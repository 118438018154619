// Assuming you already have the necessary helper functions like `fourDecimal` and `calculateAndFormatNoAbs`

import { calculateAndFormatNoAbs, fourDecimal } from ".";

export const updateTotals = (updatedAllocations, editableValue) => {
  const totalLong = updatedAllocations.reduce(
    (sum, strategy) =>
      sum +
      (parseFloat(strategy.percentLong) *
        parseFloat(strategy.totalAllocation / 100) || 0),
    0
  );

  const totalShort = updatedAllocations.reduce(
    (sum, strategy) =>
      sum +
      (parseFloat(strategy.percentShort) *
        parseFloat(strategy.totalAllocation / 100) || 0),
    0
  );

  const longFundAum = parseFloat(editableValue) * (totalLong / 100);
  const shortFundAum = parseFloat(editableValue) * (totalShort / 100);
  const totalforPercent = totalLong - totalShort;
  const totalAums = longFundAum - shortFundAum;
  const netPercent = totalLong + totalShort;
  const netFundAum = longFundAum + shortFundAum;

  const newTotals = [
    {
      changeValue: fourDecimal(totalLong),
      changeAllocation: calculateAndFormatNoAbs(longFundAum),
    },
    {
      changeValue: fourDecimal(totalShort),
      changeAllocation: calculateAndFormatNoAbs(shortFundAum),
    },
    {
      changeValue: fourDecimal(totalforPercent),
      changeAllocation: calculateAndFormatNoAbs(totalAums),
    },
    {
      changeValue: fourDecimal(netPercent),
      changeAllocation: calculateAndFormatNoAbs(netFundAum),
    },
  ];

  return newTotals;
};

export const updateLongShort = (updatedAllocations, fundAum) => {
  const totalLong = updatedAllocations.reduce(
    (sum, strategy) =>
      sum +
      (parseFloat(strategy.long) * parseFloat(strategy.existingPercent / 100) ||
        0),
    0
  );

  const totalShort = updatedAllocations.reduce(
    (sum, strategy) =>
      sum +
      (parseFloat(strategy.short) *
        parseFloat(strategy.existingPercent / 100) || 0),
    0
  );

  const longFundAum = fundAum * (totalLong / 100);
  const shortFundAum = fundAum * (totalShort / 100);
  const totalforPercent = totalLong - totalShort;
  const totalAums = longFundAum - shortFundAum;
  const netPercent = totalLong + totalShort;
  const netFundAum = longFundAum + shortFundAum;

  const newTotals = [
    {
      name: "Total for Long",
      changeValue: fourDecimal(totalLong),
      changeAllocation: calculateAndFormatNoAbs(longFundAum),
    },
    {
      name: "Total for Short",
      changeValue: fourDecimal(totalShort),
      changeAllocation: calculateAndFormatNoAbs(shortFundAum),
    },
    {
      name: "Total Gross",
      changeValue: fourDecimal(totalforPercent),
      changeAllocation: calculateAndFormatNoAbs(totalAums),
    },
    {
      name: "Total Net",
      changeValue: fourDecimal(netPercent),
      changeAllocation: calculateAndFormatNoAbs(netFundAum),
    },
  ];

  return newTotals; // Return newTotals to be set in the component
};
// utils/sortUtils.js
export const sortFunds = (funds) => {
  return funds
    ?.map((i) => i?.abbreviation)
    ?.filter((abbreviation) => abbreviation)
    ?.sort((a, b) => a.localeCompare(b));
};

export const sortData = (data) => {
  return data?.slice().sort((a, b) => {
    if (a.abbreviation && b.abbreviation) {
      return a.abbreviation.localeCompare(b.abbreviation);
    } else if (a.abbreviation) {
      return -1;
    } else if (b.abbreviation) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const sortStrategies = (strategies) => {
  const sortedStrategies = [...strategies].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const highAumStrategies = sortedStrategies.filter(
    (strategy) => strategy?.aum >= 10
  );
  const lowAumStrategies = sortedStrategies.filter(
    (strategy) => strategy?.aum < 10
  );
  return { highAumStrategies, lowAumStrategies };
};
