import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  Typography,
  Checkbox,
} from "@mui/material";

const ListHead = ({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  hideColumn,
  noStatus,
  title,
  setShowBulkDelete,
  setData,
  data,
}) => {
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const adjustedHeadLabel = hideColumn ? headLabel.slice(1) : headLabel;

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setData((prevData) => {
      const updatedData = prevData.map((row) => ({
        ...row,
        check: isChecked,
      }));

      setShowBulkDelete(isChecked);

      return updatedData;
    });
  };

  return (
    <TableHead>
      <TableRow sx={{ background: theme.palette.grey[700] }}>
        {!noStatus ? (
          <TableCell
            padding="checkbox"
            align="center"
            sx={{
              color: theme.palette.grey[100],
              background: "inherit",
              fontSize: "12px",
            }}
          >
            #
          </TableCell>
        ) : null}

        {adjustedHeadLabel?.map((headCell) => {
          if (hideColumn) {
            if (headCell.isActive) {
              return (
                <TableCell
                  key={headCell.id}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    color: theme.palette.grey[100],
                    background: "inherit",
                    fontSize: "11px",
                    padding: headCell.id === "tradefills" ? "0px" : "2px",
                    paddingLeft: headCell.id === "Actions" ? "10px" : "0px",
                    "&:hover": {
                      background: theme.palette.grey[600],
                      cursor: "pointer",
                    },
                  }}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Box
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {headCell.label}
                    </Typography>
                  </Box>
                </TableCell>
              );
            }
          } else {
            return (
              <TableCell
                key={headCell.id}
                title={title ? headCell.title : ""}
                align="center"
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  color: theme.palette.grey[100],
                  padding: headCell.id === "check" ? "0px" : "",
                  background: "inherit",
                  fontSize: "11px",
                  borderLeft: "0.1px solid #dadada",
                  borderRight: "0.1px solid #dadada",
                  alignItems: "center",
                  "&:hover": {
                    background: theme.palette.grey[600],
                    cursor: "pointer",
                  },
                }}
              >
                {headCell.id === "check" ? (
                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={data?.every((row) => row.check)}
                    size="small"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                    }}
                  />
                ) : (
                  <Box
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {headCell.label}
                    </Typography>
                  </Box>
                )}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default ListHead;
