import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  Card,
  TableContainer,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  calculateAndFormatNoAbsInt,
  findFundAbbById,
  findStrategy,
  getComparator,
  hashAction,
  hashPosition,
} from "../../../../../../Methods";
import { useSelector } from "react-redux";

const CrossingTradesTable = ({ tableHeader, crossingTrades }) => {
  const theme = useTheme();
  const { strategies, funds } = useSelector((state) => state.todos);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    const filteredOrders = crossingTrades?.map((order) => {
      const strategy = findStrategy(order?.strategy, strategies);
      const ticker = order?.ticker.stock
        ? `${order?.ticker?.symbol}/${order?.ticker?.stock}`
        : order?.ticker?.symbol;
      const tag = order?.crossingTag;
      let action = hashAction(order?.action) + hashPosition(order?.position);
      const shareCount = order?.shareCount;
      const fund = findFundAbbById(order?.fund, funds);
      const custodian = order?.custodian;

      if (action === "BuyShort") {
        action = "CoverShort";
      }

      return {
        fund: fund,
        strategy: strategy,
        ticker: ticker,
        custodian: custodian,
        action: action,
        shareCount: shareCount,
        tag: tag,
      };
    });
    const filteredUsers = applySortFilter(
      filteredOrders,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, crossingTrades]);

  const groupedData = data.reduce((acc, row) => {
    if (!acc[row.ticker]) {
      acc[row.ticker] = [];
    }
    acc[row.ticker].push(row);
    return acc;
  }, {});

  const rowsWithColors = Object.entries(groupedData).reduce(
    (acc, [ticker, rows]) => {
      const baseGrey = 200;
      const greyOffset = 0;

      rows.forEach((row, index) => {
        let color;
        if (row.tag === 1) {
          const greyShade = baseGrey + ((index * greyOffset) % 400);
          color = theme.palette.grey[greyShade];
        } else if (row.tag === 0) {
          color = "#add8e6";
        } else {
          color = "white";
        }
        acc.push({ ...row, color });
      });
      return acc;
    },
    []
  );

  const uniqueRowsWithColors = Array.from(
    new Set(rowsWithColors.map(JSON.stringify))
  ).map(JSON.parse);

  const Legend = () => (
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: "#add8e6",
            marginRight: 1,
          }}
        />
        <Typography variant="body2">Crossed By Rebalancer Orders</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: theme.palette.grey[200],
            marginRight: 1,
          }}
        />
        <Typography variant="body2">Crossed By Existing Positions</Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          borderRadius: "4px",
          marginTop: "1px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Legend />
        <Box
          sx={{
            border: `100px ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
            marginTop: "1px",
            width: "100%",
            marginRight: 1,
          }}
        >
          <Card sx={{ padding: 0 }}>
            <TableContainer style={{ height: "750px" }}>
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                sx={{
                  height: "max-content",
                }}
              >
              
                <VantageListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHeader}
                  noStatus={true}
                  customizeFont={12}
                  rowCount={data.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {uniqueRowsWithColors.map((row, index) => (
                    <TableRow
                      key={`${row.strategy}-${row.ticker}-${index}`}
                      sx={{
                        backgroundColor: row.color,
                        "& > .MuiTableCell-root": {
                          paddingTop: "1px",
                          paddingBottom: "0px !important",
                          lineHeight: "1.2",
                          "& .MuiInputBase-input": {
                            padding: "0px 0px !important",
                          },
                          "& .MuiIconButton-root": {
                            padding: "0px",
                          },
                        },
                        "&:hover": {
                          background: theme.palette.primary[100],
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row.strategy}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row?.custodian}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row?.action}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row?.ticker}
                      </Tablecell>
                      <Tablecell
                        align="right"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row?.shareCount !== undefined &&
                        row?.shareCount != null
                          ? row?.shareCount < 0
                            ? `(${Math.abs(row?.shareCount)})`
                            : `${calculateAndFormatNoAbsInt(row?.shareCount)}`
                          : ""}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row?.fund}
                      </Tablecell>
                    </TableRow>
                  ))}
                </TableBody>{" "}
              </Table>
            </TableContainer>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default CrossingTradesTable;
