import ArchivedDetailTable from "../ArchivedReportsDetails/ArchivedDetailTable";
import Header from "../Header";
const ArchivedReportDetailsLayout = (id) => {
  return (
    <>
      <Header>
        <ArchivedDetailTable id={id} />
      </Header>
    </>
  );
};
export default ArchivedReportDetailsLayout;
