"use strict";
const axios = require("axios");

const endpoint = process.env.REACT_APP_REST_ENDPOINT;
const apikey = process.env.REACT_APP_REST_APIKEY;

const authendpoint = process.env.REACT_APP_AUTH_ENDPOINT;
const authapikey = process.env.REACT_APP_AUTH_APIKEY;

export const checkEmail = async (email) => {
  let data = JSON.stringify({
    email: email,
  });

  const options = {
    method: "POST",
    url: endpoint + "checkemail",
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = { available: false };

  try {
    const res = await axios.request(options);
    response = res.data;
  } catch (error) {
    response.error = true;
    console.error(error);
  }

  return response;
};

export const verify = async (user, code) => {
  let data = JSON.stringify({
    user: user,
    code: code,
  });

  const options = {
    method: "POST",
    url: endpoint + "verify",
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = { success: false };

  try {
    const res = await axios.request(options);
    response = res.data;
  } catch (error) {
    response.error = true;
    console.error(error);
  }

  return response;
};

export const forgotPassword = async (email) => {
  let data = JSON.stringify({
    email: email,
  });

  const options = {
    method: "POST",
    url: "https://pfmahqr4ik.execute-api.us-east-1.amazonaws.com/prod/forgot",
    // url: endpoint + "forgot",
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = { success: false, data: "" };

  try {
    const res = await axios.request(options);
    console.log("error", res);

    if (res.data.success) {
      response = { success: true, data: res.data };
    } else {
      response = { success: false, data: res.data.message };
    }
  } catch (error) {
    response = { success: false, data: error.response.data.message };
    console.error(error);
  }

  return response;
};

export const changePassword = async (
  email,
  token,
  oldPassword,
  newPassword
) => {
  let data = JSON.stringify({
    user: email,
    token: token,
    oldpassword: oldPassword,
    newpassword: newPassword,
  });

  const options = {
    method: "POST",
    url: endpoint + "password",
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = { success: false };

  try {
    const res = await axios.request(options);
    response = res.data;
  } catch (error) {
    response.error = true;
    console.error(error);
  }

  return response;
};

export const resetPassword = async (email, code, confirmPassword) => {
  let data = JSON.stringify({
    user: email,
    confirmationCode: code,
    newPassword: confirmPassword,
  });

  const options = {
    method: "POST",
    url: endpoint + "reset",
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = { success: false };

  try {
    const res = await axios.request(options);
    response = res.data;
  } catch (error) {
    response.error = true;
    console.error(error);
  }

  return response;
};

export const addUser = async ({
  email,
  firstname,
  lastname,
  phone,
  password,
}) => {
  let data = {
    email: email,
    firstname: firstname,
    lastname: lastname,
    phone: phone,
    password: password,
  };

  const options = {
    method: "POST",
    url: endpoint + "submit",
    headers: {
      "x-api-key": apikey,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let response = {};

  try {
    const res = await axios.request(options);
    response = res.data;
  } catch (error) {
    console.error(error);
  }

  return response;
};
export const LogUser = async ({ username, password }) => {
  let data = {
    Username: username,
    Password: password,
  };

  const options = {
    method: "GET",
    url: authendpoint,
    headers: {
      "x-api-key": authapikey,
      Username: username,
      Password: password,
      client: "Website 3.2.1",
    },
  };

  let response = {};

  try {
    const res = await axios.request(options);
    response = { success: true, data: res.data };
  } catch (error) {
    console.error(error);
    response = { success: false, data: error.response.data };
  }

  return response;
};
