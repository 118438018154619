import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  Card,
  TableContainer,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
} from "../../../../../../Methods";

const Approved = ({ approved, tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const filteredUsers = applySortFilter(
      approved,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, approved]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "1px",
          width: "80%",
          marginRight: 1,
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "18px", mb: 1 }}>
          Approved Locates
        </Typography>
          <TableContainer style={{ height: "360px" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "10px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.ticker}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingRight: "10px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.shareCount}
                    </Tablecell>
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "10px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.fund}
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      </Box>
    </>
  );
};

export default Approved;
