import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  TableContainer,
  Card,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  formatValue,
  getComparator,
} from "../../../../../../Methods";

const NewFundRebalance = ({ newArray, tableHeader, tableRef, onScroll }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    const filteredUsers = applySortFilter(
      newArray,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, newArray]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "18px", mb: 1 }}>
          New
        </Typography>
        <Card sx={{ padding: 0 }}>
          <TableContainer
            style={{ height: "360px" }}
            ref={tableRef}
            onScroll={onScroll}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      background:
                        row?.blacklisted === true
                          ? theme.palette.blue[100]
                          : row?.weight === 0 && row?.newWeight !== 0
                          ? theme.palette.red.shortPositions
                          : row?.weight !== 0 && row?.newWeight === 0
                          ? theme.palette.green.longPositions
                          : row?.weight !== 0 && Math.abs(row?.signal) === 0
                          ? theme.palette.red.shortPositions
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          row?.blacklisted === true
                            ? theme.palette.grey[300] // Ensure hover color stays the same for blacklisted rows
                            : theme.palette.grey[200],
                        cursor:
                          row?.blacklisted === true ? "not-allowed" : "pointer", // Show "not-allowed" cursor for blacklisted rows
                      },
                    }}
                  >
                    <Tablecell
                      align="left"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row.ticker}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                        color:
                          row?.signal > 0
                            ? "green"
                            : row?.signal == 0
                            ? "black"
                            : "red",
                      }}
                    >
                      {row?.signal !== undefined
                        ? row?.signal < 0
                          ? `(${Math.abs(row?.signal)?.toFixed(2)})`
                          : `${row?.signal?.toFixed(2)}\u00A0`
                        : ""}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                        color: row?.weight > 0 ? "green" : "red",
                      }}
                    >
                      {row?.weight !== undefined
                        ? row?.weight < 0
                          ? `(${Math.abs(row?.weight)?.toFixed(2)})`
                          : `${row?.weight?.toFixed(2)}\u00A0`
                        : ""}
                    </Tablecell>
                    <Tablecell
                      align="right"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "5px",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    >
                      {row?.shareCount !== undefined && row?.shareCount != null
                        ? row?.shareCount < 0
                          ? `(${formatValue(Math.abs(row?.shareCount), true)})`
                          : `${formatValue(row?.shareCount, true)}\u00A0`
                        : ""}
                    </Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default NewFundRebalance;
