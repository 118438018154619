import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  useTheme,
  IconButton,
  Divider,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import Papa from "papaparse";
import Sockets from "../../../../../utils/sockets";
import { FundCountCols as columns } from "../../../../Methods";
import { DataGrid } from "@mui/x-data-grid";

const FundCount = (props) => {
  const socketapiData = useSelector((state) => state?.users?.apiInfo);
  const socketuser = useSelector((state) => state?.users?.userInfo);

  const { readData } = Sockets(socketapiData, socketuser);

  const theme = useTheme();
  const { FundCountPopup, setFundCountPopup, title } = props;
  const [showLoader, setShowLoader] = useState(true);
  const { reportFile } = useSelector((state) => state?.todos);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState("");
  const [textContent, setTextContent] = useState("");

  useEffect(() => {
    const processFiles = async () => {
      if (reportFile && reportFile?.generation?.file) {
        const file = reportFile?.generation?.file;
        try {
          const result = await readData(file, "eod");
          let csvString = await Papa.parse(result);
          setData(csvString?.data);
          setTextContent(result);
          setShowLoader(false);
        } catch (error) {
          console.error("Error parsing CSV:", error);
          setShowLoader(false);
        }
      } else {
        setShowLoader(false);
        setTextContent("No file content available.");
      }
    };

    processFiles();
  }, [reportFile]);

  useEffect(() => {
    if (data) {
      setRows(alterData());
    }
  }, [data, reportFile]);

  const alterData = () => {
    return data
      ?.map((v, i) => {
        if (i === 0 || i === data.length - 1) {
          return null;
        }

        const tempv = {};
        v.forEach((n, j) => {
          tempv[j] = n;
        });
        tempv.id = i;
        return tempv;
      })
      .filter((row) => row !== null);
  };

  const EditRowsData = (obj) => {
    const updatedRows = rows.map((row) => {
      if (row.id === obj.id) {
        return { ...row, [obj.field]: obj.value };
      }
      return row;
    });

    const updatedData = updatedRows.map((row) => {
      const newRow = [];
      for (let j = 0; j <= 26; j++) {
        newRow.push(row[j]);
      }
      newRow[27] = row.id;
      return newRow;
    });

    setRows(updatedRows);
    // setData(updatedData);
  };

  const uppercaseValueFormatter = (params) => {
    return String(params.value).toUpperCase();
  };

  const columnsWithUppercase = columns?.map((column) => ({
    ...column,
    valueFormatter: uppercaseValueFormatter,
  }));

  const handleDownload = () => {
    const csvContent = Papa.unparse(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "report.csv";
    link.click();
  };

  return (
    <Dialog
      open={FundCountPopup}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          //  maxWidth: "1000px",
          //   width: "1000px",
          marginLeft: "200px",
        },
      }}
      onClose={() => {
        setFundCountPopup(false);
        setTextContent("");
      }}
    >
      <Box>
        <DialogTitle sx={{ py: 1 }}>
          <div style={{ display: "flex" }}>
            <Typography
              variant="h6"
              component="div"
              style={{ flexGrow: 1, fontSize: "20px" }}
            >
              {title}'s Report
            </Typography>
            <IconButton
              onClick={() => {
                setFundCountPopup(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: 1 }}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <Box
                sx={{
                  width: "100%",
                  minHeight: "350px",
                  backgroundColor: "#f5f5f5",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "10px",
                  maxHeight: "350px",
                  overflowY: "auto",
                  wordWrap: "break-word",
                  m: 1,
                  position: "relative",
                }}
              >
                {showLoader ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {textContent ? (
                      <>
                        <div style={{ height: 350, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columnsWithUppercase}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            rowHeight={20}
                            sx={{
                              "& .MuiDataGrid-cell": {
                                color: "#000",
                                fontSize: "12px",
                                border: "0.1px solid #dadada",
                              },
                              "& .MuiDataGrid-columnHeaders": {
                                background: theme.palette.grey[700],
                                color: theme.palette.grey[100],
                                fontSize: "12px",
                              },
                            }}
                            onCellEditCommit={(props, event) => {
                              EditRowsData(props);
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <Typography variant="body1" sx={{ textAlign: "center" }}>
                        No file data available.
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
              <Button variant="contained" onClick={handleDownload}>
                Download Report
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ fontSize: "12px", fontWeight: "bold", mx: 2 }}
                startIcon={<CheckCircleIcon />}
                onClick={() => {}}
              >
                SAVE CHANGES
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default FundCount;
