import React, { useEffect, useState } from "react";
import "../App.css";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { LogIn } from "../component/Schemas";
import { useFormik } from "formik";
import theme from "../Theme";
import { useDispatch } from "react-redux";
import { incrementActiveRequests, updateLoading, updateLoadingMsg } from "../Redux/Reducers/todoReducers";
import { getUser } from "../Redux/Actions/UserActions";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Button, InputAdornment, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import logoImage from "../logo.png";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState(false);

  const handleSignUpClick = () => {
    navigate("/signup");
  };
  const handleReset = () => {
    navigate("/forgot");
  };

  const handleCreate = () => {
    navigate("/signup");
  };

  const initialValues = {
    username: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LogIn,
      onSubmit: ({ username, password }) => {
        dispatch(updateLoading(true));
        dispatch(updateLoadingMsg("Authenticating"));

        try {
          const response = dispatch(getUser({ username, password }));
          if (
            response &&
            response.statusCode !== 401 &&
            response.statusCode !== 403
          ) {
            setApiError(false);
          } else {
            setApiError(true);
            toast.error("User not Authorized");
          }
        } catch (error) {
          setApiError(true);
        }
      },
    });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Container sx={{
      width: "100%",
      maxWidth: "100% !important",
      backgroundColor: "#f5f5f5",
      minHeight: "100vh",
    }}>

      <Box sx={{
        padding: "20px",
        background: "#000",

        marginBottom: "20px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}>


        <img src={logoImage} alt="Logo" style={{ width: '150px', marginTop: "10px" }} />
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ color: "white", marginTop: "10px", marginBottom: "-5px" }}
        >
          V 3.2.1
        </Typography>
      </Box>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          flexDirection: "column",
        }}
      >
        <CssBaseline />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 4px",
            backgroundColor: "white",
            padding: "50px 30px",
          }}
        >

          <Typography
            component="h1"
            variant="h4"
            fontWeight={600}
            fontSize={40}
            color={theme.palette.black}
          >
            Log in
          </Typography>
          <Typography variant="h5"
            sx={{ cursor: "pointer", height: "30px", fontSize: "16px", textDecoration: "none", marginBottom: "-20px" }}>Need a Vantage Alpha account? <Link
              color="primary"
              variant="h5"
              onClick={handleCreate}
              sx={{ cursor: "pointer", height: "30px", fontSize: "16px", textDecoration: "none" }}
            >
              Create an account
            </Link>

          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  type="text"
                  label="Email"
                  name="username"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="email"
                />
                {errors.username && touched.username ? (
                  <p className="error">*{errors.username}</p>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="new-password"
                />
                {errors.password && touched.password ? (
                  <p className="error">*{errors.password}</p>
                ) : null}
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  paddingLeft: "10px",
                  paddingTop: "20px"
                }}
              >
                <Button type="submit" variant="contained" size="large" sx={{ borderRadius: '20px' }} >
                  Log In
                </Button>

              </Box>


              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-start" }}
                style={{ paddingLeft: "10px", paddingTop: "20px" }}
              >
                <Link
                  color="primary"
                  variant="h5"
                  onClick={handleReset}
                  sx={{ cursor: "pointer", height: "30px", fontSize: "16px", textDecoration: "none" }}
                >
                  Forgot password?
                </Link>
              </Grid>


            </Grid>


          </Box>

        </Box>

      </Container>

    </Container>
  );
};
export default SignIn;
