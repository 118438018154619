import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Box,
} from "@mui/material";
import {
  Tablecell,
  TABLE_HEAD_SIGNALS_PERFORMANCE_SUMMARY as TABLE_HEAD,
  signalsData,
} from "../../../Methods";
import VantageListHead from "../../../ListHead/SignalList";
import { useSelector } from "react-redux";

const PerformanceSignal = ({ strategies, strategyValObj }) => {
  const [Long, setLong] = useState({});
  const [Short, setShort] = useState({});
  const [total, setTotal] = useState({});
  const { signalsPerformance } = useSelector((state) => state.todos);

  useEffect(() => {
    if (signalsPerformance && signalsPerformance.length > 0 && strategyValObj) {
      const updatedSignalsPerformance = signalsData(
        signalsPerformance,
        strategies,
        strategyValObj
      );

      const totalLongs = (updatedSignalsPerformance || [])
        .filter((value) => value.value > 0)
        .reduce((sum, value) => sum + value.value, 0);

      const totalShorts = (updatedSignalsPerformance || [])
        .filter((value) => value.value <= 0)
        .reduce((sum, value) => sum + value.value, 0);
      const longSums = {
        PercentChangeDay:
          totalLongs !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeDay",
                "value",
                "long"
              ) /
                totalLongs) *
              100
            : 0,

        PercentChangeMonth:
          totalLongs !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeMonth",
                "value",
                "long"
              ) /
                totalLongs) *
              100
            : 0,
        PercentChangeQuarter:
          totalLongs !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeQuarter",
                "value",
                "long"
              ) /
                totalLongs) *
              100
            : 0,
        PercentChangeWeek:
          totalLongs !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeWeek",
                "value",
                "long"
              ) /
                totalLongs) *
              100
            : 0,
      };

      const shortSums = {
        PercentChangeDay:
          totalShorts !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeDay",
                "value",
                "short"
              ) /
                totalShorts) *
              100
            : 0,
        PercentChangeMonth:
          totalShorts !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeMonth",
                "value",
                "short"
              ) /
                totalShorts) *
              100
            : 0,
        PercentChangeQuarter:
          totalShorts !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeQuarter",
                "value",
                "short"
              ) /
                totalShorts) *
              100
            : 0,
        PercentChangeWeek:
          totalShorts !== 0
            ? (calculateSum(
                updatedSignalsPerformance,
                "PercentChangeWeek",
                "value",
                "short"
              ) /
                totalShorts) *
              100
            : 0,
      };

      setTotal({
        day: longSums?.PercentChangeDay + shortSums?.PercentChangeDay,
        week: longSums?.PercentChangeWeek + shortSums?.PercentChangeWeek,
        quarter:
          longSums?.PercentChangeQuarter + shortSums?.PercentChangeQuarter,
        month: longSums?.PercentChangeMonth + shortSums?.PercentChangeMonth,
      });
      setLong(longSums);
      setShort(shortSums);

      return;
    }

    setLong({});
    setShort({});
    setTotal({});
  }, [signalsPerformance, strategies, strategyValObj]);

  const calculateSum = (data, percentChangeKey, valueKey, condition) => {
    return data
      .filter((item) =>
        condition === "long" ? item[valueKey] > 0 : item[valueKey] <= 0
      )
      .reduce(
        (sum, item) => sum + item[valueKey] * (item[percentChangeKey] / 100),
        0
      );
  };

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "10px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Table
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "max-content",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
          }}
        >
          <TableRow
            sx={{
              "&:hover": {
                background: theme.palette.grey[200],
                cursor: "pointer",
              },
            }}
          >
            <Tablecell
              align="left"
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: 900,
                backgroundColor: "#92cbdf",
                paddingLeft: "20px",
                textAlign: "left",
                py: "5px",
              }}
            >
              Performance Summary
            </Tablecell>
          </TableRow>
        </Table>

        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <VantageListHead
            headLabel={TABLE_HEAD}
            noStatus={true}
            customizeFont={12}
            noArrow={true}
          />
          <TableBody>
            <TableRow
              sx={{
                "&:hover": {
                  background: theme.palette.grey[200],
                  cursor: "pointer",
                },
              }}
            >
              <Tablecell
                align="left"
                sx={{
                  fontWeight: "900",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                }}
              >
                Long
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Long?.PercentChangeDay < 0 ? "red" : "green",
                }}
              >
                {Long?.PercentChangeDay < 0
                  ? `(${Math.abs(Long?.PercentChangeDay)?.toFixed(2)}%)`
                  : Long?.PercentChangeDay >= 0
                  ? `${Long?.PercentChangeDay?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Long?.PercentChangeWeek < 0 ? "red" : "green",
                }}
              >
                {Long?.PercentChangeWeek < 0
                  ? `(${Math.abs(Long?.PercentChangeWeek)?.toFixed(2)}%)`
                  : Long?.PercentChangeWeek >= 0
                  ? `${Long?.PercentChangeWeek?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Long?.PercentChangeMonth < 0 ? "red" : "green",
                }}
              >
                {Long?.PercentChangeMonth < 0
                  ? `(${Math.abs(Long?.PercentChangeMonth)?.toFixed(2)}%)`
                  : Long?.PercentChangeMonth >= 0
                  ? `${Long?.PercentChangeMonth?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Long?.PercentChangeQuarter < 0 ? "red" : "green",
                }}
              >
                {Long?.PercentChangeQuarter < 0
                  ? `(${Math.abs(Long?.PercentChangeQuarter)?.toFixed(2)}%)`
                  : Long?.PercentChangeQuarter >= 0
                  ? `${Long?.PercentChangeQuarter?.toFixed(2)}%`
                  : null}
              </Tablecell>
            </TableRow>
            <TableRow
              sx={{
                "&:hover": {
                  background: theme.palette.grey[200],
                  cursor: "pointer",
                },
              }}
            >
              <Tablecell
                align="left"
                sx={{
                  fontWeight: "900",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                }}
              >
                Shorts
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Short?.PercentChangeDay < 0 ? "red" : "green",
                }}
              >
                {Short?.PercentChangeDay < 0
                  ? `(${Math.abs(Short?.PercentChangeDay)?.toFixed(2)}%)`
                  : Short?.PercentChangeDay >= 0
                  ? `${Short?.PercentChangeDay?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Short?.PercentChangeWeek < 0 ? "red" : "green",
                }}
              >
                {Short?.PercentChangeWeek < 0
                  ? `(${Math.abs(Short?.PercentChangeWeek)?.toFixed(2)}%)`
                  : Short?.PercentChangeWeek >= 0
                  ? `${Short?.PercentChangeWeek?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Short?.PercentChangeMonth < 0 ? "red" : "green",
                }}
              >
                {Short?.PercentChangeMonth < 0
                  ? `(${Math.abs(Short?.PercentChangeMonth)?.toFixed(2)}%)`
                  : Short?.PercentChangeMonth >= 0
                  ? `${Short?.PercentChangeMonth?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  color: Short?.PercentChangeQuarter < 0 ? "red" : "green",
                }}
              >
                {Short?.PercentChangeQuarter < 0
                  ? `(${Math.abs(Short?.PercentChangeQuarter)?.toFixed(2)}%)`
                  : Short?.PercentChangeQuarter >= 0
                  ? `${Short?.PercentChangeQuarter?.toFixed(2)}%`
                  : null}
              </Tablecell>
            </TableRow>
            <TableRow>
              <Tablecell
                align="left"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  fontWeight: "bold",
                  border: "none",
                  borderTop: `1px solid ${theme.palette.grey[90]}`,
                }}
              >
                Performance
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  borderTop: `1px solid ${theme.palette.grey[90]}`,
                  color: total?.day < 0 ? "red" : "green",
                }}
              >
                {total?.day < 0
                  ? `(${Math.abs(total?.day)?.toFixed(2)}%)`
                  : total?.day >= 0
                  ? `${total?.day?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  borderTop: `1px solid ${theme.palette.grey[90]}`,
                  color: total?.week < 0 ? "red" : "green",
                }}
              >
                {total?.week < 0
                  ? `(${Math.abs(total?.week)?.toFixed(2)}%)`
                  : total?.week >= 0
                  ? `${total?.week?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  borderTop: `1px solid ${theme.palette.grey[90]}`,
                  color: total?.month < 0 ? "red" : "green",
                }}
              >
                {total?.month < 0
                  ? `(${Math.abs(total?.month)?.toFixed(2)}%)`
                  : total?.month >= 0
                  ? `${total?.month?.toFixed(2)}%`
                  : null}
              </Tablecell>
              <Tablecell
                align="right"
                sx={{
                  fontWeight: "500",
                  fontSize: "12px",
                  paddingLeft: "5px",
                  border: "none",
                  borderTop: `1px solid ${theme.palette.grey[90]}`,
                  color: total?.quarter < 0 ? "red" : "green",
                }}
              >
                {total?.quarter < 0
                  ? `(${Math.abs(total?.quarter)?.toFixed(2)}%)`
                  : total?.quarter >= 0
                  ? `${total?.quarter?.toFixed(2)}%`
                  : null}
              </Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default PerformanceSignal;
