function formatTimeAgo(dateString) {
  if (!isValidDate(dateString)) {
    return { valid: false, msg: "N/A" };
  }
  const currentDate = new Date();
  const givenDate = new Date(parseInt(dateString, 10) * 1000);

  const timeDifference = currentDate - givenDate;
  const threshold = 20
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  let toreturn = { valid: true };
  if (minutesDifference > parseFloat(threshold)) {
    toreturn.valid = false;
  }

  if (minutesDifference < 1) {
    toreturn.msg = "just now";
    return toreturn;
  } else if (minutesDifference === 1) {
    toreturn.msg = "1 min ago";
    return toreturn;
  } else if (minutesDifference < 60) {
    toreturn.msg = `${minutesDifference} min ago`;
    return toreturn;
  } else {
    const hoursDifference = Math.floor(minutesDifference / 60);

    if (hoursDifference === 1) {
      toreturn.msg = "1 hour ago";
      return toreturn;
    } else if (hoursDifference < 24) {
      toreturn.msg = `${hoursDifference} hours ago`;
      return toreturn;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);

      if (daysDifference === 1) {
        toreturn.msg = "1 day ago";
        return toreturn;
      } else {
        toreturn.msg = `${daysDifference} days ago`;
        return toreturn;
      }
    }
  }
}
function formatTimeAgo5minutes(dateString) {
  if (!isValidDate(dateString)) {
    // return { valid: false, msg: "N/A" };
    return { valid: true, msg: "true" };
  }

  const currentDate = new Date();
  const givenDate = new Date(parseInt(dateString, 10) * 1000);
  const timeDifference = currentDate - givenDate;
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  if (minutesDifference <= 5) {
    return { valid: true, msg: "true" };
  } else {
    // return { valid: true, msg: "false" };
    return { valid: true, msg: "true" };
  }
}

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

function capitalizeFirstLetters(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

function getSecuritiesWithMarketDataRequest(
  lots,
  selectedFundsId = [],
  strategyIds = [],
  strategies = [],
  livePrices
) {
  if (!Array.isArray(selectedFundsId)) {
    console.error("fundIds must be an array.");
    return { securities: [], market_data_request: [] }; // Or throw an error
  }

  const initialFilteredLots = lots.filter((lot) => {
    const strategyMatches =
      strategyIds.length === 0 || strategyIds?.includes(lot.strategy);
    const validShareCount = lot?.shareCount != 0;
    return strategyMatches && validShareCount;
  });

  let filteredLots = [];
  selectedFundsId?.forEach((fundId) => {
    const fundFilteredLots = initialFilteredLots.filter(
      (lot) => lot.fund === fundId
    );
    filteredLots = filteredLots.concat(fundFilteredLots);
  });

  const signalSets = strategies
    .filter((strategy) => strategyIds?.includes(strategy?.strategyId))
    .map((strategy) => strategy?.signals);

  const tickerMap = {};

  filteredLots?.forEach((lot) => {
    const { ticker } = lot;
    //TODO: This is for Bloomberg error
    // const symbol = ticker.stock
    //   ? `${ticker.symbol}/${ticker.stock}`
    //   : ticker.symbol;
    const symbol = ticker.symbol
    const investmentType =
      ticker?.investment === 4
        ? "equity"
        : ticker?.investment === 9
          ? "options"
          : null;

    if (!tickerMap[symbol]) {
      tickerMap[symbol] = {
        ticker: ticker,
        type: investmentType,
      };
    } else {
      if (tickerMap[symbol].type !== investmentType && investmentType) {
        tickerMap[symbol].type = "equity & options";
      }
    }
  });

  signalSets.forEach((signalSet) => {
    const signalTickers = signalSet?.weights_keys;

    for (const key in signalTickers) {
      let { symbol, investment, stock } = signalTickers[key];
      //TODO: This is for Bloomberg error
      // symbol = stock ? `${symbol}/${stock}` : symbol;
      const investmentType =
        investment === 4 ? "equity" : investment === 9 ? "options" : null;

      if (!tickerMap[symbol]) {
        tickerMap[symbol] = {
          ticker: signalTickers[key],
          type: investmentType,
        };
      } else {
        if (tickerMap[symbol].type !== investmentType && investmentType) {
          tickerMap[symbol].type = "equity & options";
        }
      }
    }
  });

  const securities = Object.values(tickerMap);

  const groupedRequests = {
    equity: { market: "fmp", threshold: 1200, request: true, fields: [9, 10], tickers: [] },
    options: { market: "fmp", threshold: 1200, request: true, fields: [11], tickers: [] },
    "equity & options": {
      market: "fmp",
      threshold: 1200,
      request: true,
      fields: [9, 10, 11],
      tickers: [],
    },
  };

  securities.forEach((security) => {
    const livePrice = livePrices.find(
      (price) =>
        price.symbol.toLowerCase() === security.ticker.symbol.toLowerCase()
    );

    const expirationDate = livePrice
      ? new Date(livePrice.expiration * 1000)
      : null;
    const currentTime = new Date(Date.now());

    // const currentTimeWithMargin = new Date(
    //   currentTime.getTime() - 20 * 24 * 60 * 60 * 1000
    // );

    const currentTimeWithMargin = new Date(
      currentTime.getTime() - 20 * 60 * 1000
    );

    // const currentTimeWithMargin = new Date(currentTime.getTime() - 60 * 60 * 1000);


    const isExpired = expirationDate
      ? expirationDate < currentTimeWithMargin
      : true;

    if (!livePrice || isExpired) {
      if (groupedRequests[security.type]) {
        //TODO: This is for Bloomberg error
        // const { stock, ...tickerWithoutStock } = security.ticker;
        // const symbol = stock
        //   ? `${security.ticker.symbol}/${stock}`
        //   : security.ticker.symbol;
        // groupedRequests[security.type].tickers.push({
        //   ...tickerWithoutStock,
        //   symbol,
        // });

        groupedRequests[security.type].tickers.push(security.ticker);
      }
    }
  });

  const market_data_request = Object.values(groupedRequests).filter(
    (request) => request.tickers.length > 0
  );

  return {
    securities: securities,
    market_data_request: market_data_request,
  };
}

function getStrategiesContainingTicker(strategies, tickerSymbol) {
  const foundInStrategies = [];

  strategies?.forEach((strategy) => {
    const signalSet = strategy?.signals?.weights_keys;

    if (signalSet && typeof signalSet === "object") {
      const tickerExists = Object.values(signalSet).some(
        (signalTicker) =>
          signalTicker?.symbol?.toLowerCase() === tickerSymbol?.toLowerCase()
      );

      if (tickerExists) {
        foundInStrategies.push(strategy.name);
      }
    }
  });

  return foundInStrategies;
}

export {
  formatTimeAgo,
  formatTimeAgo5minutes,
  capitalizeFirstLetters,
  getSecuritiesWithMarketDataRequest,
  getStrategiesContainingTicker,
};
