import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, FormControl, FormHelperText } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { SecurityMap } from "../Schemas";
import {
  investArr,
  exchangeArr,
  currencyArr,
  hashCurrencyEnumString,
  hashExchangeEnumReverse,
  hashInvestmentReverse,
} from "../Methods";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../Redux/Reducers/todoReducers";

const AddSecurity = ({ setOpenPopup }) => {
  const { markets } = useSelector((state) => state.todos);
  const dispatch = useDispatch();
  const investmentList = investArr?.map((val) => val)?.sort();
  const exchangeList = exchangeArr?.map((val) => val)?.sort();
  const currencyList = currencyArr?.map((val) => val)?.sort();

  const initialValues = {
    name: "",
    ticker: "",
    cusip: "",
    sedol: "",
    isin: "",
    figi: "",
    bloomberg: "",
    factset: "",
    exchange: "US" || "",
    investment: "Equity" || "",
    currency: "USD" || "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: SecurityMap,
    onSubmit: (values) => {
      const bloombergMarketId = markets?.find(
        (market) => market.name.toLowerCase() === "bloomberg"
      )?.marketId;

      const factsetMarketId = markets?.find(
        (market) => market.name.toLowerCase() === "factset"
      )?.marketId;

      if (!bloombergMarketId || !factsetMarketId) {
        console.error("Bloomberg or Factset marketId not found!");
        return;
      }

      const formattedData = {
        action: "modify_security_master_items",
        data: {
          securities: [
            {
              cusip: values.cusip,
              figi: values.figi,
              isin: values.isin,
              markets: {
                [bloombergMarketId]: values.bloomberg,
                [factsetMarketId]: values.factset,
              },
              name: values.name,
              sedol: values.sedol,
              ticker: {
                currency: hashCurrencyEnumString(values.currency),
                exchange: hashExchangeEnumReverse(values.exchange),
                investment: hashInvestmentReverse(values.investment),
                symbol: values.ticker,
              },
            },
          ],
        },
      };

      window.clientSocks.send(JSON.stringify(formattedData));
      dispatch(incrementActiveRequests());
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg(""));
      setOpenPopup(false);
    },
  });

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{ display: "flex", justifyContent: "space-between" }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          marginBottom="5px"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={2.5} marginBottom="5px">
            <TextField
              fullWidth
              id="ticker"
              label="Ticker Symbol"
              value={values.ticker}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="standard"
              error={touched.ticker && Boolean(errors.ticker)}
              helperText={touched.ticker && errors.ticker}
            />
          </Grid>
          <Grid item xs={2.5} marginBottom="5px">
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                label="Exchange"
                value={values.exchange}
                sx={{ mt: 2 }}
                onChange={(e, value) => setFieldValue("exchange", value)}
                onBlur={handleBlur}
                variant="standard"
                id="exchange"
                name="exchange"
                options={exchangeList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    value={values.exchange}
                  />
                )}
                disabled={!values.ticker}
              />
              {errors.exchange && touched.exchange && (
                <FormHelperText error>{errors.exchange}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={2.5} marginBottom="5px">
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                label="Investment"
                autoSelect
                autoHighlight
                sx={{ mt: 2 }}
                value={values.investment}
                onChange={(e, value) => setFieldValue("investment", value)}
                onBlur={handleBlur}
                variant="standard"
                id="investment"
                name="investment"
                options={investmentList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    value={values.investment}
                  />
                )}
                disabled={!values.ticker}
              />
              {errors.investment && touched.investment && (
                <FormHelperText error>{errors.investment}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={2.5} marginBottom="5px">
            <FormControl fullWidth>
              <Autocomplete
                disableClearable
                label="Currency"
                autoSelect
                autoHighlight
                sx={{ mt: 2 }}
                value={values.currency}
                onChange={(e, value) => setFieldValue("currency", value)}
                onBlur={handleBlur}
                variant="standard"
                id="currency"
                name="currency"
                options={currencyList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    value={values.currency}
                  />
                )}
                disabled={!values.ticker}
              />
              {errors.currency && touched.currency && (
                <FormHelperText error>{errors.currency}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 1, marginBottom: 1 }}>
          <Box
            sx={{
              height: 2,
              background: "#B0B0B0",
              boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
              margin: "10px 0",
            }}
          />
        </Grid>

        {Object.keys(initialValues).map(
          (key) =>
            key !== "exchange" &&
            key !== "ticker" &&
            key !== "currency" &&
            key !== "investment" && (
              <Grid item xs={12} marginBottom="5px" key={key}>
                <TextField
                  fullWidth
                  id={key}
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  value={values[key]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                />
              </Grid>
            )
        )}

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => setOpenPopup(false)}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddSecurity;
