import React, { useState } from "react";
import {
  InputAdornment,
  Box,
  Button,
  useTheme,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { StyledRoot, StyledSearch } from "../../Methods";
import Popup from "../../Popup";
import AddSecurity from "../AddSecurity";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";

const ListToolbar = ({
  filterName,
  onFilterName,
  updatedRows,
  hasChanges,
  setHasChanges,
  bulkDelete,
  setShowBulkDelete,
  markets,
  setData,
  data,
  setUpdatedRows,
}) => {
  const options = ["Fetch Figi", "Fetch Cusip/Sedols/Isins"];

  const theme = useTheme();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = () => {
    if (options[selectedIndex] === "Actions") return;
    else if (options[selectedIndex] === "Fetch Figi") {
      handleFetchFigi();
    } else {
      handleCheck();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    handleCheck();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleCheck = () => {
    setData((prevData) => {
      const updatedData = prevData.map((row) => ({
        ...row,
        check: row?.check ? false : row?.check,
      }));

      setShowBulkDelete(false);

      return updatedData;
    });
  };

  const handleFetchFigi = () => {
    const selectedTickers = data
      .filter((row) => row.check)
      .map((row) => row.ticker);


    const requestData = {
      action: "fetch_figi",
      data: {
        tickers: selectedTickers,
      },
    };
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Fetching Figi"));
    window.clientSocks.send(JSON.stringify(requestData));
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      setSelectedIndex(0);
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    setHasChanges(false);
    const requestData = {
      action: "modify_security_master_items",
      data: {
        securities: updatedRows?.map((row) => {
          const updatedSecurity = {
            cusip: row.cusip,
            figi: row.figi,
            isin: row.isin,
            name: row.name,
            sedol: row.sedol,
            ticker: {
              currency: row.ticker.currency,
              exchange: row.ticker.exchange,
              investment: row.ticker.investment,
              symbol: row.tickerSymbol,
            },
            markets: {},
          };

          if (row.markets) {
            Object.keys(row.markets).forEach((marketId) => {
              const market = markets.find(
                (market) => market.marketId === marketId
              );

              if (market) {
                if (
                  market.name.toLowerCase() === "bloomberg" &&
                  row.bloomberg
                ) {
                  updatedSecurity.markets[marketId] = row.bloomberg;
                }

                if (market.name.toLowerCase() === "factset" && row.factset) {
                  updatedSecurity.markets[marketId] = row.factset;
                }
              }
            });
          }

          return updatedSecurity;
        }),
      },
    };
    setUpdatedRows([]);
    window.clientSocks.send(JSON.stringify(requestData));
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Modifying Securities"));
  };

  return (
    <>
      <StyledRoot>
        <Box>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ color: "text.disabled", width: 15, height: 15 }}
                />
              </InputAdornment>
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {bulkDelete && (
            <React.Fragment>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
              >
                <Button onClick={handleClick}>{options[selectedIndex]}</Button>
                <Button
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{ zIndex: 999 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 0}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </React.Fragment>
          )}

          {hasChanges && (
            <Button
              variant="contained"
              sx={{
                height: 40,
                width: 200,
                fontSize: theme.typography.bodyText17,
              }}
              onClick={handleSubmit}
            >
              SAVE CHANGES
            </Button>
          )}

          <Button
            variant="contained"
            sx={{
              height: 40,
              width: 200,
              fontSize: theme.typography.bodyText17,
            }}
            onClick={() => {
              setOpenPopup(true);
            }}
          >
            ADD SECURITY
          </Button>
        </Box>
      </StyledRoot>

      <Popup
        title="ADD SECURITY MAP"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <AddSecurity setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  );
};

export default ListToolbar;
