import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import {
  getComparator,
  TABLE_HEAD_REPORTS as TABLE_HEAD,
  Tablecell,
  applySortFilter,
} from "../../Methods";
import { useDispatch, useSelector } from "react-redux";
import HistoryIcon from "@mui/icons-material/History";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../Redux/Reducers/todoReducers";
import moment from "moment";
import FundCount from "../../Popup/PopUp/Reports/FundCount";
import Goldman from "../../Popup/PopUp/Reports/Goldman";
import TopWater from "../../Popup/PopUp/Reports/TopWater";

const ArchivedReportsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reports } = useSelector((state) => state.todos);
  const [data, setData] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [GoldmanPopup, setGoldmanPopup] = useState(false);
  const [FundCountPopup, setFundCountPopup] = useState(false);
  const [TopwaterPopup, setTopwaterPopup] = useState(false);
  const [page, setPage] = useState(0);
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  useEffect(() => {
    const updatedReports = reports?.map((report) => {
      if (
        Array.isArray(report?.generations) &&
        report?.generations?.length > 0
      ) {
        const latestGeneration = report?.generations.reduce(
          (latest, current) => {
            const currentDate = new Date(current.date);
            const latestDate = new Date(latest.date);
            return currentDate > latestDate ? current : latest;
          }
        );
        return {
          ...report,
          recentDate: latestGeneration?.date,
        };
      } else {
        return {
          ...report,
          recentDate: report?.date,
        };
      }
    });

    const filteredUsers = applySortFilter(
      updatedReports,
      getComparator(order, orderBy),
      filterName
    );

    setData(filteredUsers);
  }, [order, orderBy, filterName, reports]);

  const isNotFound = !data.length && !!filterName;

  const handleNavigateToDetails = (id) => {
    navigate("/reports-history", { state: { id } });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
  };
  const handleGenerate = (id, name) => {
    setTitle(name);
    if (name.toLowerCase().includes("fund count")) {
      setFundCountPopup(true);
    } else if (name.toLowerCase().includes("goldman")) {
      setGoldmanPopup(true);
    } else if (name.toLowerCase().includes("topwater")) {
      setTopwaterPopup(true);
    }

    window.clientSocks.send(
      JSON.stringify({
        action: "generate_report",
        data: { report: id },
      })
    );

    dispatch(incrementActiveRequests());
    dispatch(updateLoadingMsg("Generating Report"));
    dispatch(updateLoading(true));
  };

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { name, reportId, recentDate } = row;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                              ".icons": {
                                visibility: "visible",
                              },
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>

                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {name ? name : "-"}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px", width: "300px" }}
                          >
                            {recentDate
                              ? moment(recentDate).format(
                                  "MMM DD, YYYY hh:mm A"
                                )
                              : "-"}
                          </Tablecell>
                          <Tablecell
                            align="center"
                            sx={{
                              paddingLeft: "5px",
                              width: "150px",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                visibility: "hidden",
                              }}
                              className="icons"
                            >
                              <IconButton
                                title="History"
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() =>
                                  handleNavigateToDetails(reportId)
                                }
                              >
                                <HistoryIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                              {/* <IconButton
                                title="Edit Report"
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInPopup(row);
                                }}
                              >
                                <ModeEditIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton> */}
                              <IconButton
                                title="Generate Report"
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => handleGenerate(reportId, name)}
                              >
                                <EditCalendarIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <TopWater
          title={title}
          TopwaterPopup={TopwaterPopup}
          setTopwaterPopup={setTopwaterPopup}
        />
        <FundCount
          title={title}
          FundCountPopup={FundCountPopup}
          setFundCountPopup={setFundCountPopup}
        />
        <Goldman
          title={title}
          GoldmanPopup={GoldmanPopup}
          setGoldmanPopup={setGoldmanPopup}
        />
      </Container>
    </>
  );
};
export default ArchivedReportsTable;
