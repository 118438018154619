import React, { useState } from "react";
import { InputAdornment, Box, Button, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StyledRoot, StyledSearch } from "../../Methods";
import ReportsSchedular from "../../Popup/ReportsSchedular";

const ListToolbar = ({ filterName, onFilterName }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const handleClick = () => {
    setOpenPopup(true);
  };

  const theme = useTheme();

  return (
    <>
      <StyledRoot>
        <Box>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ color: "text.disabled", width: 15, height: 15 }}
                />
              </InputAdornment>
            }
          />
        </Box>

        <Button
          variant="contained"
          sx={{
            height: 40,
            width: 200,
            fontSize: theme.typography.bodyText17,
          }}
          onClick={handleClick}
        >
          ADD REPORT
        </Button>
      </StyledRoot>

      <ReportsSchedular
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Add Report"
      />
    </>
  );
};

export default ListToolbar;
