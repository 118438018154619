import React, { useEffect } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Fab from "@mui/material/Fab";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useSelector, useDispatch } from "react-redux";
import { formatTimeAgo } from "../../utils/helpers";
import {
  requestMarketData,
  saveThreshold,
} from "../../Redux/Actions/todoActions";
import {
  getLoading,
  updateLoading,
  updateLoadingMsg,
  getMarketDataThreshold,
  setShowMarketLoader,
  getShowMarketLoader,
} from "../../Redux/Reducers/todoReducers";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TextField,
} from "@mui/material";

const Drawer = () => {
  const [open, setOpen] = React.useState(false);
  const loading = useSelector(getLoading);
  const marketDataThreshold = useSelector(getMarketDataThreshold);

  const [threshold, setThreshold] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogData, setDialogData] = React.useState([]);
  const [availableDialogOpen, setAvailableDialogOpen] = React.useState(false);
  const [expiredDialogOpen, setExpiredDialogOpen] = React.useState(false);
  const [availableDialogData, setAvailableDialogData] = React.useState([]);
  const [expiredDialogData, setExpiredDialogData] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (marketDataThreshold) setThreshold(marketDataThreshold);
  }, [getMarketDataThreshold]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const { livePrices, markets, lots } = useSelector((state) => state?.todos);

  // Calculate the counts
  const currentTime = Date.now() / 1000; // Convert to seconds
  const thresholdInSeconds = threshold * 60; // Convert threshold to seconds
  const availableCount = livePrices.filter(
    (item) =>
      item.price !== 1 &&
      item.yestPrice !== 1 &&
      (item.price !== 0 || item.yestPrice !== 0) &&
      currentTime - item.expiration < thresholdInSeconds
  ).length;
  const unavailableData = livePrices.filter(
    (item) =>
      (item.price === 1 && item.yestPice === 1) ||
      (item.price === 0 && item.yestPrice === 0)
  );
  const unavailableCount = unavailableData.length;
  const expiredData = livePrices.filter(
    (item) =>
      currentTime - item.expiration > thresholdInSeconds &&
      !(item.price === 1 && item.yestPrice === 1)
  );
  const expiredCount = expiredData.length;

  const handleRowClick = (event, data) => {
    event.stopPropagation(); // Prevent the drawer from closing
    setDialogData(data);
    setDialogOpen(true);
  };

  const handleAvailableRowClick = (event) => {
    event.stopPropagation();
    const nonExpiredAvailableData = livePrices.filter(
      (item) =>
        item.price !== 1 &&
        item.yestPrice !== 1 &&
        currentTime - item.expiration <= thresholdInSeconds
    );
    setAvailableDialogData(nonExpiredAvailableData);
    setAvailableDialogOpen(true);
  };
  const handleThresholdRowClick = (event) => {
    event.stopPropagation();
  };

  const handleExpiredRowClick = (event) => {
    event.stopPropagation();
    setExpiredDialogData(expiredData);
    setExpiredDialogOpen(true);
  };

  // Helper function to get market name by ID
  const getMarketNameById = (marketId) => {
    const market = markets.find((market) => market.marketId === marketId);
    return market ? market.name : "Unknown Market";
  };

  const handleMarketData = (event) => {
    event.stopPropagation();
    dispatch(updateLoadingMsg("Fetching from Bloomberg"));
    dispatch(setShowMarketLoader(true)); // Set loader to true
    dispatch(requestMarketData({ lots: lots, threshold: threshold }));
    const timeoutDuration = livePrices.length > 0 ? 30000 : 60000;
    setTimeout(() => {
      dispatch(updateLoadingMsg("Please Wait..."));
      dispatch(setShowMarketLoader(false)); // Set loader to false after 30 seconds
    }, timeoutDuration);
  };
  const handleMarketDataFactset = (event) => {
    event.stopPropagation();
    dispatch(updateLoadingMsg("Fetching from FactSet"));
    dispatch(setShowMarketLoader(true)); // Set loader to true
    dispatch(
      requestMarketData({ lots: lots, threshold: threshold, market: "factset" })
    );

    const timeoutDuration = livePrices.length > 0 ? 30000 : 60000;

    setTimeout(() => {
      dispatch(updateLoadingMsg("Please Wait..."));
      dispatch(setShowMarketLoader(false)); // Set loader to false after 30 seconds
    }, timeoutDuration);
  };

  const handleMarketDataFMP = (event) => {
    event.stopPropagation();
    dispatch(updateLoadingMsg("Fetching from FMP"));
    dispatch(setShowMarketLoader(true)); // Set loader to true
    dispatch(
      requestMarketData({ lots: lots, threshold: threshold, market: "fmp" })
    );

    const timeoutDuration = livePrices.length > 0 ? 30000 : 60000;

    setTimeout(() => {
      dispatch(updateLoadingMsg("Please Wait..."));
      dispatch(setShowMarketLoader(false)); // Set loader to false after 30 seconds
    }, timeoutDuration);
  };

  const MarketDataTable = ({ title, data, open, onClose }) => {
    const [sortConfig, setSortConfig] = React.useState({
      key: "symbol",
      direction: "asc",
    });

    const sortedData = React.useMemo(() => {
      const sortableItems = [...data];
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      return sortableItems;
    }, [data, sortConfig]);

    const handleSort = (key) => {
      let direction = "asc";
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: 600, fontSize: "20px" }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <TableContainer
            component={Paper}
            sx={{ borderBottom: "0px", boxShadow: "none" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => handleSort("symbol")}
                    sx={{
                      width: "10%",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    Ticker
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("yestPrice")}
                    sx={{
                      width: "5%",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    Last
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("price")}
                    sx={{
                      width: "10%",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    Current
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("market")}
                    sx={{
                      width: "20%",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    Market
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort("expiration")}
                    sx={{
                      width: "60%",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      cursor: "pointer",
                    }}
                  >
                    Fetched Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        maxWidth: "50px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "10%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {item.symbol}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "5%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        textAlign: "right",
                        paddingRight: "8px",
                      }}
                    >
                      {item?.yestPrice?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        textAlign: "right",
                        paddingRight: "8px",
                      }}
                    >
                      {item?.price?.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "20%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      {getMarketNameById(item?.market)}
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: "120px",
                        width: "60%",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        textAlign: "right",
                      }}
                    >
                      {formatTimeAgo(item?.expiration)?.msg}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    );
  };
  const handleEditRow = (value) => {
    setThreshold(value);
    dispatch(saveThreshold(value));
  };
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{ display: "flex", width: "350px", flexDirection: "column" }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ width: "100%", height: "100%" }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "20px",
                marginTop: 5,
              }}
            >
              Market Data
            </Typography>
            <Table sx={{ mt: 2 }}>
              <TableBody>
                <TableRow onClick={handleThresholdRowClick}>
                  <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                    Threshold
                  </TableCell>
                  <TableCell align="right" sx={{ paddingRight: "5px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <TextField
                        value={threshold !== undefined ? threshold : ""}
                        placeholder="0"
                        onChange={(e) => {
                          const value = e.target.value;
                          const formattedValue =
                            value.match(/^\d*(\.\d{0,4})?/);
                          if (formattedValue && !isNaN(formattedValue[0])) {
                            handleEditRow(formattedValue[0]);
                          }
                        }}
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          step: "any",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield",
                            WebkitAppearance: "none",
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                        sx={{ width: "90px" }}
                      />
                      {threshold !== undefined &&
                        threshold !== null &&
                        threshold !== "" && (
                          <Typography sx={{ fontSize: "12px", mr: 2, ml: 1 }}>
                            mins
                          </Typography>
                        )}
                    </Box>
                  </TableCell>
                </TableRow>

                {/* The rest of your rows */}
                <TableRow
                  onClick={handleAvailableRowClick}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                    Available Market Data
                  </TableCell>
                  <TableCell align="right" sx={{ py: 0.5 }}>
                    {availableCount} Tickers
                  </TableCell>
                </TableRow>
                <TableRow
                  onClick={(event) => handleRowClick(event, unavailableData)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                    Unavailable Market Data
                  </TableCell>
                  <TableCell align="right" sx={{ py: 0.5 }}>
                    {unavailableCount} Tickers
                  </TableCell>
                </TableRow>
                <TableRow
                  onClick={handleExpiredRowClick}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row" sx={{ py: 0.5 }}>
                    Expired Market Data
                  </TableCell>
                  <TableCell align="right" sx={{ py: 0.5 }}>
                    {expiredCount} Tickers
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                alignSelf: "center",
                flexDirection: "column",
                marginTop: 10,
                paddingLeft: "10px", // Add padding to the left
                paddingRight: "10px",
              }}
            >
              <Button
                onClick={handleMarketData}
                variant="contained"
                color="primary"
                sx={{ mt: 1, width: "100%" }}
                disabled={true}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Fetch from Bloomberg"
                )}
              </Button>
              <Button
                onClick={handleMarketDataFactset}
                variant="contained"
                color="info"
                sx={{ mt: 1, width: "100%" }}
                disabled={true}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Fetch from FactSet"
                )}
              </Button>

              <Button
                onClick={handleMarketDataFMP}
                variant="contained"
                color="warning"
                sx={{ mt: 1, width: "100%" }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Fetch from FMP"
                )}
              </Button>
            </div>
          </div>
        </div>
      </SwipeableDrawer>

      <MarketDataTable
        title="Unavailable Market Data"
        data={dialogData}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />

      <MarketDataTable
        title="Available Market Data"
        data={availableDialogData}
        open={availableDialogOpen}
        onClose={() => setAvailableDialogOpen(false)}
      />

      <MarketDataTable
        title="Expired Market Data"
        data={expiredDialogData}
        open={expiredDialogOpen}
        onClose={() => setExpiredDialogOpen(false)}
      />
      <Fab
        color="black"
        aria-label="add"
        onClick={() => setOpen(!open)}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          backgroundColor: "#000",
          "&:hover": {
            backgroundColor: "#000",
          },
        }}
      >
        <QueryStatsIcon />
      </Fab>
    </div>
  );
};

export default Drawer;
