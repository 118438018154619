import React, { useState, useEffect } from "react";
import {
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  FormControl,
  FormGroup,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl as MuiFormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { ReportsSchedularSchema } from "../../Schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../Redux/Reducers/todoReducers";
import { hashGetReportType, ReportType } from "../../Methods";

const ReportsSchedular = ({ openPopup, setOpenPopup, title }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { funds, managers } = useSelector((state) => state.todos);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDays, setSelectedDays] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
    Sunday: false,
  });

  const FundList = (funds?.map((i) => i?.abbreviation) || [])?.sort();

  useEffect(() => {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");
    setSelectedTime(`${hours}:${minutes}:${seconds}`);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      scheduleType: "daily",
      type: "",
      selectedTime: selectedTime,
      selectedDays: selectedDays,
      selectedFunds: [],
    },
    validationSchema: ReportsSchedularSchema,
    onSubmit: (values) => {
      const managerId = managers[0]?.managerId || "";
      const selectedFundsAbbr = values.selectedFunds.length > 0 ? values.selectedFunds : FundList;
      const fundArray = funds?.filter((fund) =>
        selectedFundsAbbr.includes(fund?.abbreviation)
      );
      const fundIds = fundArray?.map((fund) => fund.fundId);
      const frequency = values.scheduleType === "daily" ? 0 : 1;
      let daysBitmask = 0;
      Object.keys(values.selectedDays).forEach((day, index) => {
        if (values.selectedDays[day]) {
          daysBitmask += Math.pow(2, index);
        }
      });

      const requestData = {
        action: "create_report",
        data: {
          fundIds: fundIds,
          name: values.name,
          type: hashGetReportType(values.type),
          managerId: managerId,
          schedule: {
            frequency: frequency,
            days: daysBitmask,
          },
        },
      };
      window.clientSocks.send(JSON.stringify(requestData));
      setOpenPopup(false);
      formik.resetForm();
      dispatch(incrementActiveRequests());
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg("Creating Report."));
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    setOpenPopup(false);
  };

  const handleFundChange = (event, newValue) => {
    formik.setFieldValue("selectedFunds", newValue);
  };
  const handleTypeChange = (event, newValue) => {
    formik.setFieldValue("type", newValue);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleCancel}
      open={openPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "200px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            align="left"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Report Name"
          placeholder="Report Name"
          id="name"
          name="name"
          variant="standard"
          value={formik.values.name}
          onChange={formik.handleChange}
          fullWidth
          required
          error={formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          sx={{ marginBottom: 2 }}
        />

        <MuiFormControl fullWidth sx={{ marginBottom: 2 }}>
          <Autocomplete
            options={ReportType}
            value={formik.values.type}
            onChange={handleTypeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Report Type"
                variant="standard"
                fullWidth
                required
                error={formik.touched.type && !!formik.errors.type}
                helperText={formik.touched.type && formik.errors.type}
              />
            )}
          />
        </MuiFormControl>

        <MuiFormControl fullWidth sx={{ marginBottom: 2 }}>
          <Autocomplete
            multiple
            options={FundList}
            value={formik.values.selectedFunds}
            onChange={handleFundChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Funds"
                variant="standard"
                fullWidth
                error={
                  formik.touched.selectedFunds && !!formik.errors.selectedFunds
                }
                helperText={
                  formik.touched.selectedFunds && formik.errors.selectedFunds
                }
              />
            )}
          />
        </MuiFormControl>

        <Typography variant="h6" sx={{ marginBottom: 1, fontSize: "20px" }}>
          Add Report
        </Typography>
        <RadioGroup
          value={formik.values.scheduleType}
          onChange={formik.handleChange}
          name="scheduleType"
          aria-label="schedule-type"
          sx={{ marginBottom: 2 }}
        >
          <FormControlLabel value="daily" control={<Radio />} label="Daily" />
          <FormControlLabel value="hourly" control={<Radio />} label="Hourly" />
        </RadioGroup>

        <Typography variant="h6" sx={{ marginBottom: 1, fontSize: "20px" }}>
          Days
        </Typography>
        <FormGroup sx={{ marginBottom: 2 }}>
          {Object.keys(formik.values.selectedDays).map((day) => (
            <FormControlLabel
              key={day}
              control={
                <Checkbox
                  checked={formik.values.selectedDays[day]}
                  onChange={formik.handleChange}
                  name={`selectedDays.${day}`}
                />
              }
              label={day}
            />
          ))}
        </FormGroup>

        {formik.touched.selectedDays && formik.errors.selectedDays && (
          <Typography color="error" variant="body2">
            {formik.errors.selectedDays}
          </Typography>
        )}

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "150px",
            }}
            onClick={formik.handleSubmit}
          >
            Add Report
          </Button>
         
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ReportsSchedular;
