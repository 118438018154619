import React from "react";
import { useEffect } from "react";
import VantageDashboardLayout from "../component/Layouts/VantageDashboardLayout";
import Topbar from "../component/NavComponents/Topbar";
import { requestMarketData } from "../Redux/Actions/todoActions";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { updateLoadingMsg, incrementActiveRequests, updateLoading } from "../Redux/Reducers/todoReducers";

const VantageDashboard = () => {
  return (
    <>
      <Helmet>
        <title>Vantage Portal | Vantage Alpha</title>
      </Helmet>
      <Topbar title="Performance Dashboard" />
      <VantageDashboardLayout />
    </>
  );
};
export default VantageDashboard;
