import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_FUND_REBALANCE_EXISTING_DATA,
  TABLE_HEAD_FUND_REBALANCE_NEW_DATA,
} from "../../../../../Methods";
import { dummyData } from "../../../../../dummyData";
import RebalanceDetailedInfo from "../../../RebalanceDetailedInfo";
import ExistingFundRebalance from "./components/ExistingFundRebalance";
import NewFundRebalance from "./components/NewFundRebalance";
import { useDispatch, useSelector } from "react-redux";
import CrossingTradeRebalancer from "../../../../../Popup/CrossingTrades/CrossingTradeRebalancer";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../../../../Redux/Reducers/todoReducers";

const Legend = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        gap: 3, 
        alignItems: 'center',
        mb: 2,
        width: '100%'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box 
          sx={{ 
            width: 20, 
            height: 20, 
            backgroundColor: 'rgba(76, 175, 80, 0.1)', // light green
            border: '1px solid rgba(76, 175, 80, 0.2)'
          }} 
        />
        <Typography variant="body2">NEW POSITION</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box 
          sx={{ 
            width: 20, 
            height: 20, 
            backgroundColor: 'rgba(244, 67, 54, 0.1)', // light red
            border: '1px solid rgba(244, 67, 54, 0.2)'
          }} 
        />
        <Typography variant="body2">CLOSING POSITION</Typography>
      </Box>
    </Box>
  );
};

const FundRebalanceStep1 = ({ setCurrentStep, onPrevious, sleeve }) => {
  const { sleeveDataStageOne } = useSelector((state) => state.todos);
  const [existingArray, setExistingArray] = useState([]);
  const [newArray, setNewArray] = useState([]);
  const [openCrossPopup, seOpenCrossPopup] = useState(false);
  const [onceClicked, setOnceClicked] = useState(false);
  const dispatch = useDispatch();

  // Add refs for the tables
  const existingTableRef = React.useRef(null);
  const newTableRef = React.useRef(null);

  // Add scroll handler
  const handleScroll = (scrollingElement, targetElement) => {
    if (targetElement) {
      targetElement.scrollTop = scrollingElement.scrollTop;
    }
  };

  const onNext = () => {
    if (onceClicked) {
      seOpenCrossPopup(true);
      return;
    }

    setOnceClicked(true);

    const rebalancerIds = Object.values(sleeveDataStageOne)?.map(
      (strategy) => strategy?.rebalancer?.rebalancerId
    );
    const socketRequest = {
      action: "create_rebalancer_orders",
      data: {
        rebalancerIds: rebalancerIds,
      },
    };
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Creating Single Line Orders"));
    window.clientSocks.send(JSON.stringify(socketRequest));
    seOpenCrossPopup(true);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <RebalanceDetailedInfo
                sleeve={sleeve}
                setExistingArray={setExistingArray}
                setNewArray={setNewArray}
                sleeveDataStageOne={sleeveDataStageOne}
              />
            </div>
            {Object.keys(sleeveDataStageOne)?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                {/* <Button
                  variant="contained"
                  color="grey"
                  startIcon={<ArrowBackIcon />}
                  onClick={onPrevious}
                  sx={{ width: "150px", height: "40px" }}
                >
                  BACK
                </Button> */}

                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "150px", height: "40px" }}
                >
                  Next
                </Button>
              </Box>
            ) : null}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: 'auto', maxWidth: '800px' }}>
            {/* Legend First */}
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 3, 
                alignItems: 'center',
                mb: 2
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box 
                  sx={{ 
                    width: 20, 
                    height: 20, 
                    backgroundColor: 'rgba(76, 175, 80, 0.1)',
                    border: '1px solid rgba(76, 175, 80, 0.2)'
                  }} 
                />
                <Typography variant="body2">NEW POSITION</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box 
                  sx={{ 
                    width: 20, 
                    height: 20, 
                    backgroundColor: 'rgba(244, 67, 54, 0.1)',
                    border: '1px solid rgba(244, 67, 54, 0.2)'
                  }} 
                />
                <Typography variant="body2">CLOSING POSITION</Typography>
              </Box>
            </Box>

            {/* Tables Container */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2,
              width: 'auto'
            }}>
              {dummyData && TABLE_HEAD_FUND_REBALANCE_EXISTING_DATA ? (
                <ExistingFundRebalance
                  existingArray={existingArray}
                  tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING_DATA}
                  tableRef={existingTableRef}
                  onScroll={(e) => handleScroll(e.target, newTableRef.current)}
                />
              ) : (
                <p>No data available</p>
              )}
              {dummyData && TABLE_HEAD_FUND_REBALANCE_NEW_DATA ? (
                <NewFundRebalance
                  newArray={newArray}
                  tableHeader={TABLE_HEAD_FUND_REBALANCE_NEW_DATA}
                  tableRef={newTableRef}
                  onScroll={(e) => handleScroll(e.target, existingTableRef.current)}
                />
              ) : (
                <p>No data available</p>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CrossingTradeRebalancer
        title="Orders"
        openCrossPopup={openCrossPopup}
        seOpenCrossPopup={seOpenCrossPopup}
        setCurrentStep={setCurrentStep}
      />
    </Container>
  );
};

export default FundRebalanceStep1;
