import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Alert, Link } from "@mui/material";
import { ResetPassword } from "../../Schemas";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { resetPassword } from "../../../utils/restAPI";
import { updateLoadingAxios } from "../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";
import logoImage from "../../../logo.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { InputAdornment, IconButton } from "@mui/material";

const ResetUserPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [passReset, setPassReset] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [apiMessage, setApiMessage] = useState("Something went wrong..");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      code: "",
    },
    validationSchema: ResetPassword,
    onSubmit: async ({ code, password, confirmPassword }) => {
      dispatch(updateLoadingAxios(true));
      try {
        const response = await resetPassword(email, code, confirmPassword);
        dispatch(updateLoadingAxios(false));

        if (response && response.success) {
          setPassReset(true);
          setEmailVerified(false);
        } else {
          setPassReset(false);
          setEmailVerified(true);
          setApiMessage("Incorrect code.");
        }
      } catch (error) {
        setPassReset(false);
        setEmailVerified(true);
        setApiMessage("Something went wrong..");
      }
    },
  });

  useEffect(() => {
    if (
      !location.state ||
      !location.state.fromButton ||
      !location.state.email
    ) {
      navigate("/login", { replace: true });
    } else {
      const userEmail = location.state.email;
      setEmail(userEmail);
    }
  }, [navigate, location.state]);
  const handleBackToLoginClick = () => {
    navigate("/login");
  };
  const theme = useTheme();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <Container
      sx={{
        width: "100%",
        maxWidth: "100% !important",
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          padding: "20px",
          background: "#000",

          marginBottom: "20px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={logoImage}
          alt="Logo"
          style={{ width: "150px", marginTop: "10px" }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ color: "white", marginTop: "10px", marginBottom: "-5px" }}
        >
          V 3.2.1
        </Typography>
      </Box>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 4px",
            backgroundColor: "white",
            padding: "50px 30px",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            fontWeight={600}
            fontSize={40}
            color={theme.palette.black}
          >
            Password Reset
          </Typography>
          <Typography
            variant="h5"
            sx={{
              cursor: "pointer",
              height: "30px",
              fontSize: "16px",
              textDecoration: "none",
              marginBottom: "10px",
            }}
          >
            Find reset code in your email and create a new password to access
            your account.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              label="Reset Code"
              variant="outlined"
              margin="normal"
              id="code"
              name="code"
              type="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              margin="normal"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Confirm Password"
              variant="outlined"
              margin="normal"
              id="confirmPassword"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />

            {passReset && (
              <Alert severity="success">
                Password is reset.{" "}
                <Link
                  onClick={handleBackToLoginClick}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                >
                  Go back to login
                </Link>
              </Alert>
            )}
            {emailVerified && !passReset && (
              <Alert severity="error" sx={{ my: 1 }}>
                {apiMessage}
              </Alert>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingLeft: "10px",
                paddingTop: "20px",
              }}
            >
              <Button
                disabled={formik.isSubmitting}
                type="submit"
                variant="contained"
                size="large"
                sx={{ borderRadius: "20px" }}
              >
                Reset Password
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </Container>
  );
};

export default ResetUserPassword;
