import * as React from "react";
import {
  Box,
  TextField,
  Grid,
  Button,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableRow,
  useTheme,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { FundName } from "../Schemas";
import { findCustByName, findCustId } from "../Methods";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../Redux/Reducers/todoReducers";

const AddFund = ({ setOpenPopup, openPopup }) => {
  const { custodians, managers } = useSelector((state) => state?.todos);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([
    { custodian: "", accountNumber: "", accountName: "" },
  ]);

  const initialValues = {
    name: "",
    abbreviation: "",
    custodianArray: rows,
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: FundName,
    onSubmit: (values) => {
      const accounts = {};
      const managerId = managers[0]?.managerId;
      if (Array.isArray(values?.custodianArray)) {
        values?.custodianArray?.forEach((account) => {
          if (
            account.accountName &&
            account.custodian &&
            account.accountNumber
          ) {
            accounts[account.accountName] = {
              custodian: findCustByName(account.custodian, custodians),
              number: account.accountNumber,
            };
          }
        });
      }

      const formattedData = {
        action: "add_fund",
        data: {
          abbreviation: values.abbreviation,
          accounts: accounts,
          managerId: managerId,
          name: values.name,
        },
      };
      window.clientSocks.send(JSON.stringify(formattedData));
      dispatch(incrementActiveRequests());
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg(""));

      setOpenPopup(false);
    },
  });

  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { custodian: "", accountNumber: "", accountName: "" },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    setFieldValue("custodianArray", updatedRows);
  };

  const checkTestKeys = () => {
    return custodians?.map((i) => i?.name);
  };

  const theme = useTheme();

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="name"
            label="Full Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            required
          />
          {errors.name && touched.name ? (
            <p className="error">*{errors.name}</p>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="abbreviation"
            label="Abbreviation"
            value={values.abbreviation}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            required
          />
          {errors.abbreviation && touched.abbreviation ? (
            <p className="error">*{errors.abbreviation}</p>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <InputLabel variant="standard" sx={{ mt: "-10px" }}>
            Accounts
          </InputLabel>
          <Table sx={{ width: "100%" }}>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <Autocomplete
                        id="custodian"
                        ListboxProps={{
                          sx: { fontSize: 12 },
                        }}
                        sx={{
                          "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                            fontSize: 12,
                          },
                        }}
                        value={row.custodian}
                        onChange={(e, newValue) => {
                          const updatedRows = [...rows];
                          updatedRows[index].custodian = newValue;
                          setRows(updatedRows);
                          if (!newValue) {
                            updatedRows[index].accountNumber = "";
                            updatedRows[index].accountName = "";
                            setRows(updatedRows);
                          }
                        }}
                        options={checkTestKeys() || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            sx={{ mb: 1 }}
                            label="Custodian"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Account Number"
                        value={row.accountNumber}
                        onChange={(e) => {
                          const updatedRows = [...rows];
                          updatedRows[index].accountNumber = e.target.value;
                          setRows(updatedRows);
                        }}
                        required={row.custodian ? true : false}
                        error={row.custodian && !row.accountNumber}
                        // helperText={row.custodian && !row.accountNumber ? "Account Number is required" : ""}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        label="Account Name"
                        variant="standard"
                        value={row.accountName}
                        onChange={(e) => {
                          const updatedRows = [...rows];
                          updatedRows[index].accountName = e.target.value;
                          setRows(updatedRows);
                        }}
                        required={row.custodian ? true : false}
                        error={row.custodian && !row.accountName}
                        // helperText={row.custodian && !row.accountName ? "Account Name is required" : ""}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => handleRemoveRow(index)}
                        sx={{ padding: "5px" }}
                      >
                        <DeleteIcon sx={{ width: "15px", height: "15px" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <Button
              variant="outlined"
              onClick={handleAddRow}
              sx={{ width: "100%" }}
              startIcon={<AddIcon />}
            >
              Add Another Row
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          margin="10px"
          display="flex"
          justifyContent="space-between"
        >
          <Button variant="contained" sx={{ width: "30%" }} type="submit">
            SAVE
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "30%",
              background: "#B2BEB5",
              color: "#000",
              "&:hover": { background: "#808080" },
            }}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            CANCEL
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddFund;
