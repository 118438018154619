
function isDateOlderThanThreshold(dateString, threshold) {
  const currentDate = new Date();
  const givenDate = new Date(dateString);
  const timeDifference = currentDate - givenDate;
  const minutesDifference = timeDifference / (1000 * 60);
  return minutesDifference > threshold;
}

export function getData(tickers, threshold, sendRequest = true, sendBenchmarks = true, requestPerformance = false, market = "bloomberg", livePrices) {
  let existingData = livePrices
  let socketRequest = [];
  let deltaRequests = [];
  let benchMarks = [];
  let toSend = {
    data: [],
    requested: tickers.length,
    found: 0,
    expired: 0,
    queried: 0,
  };

  benchMarks.push({ symbol: "nqa", investment: 7, exchange: 4, currency: 7 }, { symbol: "qqq", investment: 4, exchange: 4, currency: 7 }, { symbol: "esa", investment: 4, exchange: 4, currency: 7 }, { symbol: "spy", investment: 4, exchange: 4, currency: 7 }, { symbol: "rtya", investment: 7, exchange: 4, currency: 7 }, { symbol: "iwm", investment: 4, exchange: 4, currency: 7 }, { symbol: "dma", investment: 7, exchange: 4, currency: 7 }, { symbol: "dia", investment: 4, exchange: 4, currency: 7 }, { symbol: "rus", investment: 4, exchange: 4, currency: 7 });

  tickers.map((ticker) => {
    if (existingData.length > 0) {
      const ed = existingData;
      const found = existingData.find((item) => item.symbol.toLowerCase() == ticker.symbol.toLowerCase());

      if (found) {
        toSend.found++;
        if (isDateOlderThanThreshold(found.expiration, threshold)) {
          socketRequest.push({
            symbol: ticker.symbol,
            investment: 4,
            exchange: ticker.exchange,
            currency: ticker.currency,
            ...(ticker.stock && { stock: ticker.stock })
          });

          if (ticker.investment == 9) {
            deltaRequests.push({
              symbol: ticker.symbol,
              investment: 9,
              currency: 7,
              exchange: 4,
              underlying: {
                investment: 4,
                symbol: ticker.symbol,
                currency: 7,
                exchange: 4,
                ...(ticker.stock && { stock: ticker.stock })
              },
              strikePrice: ticker.strikePrice,
              expirationDate: ticker.expirationDate,
              option: ticker.option,
            });
          }

          toSend.expired++;
          toSend.queried++;
        }

        toSend.data.push({
          ticker: found.symbol,
          last: found.yestPrice,
          current: found.price,
          delta: 1,
          date: found.expiration,
        });
      } else {
        //IF NOT FOUND IN LOCAL STORAGE, SEND DUMMY DATA AND THEN SEND A WEBSOCKET REQUEST TO FETCH
        toSend.data.push({
          ticker: ticker.symbol,
          last: 1,
          current: 1,
          delta: 1,
        });

        if (ticker.investment == 4 || ticker.investment == 9) {
          socketRequest.push({
            symbol: ticker.symbol,
            investment: 4,
            exchange: ticker.exchange,
            currency: ticker.currency,
            ...(ticker.stock && { stock: ticker.stock })
          });

          if (ticker.investment == 9) {
            deltaRequests.push({
              symbol: ticker.symbol,
              investment: 9,
              currency: 7,
              exchange: 4,
              underlying: {
                investment: 4,
                symbol: ticker.symbol,
                currency: 7,
                exchange: 4,
                ...(ticker.stock && { stock: ticker.stock })
              },
              strikePrice: ticker.strikePrice,
              expirationDate: ticker.expirationDate,
              option: ticker.option,
            });
          }

          toSend.queried++;
        }
      }
    } else {
      //IF THERE IS NOTHING IN LOCAL STORAGE, SEND DUMMY DATA AND THEN SEND A WEBSOCKET REQUEST TO FETCH
      toSend.data.push({
        ticker: ticker.symbol,
        last: 1,
        current: 1,
        delta: 1,
      });
      if (ticker.investment == 4 || ticker.investment == 9) {
        socketRequest.push({
          symbol: ticker.symbol,
          investment: 4,
          exchange: ticker.exchange,
          currency: ticker.currency,
          ...(ticker.stock && { stock: ticker.stock })
        });
        toSend.queried++;

        if (ticker.investment == 9) {
          deltaRequests.push({
            symbol: ticker.symbol,
            investment: 9,
            currency: 7,
            exchange: 4,
            underlying: {
              investment: 4,
              symbol: ticker.symbol,
              currency: 7,
              exchange: 4,
              ...(ticker.stock && { stock: ticker.stock })
            },
            strikePrice: ticker.strikePrice,
            expirationDate: ticker.expirationDate,
            option: ticker.option,
          });
        }
      }
    }
  });

  if (sendBenchmarks) {
    benchMarks.map((ticker) => {
      if (existingData.length > 0) {
        const found = existingData.find((item) => item.symbol.toLowerCase() == ticker.symbol.toLowerCase());
        if (found) {
          if (isDateOlderThanThreshold(found.expiration)) {
            socketRequest.push({
              symbol: ticker.symbol,
              investment: ticker.investment,
              exchange: ticker.exchange,
              currency: ticker.currency,
            });
          }
          toSend.data.push({
            ticker: found.symbol,
            last: found.yestPrice,
            current: found.price,
            delta: 1,
            date: found.expiration,
          });
        } else {
          toSend.data.push({
            ticker: ticker.symbol,
            last: 1,
            current: 1,
            delta: 1,
          });

          socketRequest.push({
            symbol: ticker.symbol,
            investment: ticker.investment,
            exchange: ticker.exchange,
            currency: ticker.currency,
          });
        }
      }
    });
  }

  if (socketRequest.length > 0) {
    const requestThreshold = threshold * 60;
    let requestFormat = "";
    if (requestPerformance) {
      requestFormat = '{"action": "market_data","data":{"threshold":' + requestThreshold + ',"request":true,"fields":[4,5,6,7,8,11],"tickers":[';
      socketRequest.map((s) => {
        requestFormat += JSON.stringify(s);
        requestFormat += ",";
      });
    } else {
      if (market === "bloomberg") {

        requestFormat = '{"action": "market_data","data":{"threshold":' + requestThreshold + ',"request":true,"fields":[9,10],"tickers":[';
      } else if (market === "factset") {

        requestFormat = '{"action": "market_data","data":{"market":"factset","threshold":' + requestThreshold + ',"request":true,"fields":[9,10],"tickers":[';
      }
      else if (market === "fmp" || market === "FMP") {

        requestFormat = '{"action": "market_data","data":{"market":"fmp","threshold":' + requestThreshold + ',"request":true,"fields":[9,10],"tickers":[';
      }
      socketRequest.map((s) => {
        requestFormat += JSON.stringify(s);
        requestFormat += ",";
      });
    }

    requestFormat = requestFormat.slice(0, -1);
    requestFormat += "]}}";

    if (sendRequest) {
      window.clientSocks.send(requestFormat);
    }
  }

  if (deltaRequests.length > 0) {
    const requestThreshold = threshold * 60;
    let requestFormat = '{"action": "market_data","data":{"threshold":' + requestThreshold + ',"request":true,"fields":[3],"tickers":[';
    deltaRequests.map((s) => {
      requestFormat += JSON.stringify(s);
      requestFormat += ",";
    });

    requestFormat = requestFormat.slice(0, -1);
    requestFormat += "]}}";

    if (sendRequest) {
      window.clientSocks.send(requestFormat);

    }
  }

  return [toSend.data, socketRequest.length, sendRequest];
}
