const AWS = require("aws-sdk");

let globalCredentials = null; // Store credentials globally

const Sockets = (apiData, user) => {
  const readData = async (s3Link, bucket = "socket") => {
    let bucketName = apiData?.websocket?.socketBucket;
    if (bucket === "eod") {
      bucketName = apiData?.api?.eodBucket;
    }

    // Initialize credentials if not already done
    if (!globalCredentials) {
      globalCredentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: apiData?.api?.identityPool,
        Logins: {
          [`cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${apiData?.api?.userPool}`]: user?.idToken,
        },
      });

      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: globalCredentials,
      });
    }

    
      await globalCredentials.getPromise();
    

    const s3 = new AWS.S3();

    let headParams = {
      Bucket: bucketName,
      Key: s3Link,
    };

    try {
      // Get the file size
      let headData = await s3.headObject(headParams).promise();
      const fileSize = headData.ContentLength;
      const chunkSize =  1024 * 1024;
      const numChunks = Math.ceil(fileSize / chunkSize);

      // Create an array of promises for each chunk
      const chunkPromises = Array.from({ length: numChunks }, (_, index) => {
        const rangeStart = index * chunkSize;
        const rangeEnd = Math.min(rangeStart + chunkSize - 1, fileSize - 1);

        let params = {
          Bucket: bucketName,
          Key: s3Link,
          Range: `bytes=${rangeStart}-${rangeEnd}`,
        };

        return s3.getObject(params).promise().then(data => data.Body.toString("utf-8"));
      });

      // Wait for all chunks to be fetched
      const chunks = await Promise.all(chunkPromises);
      const fileContent = chunks.join('');
      return fileContent;
    } catch (err) {
      console.error("Error reading data from S3:", err);
      throw err;
    }
  };

  const resetCredentials = () => {
    globalCredentials = null;
  };

  return { readData, resetCredentials };
};

export default Sockets;
