import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ButtonAction } from "../../../Redux/Actions/todoActions";
import TargetAllocation from "./TargetAllocation";
import TotalFund from "./TotalFund";
import {
  calculateAndFormatNoAbs,
  findBrokerAbb,
  findBrokerId,
  findStrategy,
  fourDecimal,
  twoDecimal,
} from "../../Methods";
import { toast } from "react-toastify";
import { GetAllocation } from "../../Layouts/dashboardCalculations";
import Existing from "./TotalFund/Existing";
import Target from "./TotalFund/Target";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../Redux/Reducers/todoReducers";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { colorPalette } from "../../Methods/colors";
import {
  sortData,
  sortFunds,
  sortStrategies,
  updateLongShort,
  updateTotals,
} from "../../Methods/fundAlloc";

const FundAllocation = () => {
  const theme = useTheme();
  const [activeAum, setActiveAum] = useState(0);
  const [matchedAllocations, setMatchedAllocations] = useState([]);
  const [isAllocationValid, setIsAllocationValid] = useState(true);
  const [allocation, setAllocation] = useState({});
  const [fundId, setFundId] = useState();
  const [oldData, setOldData] = useState([]);
  const [existing, setExisting] = useState([]);
  const [fundAum, setFundAum] = useState(0);
  const [totals, setTotals] = useState([]);
  const [fundVal, setFundVal] = useState();
  const [Strategies, setStrategy] = useState([]);
  const [StrategiesLowAum, setStrategyLowAum] = useState([]);
  const [StrategiesCombined, setStrategyCombined] = useState([]);
  const [autoCompletedValue, setautoCompletedValue] = useState([]);
  const [editableValue, setEditableValue] = useState(0);
  const [Piedata, setPieData] = useState([]);
  const [PiedataTarget, setPieDataTarget] = useState([]);
  const Buttonflag = useSelector((state) => state?.todos?.buttonflag);
  const [showRecalculate, setShowRecalculate] = useState(true);
  const [editableData, setEditableData] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setActiveAum(fundAum);
  }, [fundAum]);
  const {
    funds,
    strategies,
    accountingData,
    lots,
    marketData,
    data,
    managers,
    markets,
    livePrices,
  } = useSelector((state) => state?.todos);
  const dispatch = useDispatch();
  const sortedFunds = sortFunds(funds);
  const sortedData = sortData(data);

  useEffect(() => {
    if (Strategies.length > 0) {
      const { highAumStrategies, lowAumStrategies } =
        sortStrategies(Strategies);
      setStrategyLowAum(lowAumStrategies);
      setStrategyCombined(highAumStrategies);
    } else {
      setStrategyCombined([]);
      setStrategyLowAum([]);
    }
  }, [Strategies]);

  useEffect(() => {
    dispatch(ButtonAction(false));
  }, [dispatch]);

  useEffect(() => {
    if (Buttonflag) {
      setShowRecalculate(true);
    }
  }, [Buttonflag, allocation]);

  useEffect(() => {
    const fundList = funds
      ?.map((i) => i?.abbreviation)
      ?.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
    setFundVal(fundList?.[0]);
  }, [funds]);

  const getFund = (fundId) => {
    const fundData = managers?.[0]?.funds?.[fundId];
    if (fundData?.allocation) {
      const allocation = {
        items: Object.fromEntries(
          Object.entries(fundData.allocation.items).map(([key, value]) => [
            key,
            {
              ...value,
              broker: findBrokerAbb(value?.broker, data),
              percentLong: (value?.percentLong ?? value?.percentlong) * 100,
              percentShort: (value?.percentShort ?? value?.percentshort) * 100,
              totalAllocation:
                (value?.totalAllocation ?? value?.totalallocation) * 100,
              ignoreWhenRebalancing:
                value?.ignoreWhenRebalancing ?? value?.ignorewhenrebalancing,
            },
          ])
        ),
      };
      setAllocation(allocation);
    } else {
      setAllocation(null);
    }
  };

  useEffect(() => {
    if (managers) {
      getFund(fundId);
    }
  }, [fundId, managers]);

  useEffect(() => {
    if (allocation && allocation.items && StrategiesCombined.length > 0) {
      const fundAllocations = allocation.items;
      const sortedStrategies = [...StrategiesCombined].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      const strategyColorMap = sortedStrategies?.reduce(
        (acc, strategy, index) => {
          const color = colorPalette[index % colorPalette.length];
          acc[strategy?.name] = color;
          return acc;
        },
        {}
      );

      const newAllocations = sortedStrategies?.map((strategy) => {
        const allocationData = fundAllocations[strategy.strategyId];

        if (allocationData) {
          const {
            ignoreWhenRebalancing,
            percentLong,
            percentShort,
            totalAllocation,
            broker,
            account,
            ...rest
          } = allocationData;
          const { long, short } = GetAllocation(
            fundId,
            strategy?.name,
            lots,
            accountingData,
            funds,
            strategies,
            livePrices,
            markets
          );

          const existingPercent = calculateAndFormatNoAbs(
            (strategy?.aum / fundAum) * 100
          );

          const fundAumCal = parseFloat(activeAum) || 0;
          const alcTarget = (fundAumCal * Number(totalAllocation)) / 100;
          const alcExisting = strategy?.aum;
          const formattedLong = twoDecimal((long / strategy?.aum) * 100);
          const formattedShort = twoDecimal(
            (Math.abs(short) / strategy?.aum) * 100
          );
          const strategyColor = strategyColorMap[strategy?.name];

          return {
            ...strategy,
            ...rest,
            ignoreWhenRebalancing: Boolean(ignoreWhenRebalancing),
            existingAllocation: alcExisting
              ? fourDecimal(alcExisting)
              : fourDecimal(0),
            allocation: alcTarget ? twoDecimal(alcTarget) : "",
            totalAllocation: totalAllocation
              ? fourDecimal(Number(totalAllocation))
              : "",
            percentShort: percentShort
              ? fourDecimal(-Math.abs(Number(percentShort)))
              : "",

            percentLong: percentLong ? fourDecimal(Number(percentLong)) : "",
            existingPercent: Number(existingPercent) || 0,
            long: Number(formattedLong) || 0,
            short: Number(formattedShort) || 0,
            broker: broker,
            account: account,
            color: strategyColor,
          };
        } else {
          const { long, short } = GetAllocation(
            fundId,
            strategy?.name,
            lots,
            accountingData,
            funds,
            strategies,
            livePrices,
            markets
          );

          const formattedLong = twoDecimal((long / strategy?.aum) * 100);
          const formattedShort = twoDecimal(
            (Math.abs(short) / strategy?.aum) * 100
          );

          const fundAumCal = parseFloat(activeAum) || 0;
          const existingPercent = (strategy?.aum / fundAumCal) * 100;
          const strategyColor = strategyColorMap[strategy?.name];

          return {
            ...strategy,
            broker: "",
            account: "",
            existingAllocation: strategy?.aum,
            allocation: "",
            totalAllocation: "",
            percentShort: "",
            ignoreWhenRebalancing: false,
            percentLong: "",
            existingPercent: existingPercent,
            long: Number(formattedLong) || 0,
            short: Number(formattedShort) || 0,
            color: strategyColor,
          };
        }
      });
      const pieExisting = newAllocations?.map((strategy) => ({
        name: strategy?.name,
        value: Number(strategy?.existingPercent) || 0,
        color: strategy.color,
      }));

      const pieTarget = newAllocations?.map((strategy) => ({
        name: strategy?.name,
        value: Number(strategy?.totalAllocation) || 0,
        color: strategy.color,
      }));
      setPieData(pieExisting);
      setPieDataTarget(pieTarget);
      setMatchedAllocations(newAllocations);
      setEditableData(newAllocations);
      setExisting(newAllocations);
      setTotals(updateTotals(newAllocations, editableValue));
      setOldData(updateLongShort(newAllocations, fundAum));
    } else {
      const zeroedAllocations = Strategies.map((strategy) => ({
        ...strategy,
        broker: "",
        account: "",
        totalAllocation: "",
        percentShort: "",
        ignoreWhenRebalancing: false,
        percentLong: twoDecimal(0),
        existingPercent: 0,
        long: 0,
        short: 0,
        color: "#E6E6E6",
      }));

      setMatchedAllocations(zeroedAllocations);
      setExisting(zeroedAllocations);
      setOldData([
        { name: "Total for Long", changeValue: 0, changeAllocation: 0 },
        { name: "Total for Short", changeValue: 0, changeAllocation: 0 },
        { name: "Total Gross", changeValue: 0, changeAllocation: 0 },
        { name: "Total Net", changeValue: 0, changeAllocation: 0 },
      ]);
      setTotals([
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
        { changeValue: 0, changeAllocation: 0 },
      ]);
      setPieData([]);
    }
  }, [
    allocation,
    Strategies,
    managers,
    activeAum,
    fundAum,
    fundId,
    lots,
    accountingData,
    funds,
    fundVal,
    marketData,
    StrategiesCombined,
  ]);

  useEffect(() => {
    if (fundAum) {
      setEditableValue(fundAum);
    }
  }, [fundAum]);

  const handleFundChange = (newValue) => {
    setFundVal(newValue);
    setHasChanges(false);

    if (newValue === null) {
      setFundId();
      setFundAum(0);
      setActiveAum(0);
      setAllocation(null);
      setautoCompletedValue([]);
      setStrategy([]);
      setMatchedAllocations([]);
      setExisting([]);
      return;
    }

    const item = funds?.find((e) => e.abbreviation === newValue);
    if (item?.accounts && Object.keys(item?.accounts).length) {
      const transformedAccounts = Object.keys(item.accounts)
        .map((key) => ({
          name: key,
          custodian: item.accounts[key].custodian,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setautoCompletedValue(transformedAccounts);
    } else {
      setautoCompletedValue([]);
    }

    if (item && item?.fundId) {
      setFundId(item?.fundId);
      getFund(item?.fundId);

      const foundFund = accountingData[0]?.funds?.find((row) => {
        const fundIdValue =
          typeof row?.fund === "string" ? row?.fund : row?.fund?.fundId;
        return fundIdValue === item?.fundId;
      });

      if (foundFund) {
        setFundAum(foundFund?.aum);
        setActiveAum(foundFund?.aum);
        const strategyAUMsData = Object.keys(foundFund?.strategyAUMs || {})
          .filter((strategyId) =>
            strategies.some((strategy) => strategy?.strategyId === strategyId)
          )
          .map((strategyId) => {
            return {
              strategyId: strategyId,
              name: findStrategy(strategyId, strategies),
              aum: foundFund?.strategyAUMs[strategyId],
            };
          });

        if (strategyAUMsData?.length > 0) {
          setMatchedAllocations([]);
          setExisting([]);
          setStrategy(strategyAUMsData);
        } else {
          setStrategy([]);
          setMatchedAllocations([]);
          setExisting([]);
        }
      } else {
        setFundAum(0);
        setEditableValue(0);
        setActiveAum(0);
        setFundId();
        setMatchedAllocations([]);
        setStrategy([]);
      }
    }
  };

  useEffect(() => {
    if (fundId) {
    }
  }, [fundId]);

  const handleSave = () => {
    const totalAllocationSum = matchedAllocations.reduce((sum, strategy) => {
      const totalAllocation = parseFloat(strategy.totalAllocation);
      return sum + (isNaN(totalAllocation) ? 0 : totalAllocation / 100);
    }, 0);

    if (totalAllocationSum > 1) {
      setIsAllocationValid(false);
      toast.error("Allocation must not exceed 100%");
      return;
    } else {
      setIsAllocationValid(true);

      const formattedAllocations = matchedAllocations.reduce(
        (acc, strategy) => {
          const {
            strategyId,
            account,
            totalAllocation,
            percentShort,
            percentLong,
            ignoreWhenRebalancing,
            broker,
          } = strategy;

          const parsedTotalAllocation = parseFloat(totalAllocation);
          const parsedPercentShort = parseFloat(percentShort);
          const parsedPercentLong = parseFloat(percentLong);

          if (parseFloat(totalAllocation) !== 0) {
            acc[strategyId] = {
              account: account || autoCompletedValue[0]?.name,
              totalAllocation: isNaN(parsedTotalAllocation)
                ? 0
                : parsedTotalAllocation / 100,
              percentShort: isNaN(parsedPercentShort)
                ? 0
                : parsedPercentShort / 100,
              percentLong: isNaN(parsedPercentLong)
                ? 0
                : parsedPercentLong / 100,
              ignoreWhenRebalancing,
              broker: findBrokerId(broker || sortedData[0]?.abbreviation, data),
            };
          }

          return acc;
        },
        {}
      );
      const finalData = {
        action: "set_fund_allocation",
        data: {
          allocations: formattedAllocations,
          fund: fundId,
          targetedAUM: editableValue,
        },
      };

      window.clientSocks.send(JSON.stringify(finalData));
      dispatch(incrementActiveRequests());
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg("Updating Fund Allocation"));
    }
  };

  const totalAllocationSumTarget = matchedAllocations?.reduce(
    (sum, strategy) => {
      return sum + (parseFloat(strategy?.totalAllocation) || 0);
    },
    0
  );
  const totalAllocationSumExisting = matchedAllocations?.reduce(
    (sum, strategy) => {
      return sum + (parseFloat(strategy?.existingPercent) || 0);
    },
    0
  );
  const handleDoneEditing = () => {
    setMatchedAllocations((prevAllocations) => {
      const updatedAllocations = [...prevAllocations];
      updatedAllocations.forEach((allocation, index) => {
        updatedAllocations[index] = {
          ...updatedAllocations[index],
          ...editableData[index],
        };
      });
      setTotals(updateTotals(updatedAllocations, editableValue));

      const pieTarget = updatedAllocations.map((strategy) => ({
        name: strategy.name,
        value: Number(strategy.totalAllocation) || 0,
        color: strategy?.color,
      }));

      setPieDataTarget(pieTarget);
      setHasChanges(false);
      return updatedAllocations;
    });
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ marginTop: 5, marginBottom: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                  View and Edit Fund Allocations
                </Typography>
              </div>
              <div>
                {hasChanges ? (
                  <Button
                    variant="contained"
                    onClick={handleDoneEditing}
                    sx={{ mx: 1 }}
                  >
                    Done Editing
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleSave}>
                    SAVE CHANGES
                  </Button>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              value={fundVal}
              options={sortedFunds}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => handleFundChange(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Fund"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                alignItems: "row",
                flexWrap: "wrap",
              }}
            >
              <TotalFund totals={totals} oldData={oldData} fundAum={fundAum} />
              <Existing totals={totals} oldData={oldData} fundAum={fundAum} />
              <Target
                totals={totals}
                oldData={oldData}
                editableValue={editableValue}
                setEditableValue={setEditableValue}
                setActiveAum={setActiveAum}
                fundAum={fundAum}
              />

              {Piedata.length > 0 ? (
                <div
                  style={{
                    width: "600px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={Piedata}
                        cx={150}
                        cy={150}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {Piedata.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                    <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                      Existing Allocation
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                      {`Total Allocation: ${totalAllocationSumExisting?.toFixed(
                        2
                      )}%`}
                    </Typography>
                  </div>

                  <div>
                    <PieChart width={300} height={300}>
                      <Pie
                        data={PiedataTarget}
                        cx={150}
                        cy={150}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {PiedataTarget.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                    <Typography sx={{ fontSize: "24px", fontWeight: 900 }}>
                      Target Allocation
                    </Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                      {`Total Allocation: ${totalAllocationSumTarget?.toFixed(
                        2
                      )}%`}
                    </Typography>
                  </div>
                </div>
              ) : (
                <Typography>No data available</Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "25px", fontWeight: 900 }}>
                Fund Allocation
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <TargetAllocation
                  custodians={autoCompletedValue}
                  sortedData={sortedData}
                  matchedAllocations={matchedAllocations}
                  setMatchedAllocations={setMatchedAllocations}
                  fundAum={fundAum}
                  fundId={fundId}
                  setPieDataTarget={setPieDataTarget}
                  editableValue={editableValue}
                  setEditableData={setEditableData}
                  editableData={editableData}
                  StrategiesLowAum={StrategiesLowAum}
                  setStrategyCombined={setStrategyCombined}
                  setStrategyLowAum={setStrategyLowAum}
                  setHasChanges={setHasChanges}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FundAllocation;
