import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment/moment";
import { Tablecell, findCust } from "../../../Methods";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePopUp from "../../../Popup/DeletePopUp";
import Popup from "../../../Popup";
import EditFund from "../../EditFund";
import { useSelector } from "react-redux";
import Blacklist from "../../Blacklist";
import ClosedCaptionDisabledIcon from "@mui/icons-material/ClosedCaptionDisabled";

const Row = (props) => {
  const { row, data, setData } = props;
  const { custodians, accountingData, strategies } = useSelector(
    (state) => state.todos
  );
  const accounting = accountingData[0]?.funds;
  const [open, setOpen] = useState(false);
  const [Strategies, setStrategies] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForBlacklist, setRecordForBlacklist] = useState(null);
  const [BlacklistData, setBlacklistData] = useState(null);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);
  const [openBlacklistPopup, setBlacklistOpenPopup] = useState(false);
  const [selectedFund, setSelectedFund] = useState(null);

  const hasAccountData = (row?.fundAccounts || [])?.length > 0;
  const theme = useTheme();
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };
  const openInPopupBlacklist = (item) => {
    function consolidateRules(data) {
      const consolidatedRules = [];
      const ruleMap = {};

      data?.rules?.forEach((rule) => {
        if (rule.ticker) {
          // Create a key based on all properties except the ticker
          const { ticker, ...rest } = rule;
          const key = JSON.stringify(rest);

          if (!ruleMap[key]) {
            ruleMap[key] = {
              ...rest,
              tickers: [],
            };
            consolidatedRules.push(ruleMap[key]);
          }
          ruleMap[key].tickers.push(ticker.symbol);
        } else {
          consolidatedRules.push(rule);
        }
      });

      return {
        ...data,
        rules: consolidatedRules,
      };
    }

    const mergedRules = consolidateRules(item?.blacklist);

    setBlacklistData(mergedRules);
    setRecordForBlacklist(item?.fundId);
    const findFund = accounting?.find((i) => i.fund === item.fundId);
    if (findFund && findFund.strategyAUMs) {
      const strategyAums = findFund.strategyAUMs;
      const matchingStrategies = strategies?.filter((strategy) =>
        strategyAums.hasOwnProperty(strategy.strategyId)
      );
      setStrategies(matchingStrategies);
    } else {
      setStrategies([]);
    }
    setSelectedFund(item?.name);
    setBlacklistOpenPopup(true);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:hover": {
            background: theme.palette.grey[200],
            cursor: "pointer",
            ".deleteIcon": {
              visibility: "visible",
            },
          },
        }}
      >
        <Tablecell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ height: "10px", width: "10px", mx: 1 }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Tablecell>
        <Tablecell
          align="left"
          sx={{ paddingLeft: "5px" }}
          component="th"
          scope="row"
        >
          {row?.abbreviation ? row?.abbreviation : "-"}
        </Tablecell>

        <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
          {row?.name ? row?.name : "-"}
        </Tablecell>
        <Tablecell align="right" sx={{ paddingRight: "5px", width: "160px" }}>
          ${" "}
          {row?.SelectedAum
            ? row?.SelectedAum?.toLocaleString("en-US", {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : 0}
        </Tablecell>
        <Tablecell align="right" sx={{ paddingLeft: "5px", width: "150px" }}>
          {row?.countCustodians}
        </Tablecell>
        <Tablecell align="right" sx={{ paddingLeft: "5px", width: "150px" }}>
          {row?.countAccounts}
        </Tablecell>
        <Tablecell align="right" sx={{ paddingLeft: "5px", width: "150px" }}>
          0
        </Tablecell>
        <Tablecell align="left" sx={{ paddingLeft: "5px", width: "150px" }}>
          {row?.date ? moment(row?.date).format("MMM DD, YYYY") : "-"}
        </Tablecell>
        <Tablecell
          align="center"
          sx={{
            paddingLeft: "5px",
            width: "150px",
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              // visibility: "hidden",
            }}
            // className="deleteIcon"
          >
            <IconButton
              sx={{ height: "20px", Minwidth: "10px" }}
              onClick={() => {
                openInPopupBlacklist(row);
              }}
            >
              <ClosedCaptionDisabledIcon
                sx={{
                  color: "#808080",
                  width: "20px",
                  height: "15px",
                }}
              />
            </IconButton>
          </Box>
        </Tablecell>
        {/* <Tablecell
          align="center"
          sx={{
            paddingLeft: "5px",
            width: "150px",
          }}
        >
          <Box
            component="span"
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              visibility: "hidden",
            }}
            className="deleteIcon"
          >
            <IconButton
              sx={{ height: "20px", Minwidth: "10px" }}
              onClick={() => {
                openInPopup(row);
              }}
            >
              <EditIcon
                sx={{
                  color: "#808080",
                  width: "20px",
                  height: "15px",
                }}
              />
            </IconButton>
            <IconButton
              sx={{ height: "20px", Minwidth: "10px" }}
              onClick={() => {
                openInDeletePopup(row);
              }}
            >
              <DeleteIcon
                sx={{
                  color: "#808080",
                  width: "20px",
                  height: "15px",
                }}
              />
            </IconButton>
          </Box>
        </Tablecell> */}
      </TableRow>
      {hasAccountData && (
        <TableRow>
          <Tablecell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="strategies-aum-table">
                  <TableHead>
                    <TableRow>
                      <Tablecell align="left" sx={{ border: "none" }}>
                        Account Names
                      </Tablecell>
                      <Tablecell align="left" sx={{ border: "none" }}>
                        Custodian
                      </Tablecell>
                      <Tablecell align="left" sx={{ border: "none" }}>
                        Number
                      </Tablecell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(row?.fundAccounts || [])?.map((fund, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            background: theme.palette.grey[200],
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Tablecell align="left" sx={{ borderRight: "none" }}>
                          {fund?.name}
                        </Tablecell>
                        <Tablecell
                          align="left"
                          sx={{ borderLeft: "none", borderRight: "none" }}
                        >
                          {findCust(fund?.custodian, custodians)}
                        </Tablecell>
                        <Tablecell align="left" sx={{ borderLeft: "none" }}>
                          {fund?.number}
                        </Tablecell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </Tablecell>
        </TableRow>
      )}
      {!hasAccountData && open && (
        <TableRow>
          <TableCell colSpan={9}>
            <Typography variant="subtitle1">No Accounts</Typography>
          </TableCell>
        </TableRow>
      )}
      <DeletePopUp
        title="Delete a Fund"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_fund"
        item="fund"
      />

      <Popup
        title="Edit Fund details"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditFund
          recordForEdit={recordForEdit}
          data={data}
          setData={setData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>

      <Blacklist
        title={`${selectedFund} Blacklist`}
        strategies={strategies}
        Strategies={Strategies}
        data={data}
        setData={setData}
        openBlacklistPopup={openBlacklistPopup}
        setBlacklistOpenPopup={setBlacklistOpenPopup}
        recordForBlacklist={recordForBlacklist}
        BlacklistData={BlacklistData}
      />
    </React.Fragment>
  );
};
export default Row;
