import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import {
  Tablecell,
  calculateAndFormatNoAbs,
  formatValue,
} from "../../../../Methods";
const Existing = ({ totals, oldData, fundAum }) => {
  const theme = useTheme();

  const [editableOldData, setEditableOldData] = useState(oldData);

  useEffect(() => {
    setEditableOldData(oldData);
  }, [totals, oldData, fundAum]);

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "50px",
          width: "25%",
          marginRight: 1,
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "50%",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#B5D0D9",
                  textAlign: "center",
                }}
              >
                Existing
              </Tablecell>
            </TableRow>
          </TableBody>

          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Amount
            </Tablecell>
          </TableRow>

          <TableBody>
            {editableOldData?.map((row, index) => (
              <TableRow
                key={index} // Add unique key for each row
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "900",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    paddingRight: "5px",
                  }}
                >
                  {row?.changeValue} {/* Percent */}
                </Tablecell>
                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    paddingRight: "5px",
                  }}
                >
                  {row?.changeAllocation} {/* Amount */}
                </Tablecell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#B5D0D9",
                  textAlign: "center",
                  py: "2px",
                }}
              >
                <Box sx={{ height: "40px" }}>
                  <Typography
                    sx={{
                      fontSize: theme.typography.bodyText12.fontSize,
                      fontWeight: theme.typography.bodyText12.fontWeight,
                      mt: "5px",
                    }}
                  >
                    Existing
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: theme.typography.bodyText12.fontSize,
                      fontWeight: 900,
                    }}
                  >
                    ${calculateAndFormatNoAbs(fundAum)}
                  </Typography>
                </Box>
              </Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};
export default Existing;
