import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  Card,
  TableContainer,
} from "@mui/material";
import VantageListHead from "../../../../ListHead/SignalList";
import { Tablecell, applySortFilter, getComparator } from "../../../../Methods";

const Allocated = ({ alloc, tableHeader, error }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("expiration");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (alloc?.length > 0) {
      const filteredUsers = applySortFilter(
        alloc,
        getComparator(order, orderBy)
      );
      setData(filteredUsers);
    }
  }, [order, orderBy, alloc]);

  return (
    <>
      {data && data?.length > 0 ? (
        <Box
          sx={{
            border: `100px ${theme.palette.lightGrey.tableHeader}`,
            borderRadius: "4px",
            marginTop: "10px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              textAlign: "left",
              textDecoration: "none",
              color: "red",
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
                textUnderlineOffset: "2px",
              },
            }}
          >
            {error}{" "}
          </Typography>

            <TableContainer style={{ height: "180px" }}>
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                sx={{
                  height: "max-content",
                }}
              >
                <VantageListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHeader}
                  noStatus={true}
                  customizeFont={12}
                  rowCount={data.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:hover": {
                          background: theme.palette.grey[200],
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row.fund}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row.strategy}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row.status}
                      </Tablecell>
                      <Tablecell
                        align="left"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          paddingLeft: "5px",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        {row.reason}
                      </Tablecell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Box>
      ) : null}
    </>
  );
};

export default Allocated;
