import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Checkbox,
  Typography,
  TableCell,
  TextField,
} from "@mui/material";
import {
  Tablecell,
  formatValue,
  twoDecimal,
  calculateAndFormatNoAbs,
} from "../../../Methods";
import { total_fund } from "../../../dummyData";
const TotalFund = ({ totals, oldData, fundAum }) => {
  const theme = useTheme();
  const [editableValue, setEditableValue] = useState(0);
  const [editableData, setEditableData] = useState(totals);
  const [editableOldData, setEditableOldData] = useState(oldData);

  const [myVal, setVal] = useState(true);

  useEffect(() => {
    setEditableData(totals);
    setEditableOldData(oldData);
    setEditableValue(formatValue(fundAum));
  }, [totals, oldData, fundAum]);

  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "50px",
          width: "10%",
          marginRight: 1,
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "50%",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#F2F2F2",
                  textAlign: "center",
                  width: "100px",
                  height: "38px",
                  py: 1.4,
                }}
              >
                Total for Fund
              </Tablecell>
            </TableRow>
          </TableBody>

          <TableBody>
            {editableOldData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="left"
                  sx={{
                    fontWeight: "900",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                  }}
                >
                  {row?.name}
                </Tablecell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                colSpan={1}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#F2F2F2",
                  textAlign: "center",
                  verticalAlign: "middle",
                  py: "2px",
                }}
              >
                <Box sx={{ height: "40px" }}>
                  <Typography
                    sx={{
                      fontSize: theme.typography.bodyText12.fontSize,
                      fontWeight: 900,
                      mt: "5px",
                    }}
                  >
                    Fund AUM
                  </Typography>
                </Box>
              </Tablecell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>

    // <>
    //   <Box
    //     sx={{
    //       border: `1px ${theme.palette.lightGrey.tableHeader}`,
    //       borderRadius: "4px",
    //       marginTop: "50px",
    //       width: "10%",
    //       marginRight: 1,
    //     }}
    //   >
    //     <Table
    //       size="large"
    //       stickyHeader
    //       aria-label="a dense table"
    //       sx={{
    //         height: "50%",
    //         borderCllapse: "collapse",
    //         border: "solid 1px #eee",
    //         marginTop: "-1px",
    //       }}
    //     >
    //       <TableRow
    //         sx={{
    //           backgroundColor: "#B5D0D9",
    //         }}
    //       >
    //         <Tablecell
    //           sx={{
    //             fontSize: theme.typography.bodyText12.fontSize,
    //             fontWeight: theme.typography.bodyText12.fontWeight,
    //             backgroundColor: "#F2F2F2",
    //             textAlign: "center",
    //             width: "100px",
    //             height: "38px",
    //           }}
    //         >
    //           Total for Fund
    //         </Tablecell>
    //       </TableRow>

    //       <TableBody>
    //         {editableOldData?.map((row, index) => (
    //           <TableRow
    //             sx={{
    //               "&:hover": {
    //                 background: theme.palette.grey[200],
    //                 cursor: "pointer",
    //               },
    //             }}
    //           >
    //             <Tablecell
    //               align="left"
    //               sx={{
    //                 fontWeight: "900",
    //                 fontSize: "12px",
    //                 paddingLeft: "5px",
    //                 borderRight: "none",
    //               }}
    //             >
    //               {row?.name}
    //             </Tablecell>
    //           </TableRow>
    //         ))}

    //         <TableRow
    //           sx={{
    //             backgroundColor: "#B5D0D9",
    //           }}
    //         >
    //           <Tablecell
    //             colSpan={1}
    //             sx={{
    //               fontSize: theme.typography.bodyText12.fontSize,
    //               fontWeight: theme.typography.bodyText12.fontWeight,
    //               backgroundColor: "#F2F2F2",
    //               textAlign: "center",
    //               verticalAlign: "middle",
    //               py:"2px"
    //             }}
    //           >
    //             <Box sx={{ height: "35px" }}>
    //               <Typography
    //                 sx={{
    //                   fontSize: theme.typography.bodyText12.fontSize,
    //                   fontWeight: 900,
    //                   mt: "10px",
    //                 }}
    //               >
    //                 Fund AUM
    //               </Typography>
    //             </Box>
    //           </Tablecell>
    //         </TableRow>
    //       </TableBody>
    //     </Table>
    //   </Box>
    // </>
  );
};
export default TotalFund;
