import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Link, IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { verify } from "../../../utils/restAPI";
import { updateLoadingAxios } from "../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";

const EmailVerification = ({ email }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Apierror, setApiError] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isButtonDisabled, setIsDisabled] = useState(false);

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const codeRefs = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];

  const handleBackToLoginClick = () => {
    navigate("/login");
  };

  const handleChange = (index) => (event) => {
    const value = event.target.value;
    if (value.match(/^\d*$/)) {
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index] = value;
        return newCode;
      });
      if (index < codeRefs.length - 1 && value !== "") {
        codeRefs[index + 1].current.focus();
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    dispatch(updateLoadingAxios(true));
    // if (code.some((digit) => digit === "")) {
    //   console.error("Please fill in all code digits");
    //dispatch(updateLoadingAxios(false));
    //   return;
    // }
    if (!email) {
      setApiError(true);
      dispatch(updateLoadingAxios(false));
      return;
    }

    try {
      const response = await verify(email, code.join(""));
      dispatch(updateLoadingAxios(false));
      if (response && response.success) {
        setIsDisabled(true);
        setIsCodeSent(true);
        setApiError(false);
      } else {
        setIsDisabled(false);
        setIsCodeSent(false);
        setApiError(true);
      }
    } catch (error) {
      console.error("An error occurred during verification:", error);
      setIsDisabled(false);
      setIsCodeSent(false);
      setApiError(true);
      dispatch(updateLoadingAxios(false));
    }
  };

  const theme = useTheme();

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 1,

          position: "relative",
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          fontWeight={600}
          fontSize={35}
          color={theme.palette.black}
        >
          VERIFY YOUR ACCOUNT
        </Typography>
        <Typography variant="h5"
          sx={{ cursor: "pointer", height: "30px", fontSize: "16px", textDecoration: "none", marginBottom: "20px" }}>
          You're almost there! We have sent an email with verification code to{" "} <strong>{email}</strong>.
        </Typography>
        <Typography variant="h5"
          sx={{ cursor: "pointer", height: "30px", fontSize: "16px", textDecoration: "none", marginBottom: "20px" }}>
          Please enter the code below to complete your sign up. If you don't see
          it, you may need to check your <strong>spam folder</strong>.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {code.map((digit, index) => (
              <TextField
                key={index}
                inputRef={codeRefs[index]}
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                size="small"
                autoFocus={index === 0}
                value={digit}
                onChange={handleChange(index)}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center" },
                }}
              />
            ))}
          </Box>
          <Button type="submit" variant="contained" disabled={isButtonDisabled} size="large" sx={{ borderRadius: '20px', marginTop: "20px", alignSelf: "center" }} >


            Verify Account
          </Button>
        </form>
        {Apierror && (
          <Alert severity="error" sx={{ my: 1 }}>
            Sign-up failed. Please check your details and try again.
          </Alert>
        )}
        {isCodeSent && (
          <Alert severity="success" sx={{ my: 1 }}>
            Your email address is successfully verified.
          </Alert>
        )}
      
      </Box>
    </Container>


  );
};

export default EmailVerification;
