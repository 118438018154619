import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Link } from "@mui/material";
import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Grid from "@mui/material/Grid";

import { forgotPassword } from "../../../utils/restAPI";
import { useDispatch } from "react-redux";
import { updateLoadingAxios } from "../../../Redux/Reducers/todoReducers";
import logoImage from "../../../logo.png";


const ForgotUserPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailVerified, setEmailVerified] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiMessage, setApiMessage] = useState("Something Went Wrong..");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async ({ email }) => {
      dispatch(updateLoadingAxios(true));

      try {
        const response = await forgotPassword(email);
        dispatch(updateLoadingAxios(false));

        if (response.success && response.data.success) {
          navigate("/resetPass", { state: { fromButton: true, email: email } });
          setEmailVerified(false);
          setApiError(false);
        } else {
          setApiMessage(response.data);
          setEmailVerified(true);
          setApiError(true);
        }
      } catch (error) {
        setApiMessage("Something went wrong..");
        setApiError(true);
        setEmailVerified(true);
      }
    },
  });

  const handleBackToLoginClick = () => {
    navigate("/login");
  };
  const theme = useTheme();
  return (
    <Container sx={{
      width: "100%",
      maxWidth: "100% !important",
      backgroundColor: "#f5f5f5",
      backgroundColor: "#f5f5f5",
      minHeight: "100vh",
    }}>

      <Box sx={{
        padding: "20px",
        background: "#000",

        marginBottom: "20px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}>


        <img src={logoImage} alt="Logo" style={{ width: '150px', marginTop: "10px" }} />
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ color: "white", marginTop: "10px", marginBottom: "-5px" }}
        >
          V 3.2.1
        </Typography>
      </Box>


      <Container
        component="main"
        maxWidth="sm"
        sx={{

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80vh",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 4px",
            backgroundColor: "white",
            padding: "50px 30px",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            fontWeight={600}
            fontSize={40}
            color={theme.palette.black}
          >
            Password Reset
          </Typography>

          <Typography variant="h5"
            sx={{ cursor: "pointer", height: "30px", fontSize: "16px", textDecoration: "none", marginBottom: "-10px" }}>
            Provide the email address you used when you joined and we'll send you instructions to reset your password.
          </Typography>



          <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              {emailVerified && (
                <Alert severity="error" sx={{ my: 1, width: "100%" }}>
                  {apiMessage}
                </Alert>
              )}
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Button type="submit" variant="contained" size="large" sx={{ borderRadius: '20px' }} disabled={formik.isSubmitting}>
                  Reset Password
                </Button>
                <Link
                  color="primary"
                  variant="h5"
                  onClick={handleBackToLoginClick}
                  sx={{ cursor: "pointer", fontSize: "16px", textDecoration: "none" }}
                >
                  Back to login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Container >
  );
};

export default ForgotUserPassword;
