import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { useLocation } from "react-router-dom";
import theme from "./Theme";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogUser } from "./utils/restAPI";
import "./Style/Fonts.css";

import {
  getFunds,
  getStrategies,
  getLots,
  getTrades,
  getDeletes,
  getCustodians,
  getFtp,
  getTickers,
  getRouters,
  getBrokers,
  TradeCustodianFile,
  addNewBrokers,
  addNewTrades,
  addNewOrders,
  addNewManual,
  addNewFill,
  deleteTrades,
  getAccountingSystem,
  addNewCommission,
  TradeCustodianAccountingFile,
  getUserPreference,
  getHistoricalData,
  addNewCustodians,
  modifyCustodians,
  deleteCustodian,
  modifyBrokers,
  deleteBroker,
  addNewFunds,
  modifyFunds,
  saveMarketData,
  saveMarketDataBloomberg,
  deleteFund,
  addNewStrategy,
  modifyStrategy,
  deleteStrategy,
  modifySignal,
  getFees,
  getManagers,
  getNotifications,
  getCustomFunds,
  modifyNotification,
  modifyUnreadNotification,
  modifySignalValue,
  getAllocationData,
  stageOneOfSleeve,
  stageTwoOfRebalance,
  finalCrossedTrades,
  stageThreeOfRebalance,
  RebalanceSummary,
  blacklistRulesManager,
  blacklistRulesModifiedManager,
  blacklistRulesFunds,
  blacklistRulesModifiedFunds,
  BlacklistSignals,
  blacklistDeleteFunds,
  blacklistDeleteManagers,
  RebalancedTrades,
  stageOneOfRebalance,
  getMarkets,
  getProcessTrades,
  updateAccountingAum,
  getSecurities,
  getHistoryReports,
  ReportFile,
  CreateReport,
  UpdateSecurities,
  updatefigi,
} from "./Redux/Actions/todoActions";

import {
  updateLoading,
  getLoading,
  updateLoadingMsg,
  updateIsInvestor,
  updateIsStrategist,
  getIsLoading,
  decrementActiveRequests,
  incrementActiveRequests,
  getActiveRequests,
  getLoadingMsg,
  getShowMarketLoader,
} from "./Redux/Reducers/todoReducers";
import { getUserState } from "./Redux/Reducers/userReducer";
import {
  getUser,
  getUserInfo,
  removeUser,
  getApiInfo,
} from "./Redux/Actions/UserActions";
import { Spinner } from "./component/spinner";
import { ToastContainer, toast } from "react-toastify";
import MainRoutes from "./MainRoutes";
import Sockets from "./utils/sockets";
import { customizedFunds } from "./component/Methods";
import { Reconnect } from "./component/Reconnect";
import { Spin } from "./component/spinner/Spin";
import Drawer from "./component/Drawer";

function ping(client) {
  if (client && client.readyState === WebSocket.OPEN) {
    client.send("ping");
  }
}

function App() {
  const user = useSelector(getUserState);
  const { managers } = useSelector((state) => state.todos);
  const socketapiData = useSelector((state) => state?.users?.apiInfo);
  const socketuser = useSelector((state) => state?.users?.userInfo);

  const token = localStorage.getItem("key");
  const loadingmsg = useSelector(getLoadingMsg);
  const loading = useSelector(getLoading);
  const isLoading = useSelector(getIsLoading);
  const req = useSelector(getActiveRequests);
  const showMarketLoader = useSelector(getShowMarketLoader);
  var intervalId = 0;
  var retryIntervalId = 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const apiDataRef = useRef(socketapiData);
  const userRef = useRef(socketuser);
  const isReconnectingRef = useRef(false);
  const reconnects = useRef(0);

  useEffect(() => {
    apiDataRef.current = socketapiData;
    userRef.current = socketuser;
  }, [socketapiData, socketuser]);

  useEffect(() => {
    let localUser = localStorage.getItem("user");
    if (localUser) {
      let userAuth = JSON.parse(localUser);
      dispatch(getUser(userAuth));
    }
  }, []);

  const parseMsg = (data) => {
    if (data?.message == "Internal server error") {
      toast.error(data?.message);
    }
    if (data?.message != "Endpoint request timed out") {
      dispatch(decrementActiveRequests());
    }

    if (data) {
      if (data.action === "seed") {
        if (data?.data) {
          dispatch(getBrokers(data?.data?.brokers));
          dispatch(getSecurities(data?.data?.securityMaster));
          dispatch(getManagers(data?.data?.managers));
          dispatch(getMarkets(data?.data?.markets));
          dispatch(getRouters(data?.data?.routers));
          let userType = "strategist";
          if (data?.data?.managers?.length > 0) {
            data?.data?.managers?.forEach((manager) => {
              if (manager.permissions.user.hasOwnProperty(user?.username)) {
                const userPermissionValue =
                  manager.permissions.user[user?.username];
                if (userPermissionValue === 4) {
                  userType = "admin";
                } else if (userPermissionValue === 2) {
                  userType = "investor";
                  dispatch(updateIsInvestor(true));
                }
              } else if (manager.owner === "user#" + user?.username) {
                userType = "admin";
              } else {
                userType = "strategist";
                dispatch(updateIsStrategist(true));
              }
            });
          } else {
            userType = "strategist";
            dispatch(updateIsStrategist(true));
          }

          if (userType === "admin" || userType === "investor") {
            const manager = data?.data?.managers;
            const findFund = manager[0]?.funds;
            const fundsArray = Object.values(findFund);
            dispatch(getFunds(fundsArray));
            const findAccounting = manager[0]?.accountingSystems;
            const AccountingArray = Object.values(findAccounting);
            dispatch(getAccountingSystem(AccountingArray));
            dispatch(getStrategies(data?.data?.strategies));
            const reports = Object.values(Object.values(manager[0]?.reports));
            dispatch(getHistoryReports(reports));

            const combinedLots = fundsArray.reduce((acc, fund) => {
              return acc.concat(fund.lots);
            }, []);

            dispatch(
              getCustomFunds(
                customizedFunds(
                  combinedLots,
                  fundsArray,
                  data?.data?.strategies
                )
              )
            );
            dispatch(getLots(combinedLots));
          } else {
            dispatch(getStrategies(data?.data?.strategies));
            const manager = data?.data?.managers;
            const findFund = manager[0]?.funds;
            if (findFund) {
              const fundsArray = Object.values(findFund);

              const combinedLots = fundsArray.reduce((acc, fund) => {
                return acc.concat(Object.values(fund));
              }, []);
              dispatch(getLots(combinedLots));
            }
          }

          dispatch(getHistoricalData(data?.data?.historicaltrades));
          dispatch(getCustodians(data?.data?.custodians));
          dispatch(getTickers(data?.data?.tickers));
          dispatch(getTrades(data?.data?.trades));
          dispatch(getDeletes(data?.data?.trades));
          dispatch(getUserPreference(data?.data?.userPreference));
          dispatch(getFees(data?.data?.fees));
          dispatch(getNotifications(data?.data?.notifications));
        }
        if (location.pathname === "/login" || location.pathname === "/") {
          if (data?.data?.managers && data?.data?.managers.length > 0) {
            navigate("/portfolio");
          } else {
            navigate("/dashboard");
          }
        } else {
          navigate(location.pathname);
        }

        return null;
      }

      if (data.action === "update_accounting_aum") {
        dispatch(updateAccountingAum(data?.data));
      }

      if (data.action === "new_broker") {
        return dispatch(addNewBrokers(data?.data?.broker));
      }
      if (data.action === "modify_broker") {
        dispatch(modifyBrokers(data?.data?.broker));
      }
      if (data.action === "delete_broker") {
        dispatch(deleteBroker(data?.data?.broker));
      }
      if (data.action === "new_custodian") {
        return dispatch(addNewCustodians(data?.data?.custodian));
      }
      if (data.action === "modify_custodian") {
        dispatch(modifyCustodians(data?.data?.custodian));
      }
      if (data.action === "delete_custodian") {
        dispatch(deleteCustodian(data?.data?.custodian));
      }
      if (data.action === "add_fund") {
        return dispatch(addNewFunds(data?.data));
      }
      if (data.action === "modify_fund") {
        dispatch(modifyFunds(data?.data?.fund));
      }
      if (data.action === "market_data") {
        dispatch(saveMarketData(data?.data));
      }

      if (data.action === "delete_fund") {
        dispatch(deleteFund(data?.data?.fund));
      }
      if (data.action === "new_strategy") {
        return dispatch(addNewStrategy(data?.data));
      }
      if (data.action === "modify_strategy") {
        dispatch(modifyStrategy(data?.data?.strategy));
      }
      if (data.action === "modify_security_master_items" && data?.data) {
        dispatch(UpdateSecurities(data?.data));
      }
      if (data.action === "delete_strategy") {
        dispatch(deleteStrategy(data?.data?.strategy));
      }

      if (data.action === "get_accounting_eod") {
        dispatch(TradeCustodianAccountingFile(data?.data));
      }
      if (data.action === "new_trades" && data?.data) {
        toast.success("New trades are added.");
        dispatch(addNewTrades(data?.data));
        dispatch(getLots([]));
      }
      if (data.action === "modify_orders" && data?.data) {
        toast.success("Trade is Updated.");
        dispatch(addNewOrders(data));
        dispatch(getLots([]));
      }
      if (data.action === "modify_trade_fills" && data?.data) {
        dispatch(addNewManual(data?.data));
        dispatch(getLots([]));
      }
      if (data.action === "modify_trade_commissions" && data?.data) {
        dispatch(addNewCommission(data));
      }

      if (data.action === "update_fills" && data?.data?.fill) {
        dispatch(addNewFill(data?.data?.fill));
        dispatch(getLots([]));
      }
      if (data.action === "route_orders") {
        toast.success("Orders routed successfully.");
      }
      if (
        data.action === "get_rebalancer_crossing_orders" &&
        data?.data?.data
      ) {
        dispatch(getProcessTrades(data?.data?.data));
      }
      if (data.action === "generate_file") {
        return dispatch(TradeCustodianFile(data?.data));
      }
      if (data.action === "create_report") {
        return dispatch(CreateReport(data?.data));
      }
      if (data.action === "generate_report") {
        toast.success("Report is Generated");
        return dispatch(ReportFile(data?.data));
      }
      if (data.action === "delete_trades" && data?.data) {
        toast.success("Trade is Deleted.");
        return dispatch(deleteTrades(data));
      }
      if (data.action === "post_ftp") {
        return dispatch(getFtp(data?.data));
      }
      if (data.action === "new_signal" && data) {
        toast.success("Strategy Signal Updated.");
        dispatch(modifySignal(data));
      }
      if (data.action === "blacklisted_tickers" && data?.data) {
        dispatch(BlacklistSignals(data?.data));
        toast.error(
          "Signal list could not be updated because blacklisted tickeres are found."
        );
      }
      if (data.action === "new_notification" && data) {
        dispatch(modifyNotification(data));
      }
      if (data.action === "modify_notifications" && data) {
        dispatch(modifyUnreadNotification(data));
      }
      if (data.action === "compare_signal" && data) {
        dispatch(modifySignalValue(data?.data));
      }

      if (data.action === "rebalancer_check_blacklist" && data) {
        dispatch(stageOneOfRebalance(data?.data));
      }
      if (data.action === "create_rebalancer_items" && data) {
        dispatch(stageOneOfSleeve(data?.data));
      }
      if (data.action === "create_rebalancer_orders" && data?.data) {
        dispatch(RebalancedTrades(data?.data?.orders));
      }
      if (data.action === "create_rebalancer_crossing_orders") {
        dispatch(stageTwoOfRebalance(data?.data?.data));
      }
      if (data.action === "rebalancer_locates") {
        dispatch(finalCrossedTrades(data?.data?.data?.orders));
      }

      if (data.action === "rebalancer_summary") {
        dispatch(RebalanceSummary(data?.data));
      }

      if (data.action === "rebalancer_complete") {
        toast.success("Rebalancer Orders Created");
        navigate("/tradesV2");
      }

      if (data.action === "fund_allocation" && data) {
        dispatch(getAllocationData(data?.data));
      }

      if (data.action === "fetch_figi" && data) {
        dispatch(updatefigi(data?.data));
      }

      if (data.action === "create_fund_blacklist_rule" && data?.data) {
        dispatch(blacklistRulesFunds(data?.data));
        toast.success("Blacklisted Rules Created.");
      }

      if (data.action === "modify_fund_blacklist" && data) {
        dispatch(blacklistRulesModifiedFunds(data?.data));
        toast.success("Blacklisted Rules Updated.");
      }

      if (data.action === "delete_fund_blacklist" && data?.data) {
        dispatch(blacklistDeleteFunds(data?.data));
        toast.success("Blacklist is Deleted.");
      }

      if (data.action === "create_manager_blacklist_rule" && data?.data) {
        dispatch(blacklistRulesManager(data?.data));
        toast.success("Blacklisted Rules Created.");
      }

      if (data.action === "modify_manager_blacklist" && data) {
        dispatch(blacklistRulesModifiedManager(data?.data));
        toast.success("Blacklisted Rules Updated.");
      }

      if (data.action === "delete_manager_blacklist" && data?.data) {
        dispatch(blacklistDeleteManagers(data?.data));
        toast.success("Blacklist is Deleted.");
      }
    }
  };

  // const timeoutDuration = 180000;
  // const timeoutId = useRef(null);

  // useEffect(() => {
  //   if (req > 0) {
  //     timeoutId.current = setTimeout(() => {
  //       if (req > 0) {
  //         dispatch(updateLoading(false));
  //         dispatch(updateLoadingMsg("Request timed out."));
  //         dispatch(decrementActiveRequests());
  //       }
  //     }, timeoutDuration);
  //   } else {
  //     dispatch(updateLoading(false));
  //     dispatch(updateLoadingMsg(""));
  //     if (timeoutId.current) {
  //       clearTimeout(timeoutId.current);
  //       timeoutId.current = null;
  //     }
  //   }

  //   return () => {
  //     if (timeoutId.current) {
  //       clearTimeout(timeoutId.current);
  //       timeoutId.current = null;
  //     }
  //   };
  // }, [req, dispatch]);

  useEffect(() => {
    if (req === 0) {
      dispatch(updateLoading(false));
      dispatch(updateLoadingMsg(""));
    }
  }, [req]);

  useEffect(() => {}, [loading]);
  useEffect(() => {
    if (user && !window?.clientSocks) {
      dispatch(updateLoading(true));
      dispatch(updateLoadingMsg("Authenticating"));

      async function fetchUser(username, password) {
        const response = await LogUser({
          username: username,
          password: password,
        });

        if (response.success)
          return { success: true, token: response.data.token };
        else return { success: false, token: "", msg: response.data };
      }

      async function initiateWebSocketConnection(user) {
        const token = await fetchUser(user.username, user.password);
        if (token.token) {
          clearInterval(retryIntervalId);
          const url = `${process.env.REACT_APP_URL}?token=${token.token}`;
          const client = new WebSocket(url);
          window.clientSocks = client;

          if (window?.clientSocks) {
            window.clientSocks.onerror = function (error) {
              dispatch(updateLoading(false));
              dispatch(updateLoadingMsg(""));
              toast.error("Connection Lost. Please Reconnect.");
            };
            window.clientSocks.onopen = function (e) {
              intervalId = setInterval(() => {
                ping(window.clientSocks);
              }, 540000);

              // Reset globalCredentials when connection is re-established
              const { resetCredentials } = Sockets(
                apiDataRef.current,
                userRef.current
              );
              resetCredentials();
            };

            window.clientSocks.onmessage = function (event) {
              var data = JSON.parse(event.data);
              if (data.action === "login") {
                dispatch(updateLoading(true));
                dispatch(updateLoadingMsg("Fetching Seed Data"));
                dispatch(getUserInfo(data?.data?.user));
                dispatch(getApiInfo(data?.data));
                const key = data.data.user.refreshToken;
                localStorage.setItem("key", key);
                dispatch(incrementActiveRequests());
                const req = '{"action": "seed"}';
                window?.clientSocks.send(req);
              } else if (data.action == "download") {
                const handleDownload = async (file) => {
                  try {
                    const { readData } = Sockets(
                      apiDataRef.current,
                      userRef.current
                    );
                    dispatch(updateLoadingMsg("Downloading Data"));
                    const result = await readData(file);
                    const JSONmsg = JSON.parse(result);
                    console.log("s3 Data", JSONmsg);
                    parseMsg(JSONmsg);
                  } catch (error) {
                    console.error("Error during file download:", error);
                    toast.error("Failed to download data from server.");
                  }
                };
                if (isReconnectingRef.current) {
                  isReconnectingRef.current = false;
                  dispatch(updateLoading(false));
                  dispatch(updateLoadingMsg(""));
                } else {
                  handleDownload(data.file);
                }
              } else {
                parseMsg(data);
              }
            };

            window.clientSocks.onclose = function (event) {
              window.clientSocks = null;
              clearInterval(intervalId);
              if (user) {
                isReconnectingRef.current = true;

                // Retry connection if user exists in redux
                retryIntervalId = setInterval(() => {
                  if (reconnects.current < 3) {
                    reconnects.current += 1;
                    initiateWebSocketConnection(user);
                  } else {
                    clearInterval(retryIntervalId);
                  }
                }, 5000);
              } else {
                isReconnectingRef.current = false;
                dispatch(removeUser());
                navigate("/login");
              }
            };
          }
        } else {
          localStorage.removeItem("key");
          localStorage.removeItem("user");
          toast.error(token.msg);
          dispatch(updateLoading(false));
          dispatch(updateLoadingMsg(""));
        }
      }

      initiateWebSocketConnection(user);
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      {loading && <Spin message={loadingmsg} />}
      {showMarketLoader && <Spin message={loadingmsg} />}

      {user && <Drawer />}
      <ToastContainer />
      {managers ? <MainRoutes token={token} managers={managers} /> : null}
      {isReconnectingRef.current && <Reconnect />}
    </ThemeProvider>
  );
}

export default App;
