import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../../utils/bloombergMarketData";
import { fetchTodaysSymbols } from "../../component/Methods";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
  getLivePrices,
} from "../Reducers/todoReducers";
import { useSelector } from "react-redux";

//ALL GET ACTIONS
export const getFunds = createAsyncThunk("getFunds", async (payload) => {
  const result = payload;
  return result;
});

export const getCustomFunds = createAsyncThunk(
  "getCustomFunds",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getStrategies = createAsyncThunk(
  "getStrategies",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getHistoricalData = createAsyncThunk(
  "getHistoricalData ",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getLots = createAsyncThunk("getLots", async (payload) => {
  const result = payload;
  return result;
});
export const getTrades = createAsyncThunk("getTrades", async (payload) => {
  const result = payload;
  return result;
});
export const getDeletes = createAsyncThunk("getDeletes", async (payload) => {
  const result = payload;
  return result;
});
export const getCustodians = createAsyncThunk(
  "getCustodians",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getTickers = createAsyncThunk("getTickers", async (payload) => {
  const result = payload;
  return result;
});
export const getFtp = createAsyncThunk("getFtp", async (payload) => {
  const result = payload;
  return result;
});
export const getRouters = createAsyncThunk("getRouters", async (payload) => {
  const result = payload;
  return result;
});
export const getBrokers = createAsyncThunk("getBrokers", async (payload) => {
  const result = payload;
  return result;
});
export const getManagers = createAsyncThunk("getManagers", async (payload) => {
  const result = payload;
  return result;
});
export const getMarkets = createAsyncThunk("getMarkets", async (payload) => {
  const result = payload;
  return result;
});
export const getSecurities = createAsyncThunk(
  "getSecurities",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getAccountingSystem = createAsyncThunk(
  "getAccountingSystem",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getUserPreference = createAsyncThunk(
  "getUserPreference",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getFees = createAsyncThunk("getFees", async (payload) => {
  const result = payload;
  return result;
});
export const getNotifications = createAsyncThunk(
  "getNotifications",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getAllocationData = createAsyncThunk(
  "getAllocationData",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const updatefigi = createAsyncThunk("updatefigi", async (payload) => {
  const result = payload;
  return result;
});

export const getProcessTrades = createAsyncThunk(
  "getProcessTrades",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const getHistoryReports = createAsyncThunk(
  "getHistoryReports",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const TradeCustodianFile = createAsyncThunk(
  "TradeCustodianFile",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const CreateReport = createAsyncThunk(
  "CreateReport",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const ReportFile = createAsyncThunk("ReportFile", async (payload) => {
  const result = payload;
  return result;
});
export const UpdateSecurities = createAsyncThunk(
  "UpdateSecurities",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const TradeCustodianAccountingFile = createAsyncThunk(
  "TradeCustodianAccountingFile",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const setDefaultFund = createAsyncThunk(
  "setDefaultFund",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const requestMarketData = createAsyncThunk(
  "requestMarketData",
  async (payload, { dispatch, getState }) => {
    const getUniqueTickers = (array) => {
      const uniqueSymbols = new Set();
      return array
        .filter((item) => {
          const symbol = item.ticker.symbol.split(" ")[0];
          if (!uniqueSymbols.has(symbol)) {
            uniqueSymbols.add(symbol);
            return true;
          }
          return false;
        })
        .map((item) => item.ticker);
    };

    const state = getState();
    const livePrices = getLivePrices(state);

    const getPrices = async (symbols, livePrices) => {
      const data = [];
      const chunkSize = payload?.noRequest ? 4000 : 4000;
      const sendReq = payload?.noRequest ? false : true;

      for (let i = 0; i < symbols.length; i += chunkSize) {
        const symbolsChunk = symbols.slice(i, i + chunkSize);
        const response = getData(
          symbolsChunk,
          payload.threshold,
          sendReq,
          true,
          false,
          payload.market ? payload.market : "bloomberg",
          livePrices
        );
        data.push(...response[0]);
      }
      return data;
    };

    const uniqueTickers = getUniqueTickers(payload.lots);

    let uniqueArray = uniqueTickers;

    if (payload.trades) {
      const todaysSymbols = fetchTodaysSymbols(payload.trades);
      const mergedArray = [...uniqueTickers, ...todaysSymbols];

      uniqueArray = mergedArray.filter(
        (item, index, self) =>
          self.findIndex((t) => t.symbol === item.symbol) === index
      );
    }

    return await getPrices(
      uniqueArray.length > 0 ? uniqueArray : uniqueTickers,
      livePrices
    );
  }
);

export const setGlobalContract = createAsyncThunk(
  "setGlobalContract",
  async (payload) => {
    const result = payload;
    return result;
  }
);
//ALL POST (ADD-EDIT) ACTIONS
export const addNewBrokers = createAsyncThunk(
  "addNewBrokers",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const updateAccountingAum = createAsyncThunk(
  "updateAccountingAum",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const modifyBrokers = createAsyncThunk(
  "modifyBrokers ",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const addNewCustodians = createAsyncThunk(
  "addNewCustodians",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const modifyCustodians = createAsyncThunk(
  "modifyCustodians",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const addNewFunds = createAsyncThunk("addNewFunds", async (payload) => {
  const result = payload;
  return result;
});
export const modifyFunds = createAsyncThunk("modifyFunds", async (payload) => {
  const result = payload;
  return result;
});
export const addNewStrategy = createAsyncThunk(
  "addNewStrategy",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const modifyStrategy = createAsyncThunk(
  "modifyStrategy",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const addNewTrades = createAsyncThunk(
  "addNewTrades",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const addNewOrders = createAsyncThunk(
  "addNewOrders",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const addNewManual = createAsyncThunk(
  "addNewManual",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const addNewFill = createAsyncThunk("addNewFill", async (payload) => {
  const result = payload;
  return result;
});
export const addNewCommission = createAsyncThunk(
  "addNewCommission",
  async (payload) => {
    const result = payload;
    return result;
  }
);

//ALL DELETE ACTIONS
export const deleteTrades = createAsyncThunk(
  "deleteTrades",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const deleteBroker = createAsyncThunk(
  "deleteBroker",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const deleteCustodian = createAsyncThunk(
  "deleteCustodian",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const updateCalculatedMonitorScreen = createAsyncThunk(
  "updateCalculatedMonitorScreen",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const deleteFund = createAsyncThunk("deleteFund", async (payload) => {
  const result = payload;
  return result;
});
export const deleteStrategy = createAsyncThunk(
  "deleteStrategy",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const ButtonAction = createAsyncThunk("ButtonAction", async (flag) => {
  const result = flag;
  return result;
});

export const saveMarketData = createAsyncThunk(
  "saveMarketData",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const saveThreshold = createAsyncThunk(
  "saveThreshold",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const BlacklistSignals = createAsyncThunk(
  "BlacklistSignals",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const modifySignal = createAsyncThunk(
  "modifySignal",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const modifySignalValue = createAsyncThunk(
  "modifySignalValue",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const stageOneOfRebalance = createAsyncThunk(
  "stageOneOfRebalance",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const stageOneOfSleeve = createAsyncThunk(
  "stageOneOfSleeve",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const stageTwoOfRebalance = createAsyncThunk(
  "stageTwoOfRebalance",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const RebalanceSummary = createAsyncThunk(
  "RebalanceSummary",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const finalCrossedTrades = createAsyncThunk(
  "finalCrossedTrades",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const stageThreeOfRebalance = createAsyncThunk(
  "stageThreeOfRebalance",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const modifyNotification = createAsyncThunk(
  "modifyNotification",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const modifyUnreadNotification = createAsyncThunk(
  "modifyUnreadNotification",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const blacklistRulesFunds = createAsyncThunk(
  "blacklistRulesFunds",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const blacklistDeleteFunds = createAsyncThunk(
  "blacklistDeleteFunds",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const blacklistRulesModifiedFunds = createAsyncThunk(
  "blacklistRulesModifiedFunds",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const blacklistRulesManager = createAsyncThunk(
  "blacklistRulesManager",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const blacklistRulesModifiedManager = createAsyncThunk(
  "blacklistRulesModifiedManager",
  async (payload) => {
    const result = payload;
    return result;
  }
);
export const blacklistDeleteManagers = createAsyncThunk(
  "blacklistDeleteManagers",
  async (payload) => {
    const result = payload;
    return result;
  }
);

//trades
export const RebalancedTrades = createAsyncThunk(
  "RebalancedTrades",
  async (payload) => {
    const result = payload;
    return result;
  }
);

export const clearRebalancer = createAsyncThunk(
  "clearRebalancer",
  async (payload) => {
    const result = payload;
    return result;
  }
);
