import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Typography,
  FormControl,
  Box,
  useTheme,
} from "@mui/material";
// import { processData } from "../../Methods";
import CurrentSignal from "./CurrentSignal";
import ModifiedSignal from "./ModifiedSignal";
import SignalTickerData from "./SignalTickerData";
import CurrentList from "./CurrentList";
import { useSelector } from "react-redux";
import { extractTickerAndWeight } from "../../Methods";
import PerformanceList from "./PerformanceList";
import PerformanceSignal from "./PerformanceSignal";
import CachedIcon from "@mui/icons-material/Cached";
import BlacklistSignal from "../../Popup/BlacklistSignal";

const SignalsInputAllocation = () => {
  const theme = useTheme();
  const [pasted, setpasted] = useState([]);
  const [strategyVal, setstrategyVal] = useState("");
  const [strategyValObj, setstrategyValObj] = useState();
  const { strategies, isStrategist, managers, blacklistSignal } = useSelector(
    (state) => state?.todos
  );
  const [verifyEnabled, setVerifyEnabled] = useState(false);
  const [copiedData, setCopiedData] = useState([]);
  const [verifyStrategy, setVerifyStrategy] = useState(false);
  const [handleVerify, setHandleVerify] = useState(() => () => {});
  const [setSaveChange, setSaveChangeHandler] = useState(() => () => {});
  const [openBlacklistPopup, setBlacklistOpenPopup] = useState(false);
  const [numberOfBlacklistedObjects, setNumberOfBlacklistedObjects] =
    useState();
  const [numberOfInvalidObjects, setNumberOfInvalidObjects] = useState();
  const [numberOfNotVerifiedObjects, setNumberOfNotVerifiedObjects] =
    useState();
  const [empty, setEmpty] = useState(false);
  const [pasteButton, setPasteButton] = useState(false);
  const [error, setError] = useState("");


  function strategySelected(id) {
    // processData(strategies, id);
    setstrategyValObj(id);
  }

  const strategyList = strategies
    ?.map((i) => i?.name)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

  const pasteFromClipboard = async () => {
    try {
      const entries = await navigator.clipboard.readText();
      let result = [];

      try {
        result = JSON.parse(entries);
        if (
          !Array.isArray(result) ||
          !result.every((obj) => typeof obj === "object")
        ) {
          throw new Error("Invalid format after parsing as JSON");
        }
      } catch (jsonParseError) {
        entries.split("\n").forEach((entry) => {
          if (entry.trim() !== "") {
            const extracted = extractTickerAndWeight(entry);

            if (extracted) {
              result.push(extracted);
            } else {
              console.error(`Invalid entry format: ${entry}`);
            }
          }
        });
      }

      setpasted(result);
      reset();

      if (result?.length === 0) {
        alert("Error: The copied text does not match the expected format.");
      }
    } catch (err) {
      setpasted([]);
      alert("Error: Cannot paste from clipboard");
    }
  };
  const handleDataChange = () => {
    if (copiedData && copiedData.length > 0 && verifyEnabled) {
      setpasted(copiedData);
      reset();
    } else {
      setpasted([]);
      reset();
    }
  };
  const reset = () => {
    setVerifyEnabled(false);
    setEmpty(false);
  };
  const handleStrategyChange = (newValue) => {
    setstrategyVal(newValue);
    if (newValue === null) {
      setstrategyValObj();
      setVerifyStrategy(false);
      setpasted([]);
      reset();
      return;
    }

    const strObj = strategies?.find((i) => {
      return i?.name == newValue;
    });
    if (strObj && strObj?.strategyId) {
      strategySelected(strObj?.strategyId);
      setVerifyStrategy(true);
    } else {
      setstrategyValObj();
      setVerifyStrategy(false);
    }
    setpasted([]);
    setPasteButton(false);
    reset();
  };
  console.log("number--", numberOfNotVerifiedObjects, copiedData);
  return (
    <>
      <Container maxWidth="100%" sx={{ marginTop: 5, marginBottom: 3 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "48%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 900,
                    lineHeight: "32.89px",
                  }}
                >
                  View and enter new signals for selected strategy
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <FormControl sx={{ width: "200px" }}>
                <Autocomplete
                  options={strategyList}
                  value={strategyVal}
                  autoSelect
                  onChange={(e, newValue) => handleStrategyChange(newValue)}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Strategy to Manage"
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
              {strategyVal ? (
                <Button
                  variant="outlined"
                  // disabled={!pasteButton}
                  style={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    letterSpacing: "0.49px",
                  }}
                  onClick={pasteFromClipboard}
                >
                  PASTE FROM CLIPBOARD
                </Button>
              ) : (
                <></>
              )}
            </div>

            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flex: "0 0 auto",
                  minWidth: "300px",
                  zIndex: 1000,
                }}
              >
                {verifyEnabled && !empty && (
                  <Button
                    variant="contained"
                    sx={{ mr: 3 }}
                    startIcon={<CachedIcon />}
                    disabled={!verifyStrategy}
                    onClick={handleDataChange}
                  >
                    Done Editing
                  </Button>
                )}
                {!verifyEnabled &&
                  !empty &&
                  copiedData &&
                  copiedData?.length > 0 && (
                    <>
                      <Button
                        variant="standard"
                        startIcon={<CachedIcon />}
                        disabled={!verifyStrategy}
                        onClick={() => {
                          handleVerify();
                          setBlacklistOpenPopup(true);
                        }}
                        sx={{ mr: 2 }}
                      >
                        Verify Signals
                      </Button>

                      <Button
                        variant="contained"
                        startIcon={<CachedIcon />}
                        disabled={!verifyStrategy}
                        onClick={() => setSaveChange(true)}
                      >
                        SAVE CHANGES
                      </Button>
                    </>
                  )}
              </Box>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <CurrentSignal
                  strategies={strategies}
                  strategyValObj={strategyVal}
                  strategyVal={strategyValObj}
                />
              </div>
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <ModifiedSignal pastedArray={pasted} />
              </div>
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <PerformanceSignal
                  strategies={strategies}
                  strategyValObj={strategyValObj}
                  strategyVal={strategyVal}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <Box
              sx={{
                color: "red",
                fontSize: "small",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: 42,
                width: "70%",
                height: "50px",
                mb: -10,
              }}
            >
              {numberOfInvalidObjects ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "small",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: theme.palette.red.shortPositions,
                      marginRight: "5px",
                      border: "1px solid black",
                    }}
                  />{" "}
                  Number of wrong ticker inputs: {numberOfInvalidObjects}
                </div>
              ) : null}

              {numberOfNotVerifiedObjects &&
              copiedData &&
              copiedData?.length > 0 ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "small",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "yellow",
                      marginRight: "5px",
                      border: "1px solid black",
                    }}
                  />
                  Number of tickers for which market data not fetched:{" "}
                  {numberOfNotVerifiedObjects}
                </div>
              ) : null}

              {numberOfBlacklistedObjects ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "small",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: theme.palette.grey[90],
                      marginRight: "5px",
                      border: "1px solid black",
                    }}
                  />
                  Number of tckers that are blacklisted:{" "}
                  {numberOfBlacklistedObjects}
                </div>
              ) : null}
              {error ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "small",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  * {error}
                </div>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                // overflowX: "auto",
                // whiteSpace: "nowrap",
              }}
            >
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <CurrentList
                  strategies={strategies}
                  strategyValObj={strategyValObj}
                  strategyVal={strategyVal}
                  setPasteButton={setPasteButton}
                />
              </div>

              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <SignalTickerData
                  pastedArray={pasted}
                  setpasted={setpasted}
                  strategyValObj={strategyValObj}
                  setVerifyEnabled={setVerifyEnabled}
                  setCopiedData={setCopiedData}
                  setHandleVerify={setHandleVerify}
                  setSaveChangeHandler={setSaveChangeHandler}
                  setNumberOfBlacklistedObjects={setNumberOfBlacklistedObjects}
                  setNumberOfInvalidObjects={setNumberOfInvalidObjects}
                  setNumberOfNotVerifiedObjects={setNumberOfNotVerifiedObjects}
                  setEmpty={setEmpty}
                  setError={setError}
                  error={error}
                  empty={empty}
                  numberOfNotVerifiedObjects={numberOfNotVerifiedObjects}
                  blacklist={blacklistSignal}
                  isStrategist={isStrategist}
                />
              </div>
              <div
                style={{ flex: "0 0 auto", minWidth: "300px", margin: "10px" }}
              >
                <PerformanceList
                  strategies={strategies}
                  strategyValObj={strategyValObj}
                  strategyVal={strategyVal}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {!isStrategist ? (
          <BlacklistSignal
            pastedArray={pasted}
            setpasted={setpasted}
            openBlacklistPopup={openBlacklistPopup}
            setBlacklistOpenPopup={setBlacklistOpenPopup}
            title="Blacklist Signals"
            managers={managers}
            blacklistSignal={blacklistSignal}
          />
        ) : null}
      </Container>
    </>
  );
};

export default SignalsInputAllocation;
