import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  Typography,
} from "@mui/material";
import { head } from "lodash";

const VantageListHead = ({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  hideColumn,
  noStatus,
  title,
  customizeFont,
}) => {
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const adjustedHeadLabel = headLabel;

  return (
    <TableHead>
      <TableRow sx={{ background: theme.palette.grey[700] }}>
        {!noStatus ? (
          <TableCell
            padding="checkbox"
            align="center"
            sx={{
              color: theme.palette.grey[100],
              background: "inherit",
              fontSize: "12px",
            }}
          >
            #
          </TableCell>
        ) : null}

        {adjustedHeadLabel?.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              title={title ? headCell.title : ""}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                color: theme.palette.lightGrey.textColor,
                background:
                  headCell.id === "sharecount"
                    ? "#aeb5f3"
                    : theme.palette.lightGrey.backgroundBody,
                fontSize: "11px",
                width:
                  headCell.id === "idealweights" ||
                  headCell.id === "currentweight"
                    ? "220px"
                    : headCell.id === "actions"
                    ? "80px"
                    : headCell.id === "long_size"
                    ? "50px"
                    : headCell.id === "long_deltaadjustedsharecount"
                    ? "50px"
                    : headCell.id === "long_tickerSymbol"
                    ? "60px"
                    : headCell.id === "long_last"
                    ? "60px"
                    : headCell.id === "long_current"
                    ? "60px"
                    : headCell.id === "long_ch"
                    ? "50px"
                    : headCell.id === "long_pL"
                    ? "60px"
                    : headCell.id === "long_chpercentage"
                    ? "55px"
                    : headCell.id === "long_pl_avg_cost"
                    ? "80px"
                    : headCell.id === "long_percentage_avg_cost"
                    ? "90px"
                    : headCell.id === "long_cost"
                    ? "70px"
                    : headCell.id === "long_tgt"
                    ? "70px"
                    : headCell.id === "short_size"
                    ? "50px"
                    : headCell.id === "short_deltaadjustedsharecount"
                    ? "50px"
                    : headCell.id === "short_tickerSymbol"
                    ? "60px"
                    : headCell.id === "short_last"
                    ? "60px"
                    : headCell.id === "short_current"
                    ? "60px"
                    : headCell.id === "short_ch"
                    ? "50px"
                    : headCell.id === "short_pL"
                    ? "60px"
                    : headCell.id === "short_chpercentage"
                    ? "55px"
                    : headCell.id === "short_pl_avg_cost"
                    ? "80px"
                    : headCell.id === "short_percentage_avg_cost"
                    ? "90px"
                    : headCell.id === "short_cost"
                    ? "70px"
                    : headCell.id === "short_tgt"
                    ? "70px"
                    : headCell.id === "pL"
                    ? "70px"
                    : headCell.id === "plShort"
                    ? "70px"
                    : headCell.id === "plLong"
                    ? "70px"
                    : headCell.id === "sizeLong"
                    ? "70px"
                    : headCell.id === "sizeShort"
                    ? "70px"
                    : headCell.id === "longCapital"
                    ? "85px"
                    : headCell.id === "shortCapital"
                    ? "85px"
                    : headCell.id === "longPerformance"
                    ? "65px"
                    : headCell.id === "shortPerformance"
                    ? "65px"
                    : headCell.id === "plPer"
                    ? "65px"
                    : headCell.id === "strategyName"
                    ? "150px"
                    : headCell.id === "fundName"
                    ? "150px"
                    : headCell.id === "aum"
                    ? "90px"
                    : headCell.id === "pLWeek"
                    ? "70px"
                    : headCell.id === "pLMonth"
                    ? "70px"
                    : headCell.id === "pLQuarter"
                    ? "70px"
                    : headCell.id === "pLYear"
                    ? "70px"
                    : "",
                padding:
                  headCell.id === "idealweights" ||
                  headCell.id === "currentweight"
                    ? "1px"
                    : "2px",

                borderLeft: "0.1px solid #dadada",
                borderRight: "0.1px solid #dadada",
                alignItems: "center",
                "&:hover": {
                  background: theme.palette.lightGrey.tableHeader,
                  cursor: "pointer",
                },
              }}
            >
              <Box
                // active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color:
                      headCell.id === "sharecount"
                        ? "#292929"
                        : theme.palette.lightGrey.textColor,
                    fontSize: customizeFont
                      ? theme.typography.bodyText16.fontSize
                      : theme.typography.bodyText12.fontSize,
                    fontWeight: theme.typography.bodyText12.fontWeight,
                    textAlign: "center",
                  }}
                >
                  {headCell.label}
                </Typography>
              </Box>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default VantageListHead;
