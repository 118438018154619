import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Typography, Select, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { findFundAbbById, findFundById, TABLE_CROSS_TRADE as tableHeader } from "../../../../../Methods";
import { useSelector } from "react-redux";
import CrossingTradesTable from "./components/CrossingTradesTable";
import Loader from "../../../../../Loader";

const FundRebalanceStep6 = ({ setCurrentStep, onPrevious }) => {
  const { crossingLoader, AllTrades, funds } = useSelector((state) => state.todos);
  const [combinedOrders, setCombinedOrders] = useState([]);
  const [selectedFund, setSelectedFund] = useState("All");

  useEffect(() => {
    if (
      AllTrades &&
      Array.isArray(AllTrades?.realOrders) &&
      Array.isArray(AllTrades?.crossingOrders)
    ) {
      const combined = [...AllTrades.realOrders, ...AllTrades.crossingOrders];
      setCombinedOrders(combined);
    } else {
      setCombinedOrders([]);
    }
  }, [AllTrades]);

  // Extract unique fund options with id and abbreviation
  const fundOptions = [
    { id: "All", abbreviation: "All" },
    ...combinedOrders.map(order => {
      const fundAbbreviation = findFundAbbById(order?.fund, funds);
      return { id: order?.fund, abbreviation: fundAbbreviation };
    }).filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.id === value.id && t.abbreviation === value.abbreviation
      ))
    )
  ];

  const handleFundChange = (event) => {
    setSelectedFund(event.target.value);
  };


  console.log(combinedOrders)
  // Filter orders based on selected fund
  const filteredOrders = selectedFund === "All" 
    ? combinedOrders 
    : combinedOrders.filter(order => order.fund == selectedFund);

  const onNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      {crossingLoader ? (
        <Loader mTrue={false} />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "44%",
                }}
              >
                <Box
                  sx={{
                    marginBottom: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold !important", fontSize: "20px !important" }}>
                    Crossing Trades
                  </Typography>
                  <Select
                    value={selectedFund}
                    onChange={handleFundChange}
                    sx={{ marginLeft: 2 }}
                    size="small"
                  >
                    {fundOptions.map((fund) => (
                      <MenuItem key={fund.id} value={fund.id}>
                        {fund.abbreviation}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </div>
              {filteredOrders && filteredOrders.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={onNext}
                    sx={{ width: "150px", height: "40px" }}
                  >
                    Next
                  </Button>
                </Box>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "45%",
                justifyContent: "space-between",
              }}
            >
              {filteredOrders ? (
                <CrossingTradesTable
                  crossingTrades={filteredOrders}
                  tableHeader={tableHeader}
                />
              ) : (
                <p>No data available</p>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default FundRebalanceStep6;
