import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Tablecell } from "../Methods";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import BulkUpdatePopup from "../Popup/PopUp/FundAllocator";
import BuildIcon from "@mui/icons-material/Build";

const AllocatorList = ({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  fundId,
  matchedAllocations,
  editableValue,
  editableData,
  setEditableData,
  custodians,
  sortedData,
  StrategiesLowAum,
  setStrategyCombined,
  setStrategyLowAum,
  setHasChanges,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleBulk = (event) => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddStrategy = (item) => {
    handleClose();
    setHasChanges(true);
    setStrategyCombined((prev) => [...prev, item]);
    setStrategyLowAum((prev) => prev.filter((i) => i !== item));
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isAddStrategyDisabled =
    !StrategiesLowAum || StrategiesLowAum.length === 0;
  const isBulkUpdate =
    fundId != "" &&
    fundId !== undefined &&
    fundId !== null &&
    matchedAllocations &&
    matchedAllocations.length;

  return (
    <>
      <TableHead>
        <TableRow
          sx={{
            "&:hover": {
              background: theme.palette.grey[200],
              cursor: "pointer",
            },
          }}
        >
          <TableCell
            align="left"
            colSpan={1}
            sx={{
              fontSize: theme.typography.bodyText12.fontSize,
              fontWeight: 900,
              background: "white",
              padding: 0,
              paddingLeft: "20px",
              textAlign: "left",
              cursor: isAddStrategyDisabled ? "not-allowed" : "pointer",
              "&:hover": {
                backgroundColor: isAddStrategyDisabled ? "white" : "lightgrey",
              },
            }}
            onClick={isAddStrategyDisabled ? null : handleClick}
          >
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: 900,
                  marginRight: "8px",
                }}
              >
                Add Strategy
              </Typography>
              <IconButton
                aria-label="add"
                sx={{
                  height: "16px",
                  width: "16px",
                  padding: 0,
                }}
                disabled={isAddStrategyDisabled}
              >
                <AddIcon
                  sx={{
                    height: "20px",
                    width: "20px",
                    color: "black",
                  }}
                />
              </IconButton>
            </Box>
          </TableCell>

          <TableCell
            align="center"
            colSpan={4}
            sx={{
              fontSize: theme.typography.bodyText12.fontSize,
              fontWeight: 900,
              backgroundColor: "#B5D0D9",
              padding: 0,
              paddingLeft: "20px",
              textAlign: "center",
            }}
          >
            Existing
          </TableCell>
          <Tablecell
            align="center"
            colSpan={5}
            sx={{
              fontSize: theme.typography.bodyText12.fontSize,
              fontWeight: 900,
              backgroundColor: "#E4C0C6",
              padding: 0,
              paddingLeft: "20px",
              textAlign: "center",
            }}
          >
            Target
          </Tablecell>
          <TableCell
            align="center"
            colSpan={2}
            sx={{
              fontSize: theme.typography.bodyText12.fontSize,
              fontWeight: 900,
              backgroundColor: "#bfffbf",
              padding: 0,
              paddingLeft: "20px",
              textAlign: "center",
              "&:hover": {
                backgroundColor: "#bfffbf",
              },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: '900 !important',
                  marginRight: "8px",
                  fontFamily: 'clashGrotesk !important'
                }}
              >
                Preferences{" "}
              </Typography>

              {isBulkUpdate ? (
                <IconButton
                  aria-label="add"
                  sx={{
                    height: "16px",
                    width: "16px",
                    padding: 0,
                  }}
                  onClick={handleBulk}
                >
                  <BuildIcon
                    sx={{
                      height: "20px",
                      width: "20px",
                      color: "black",
                    }}
                  />
                </IconButton>
              ) : null}
            </Box>
          </TableCell>
        </TableRow>

        <TableRow sx={{ background: theme.palette.grey[700] }}>
          {headLabel?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                color: theme.palette.lightGrey.textColor,
                background: "#d9dcdf",
                fontSize: "11px",
                width: headCell.id === "ne" ? "10px" : "",
                padding: "2px",
                borderLeft: "0.1px solid #ccc3c3",
                borderRight: "0.1px solid #ccc3c3",
                alignItems: "center",
                "&:hover": {
                  background: theme.palette.lightGrey.tableHeader,
                  cursor: "pointer",
                },
              }}
            >
              <TableSortLabel
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black !important",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {headCell.label}
                  </Typography>
                </Box>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {StrategiesLowAum?.map((strategy) => (
            <MenuItem
              key={strategy?.strategyId}
              onClick={() => handleAddStrategy(strategy)}
            >
              {strategy?.name}
            </MenuItem>
          ))}
        </Menu>
      </TableHead>
      <BulkUpdatePopup
        sortedData={sortedData}
        custodians={custodians}
        matchedAllocations={matchedAllocations}
        setEditableData={setEditableData}
        editableData={editableData}
        fundId={fundId}
        editableValue={editableValue}
        setHasChanges={setHasChanges}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Bulk Update"
      />
    </>
  );
};

export default AllocatorList;
