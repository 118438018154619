import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  TextField,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Card,
  TableContainer,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  formatValue,
  getComparator,
} from "../../../../../../Methods";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Locates = ({ tickerTrades, setLocatesData, tableHeader, custodian }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(tickerTrades);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  useEffect(() => {
    const filteredData = applySortFilter(
      tickerTrades,
      getComparator(order, orderBy)
    );
    setData(filteredData);
  }, [order, orderBy, tickerTrades]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAvailableChange = (e, index, key, id) => {
    let value = parseInt(e.target.value, 10);

    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }

    const updatedTickerTrades = tickerTrades.map((trade) =>
      trade.key === key && trade.id === id
        ? { ...trade, available: value }
        : trade
    );

    setLocatesData(updatedTickerTrades, custodian);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    if (action === "Copy") {
      const formattedData = ["ticker\tlocated required"] // Add column headers
        .concat(data.map((trade) => `${trade.ticker}\t${trade.shareCount}`)) // Map data with tab separation
        .join("\n"); // Join with newlines

      navigator.clipboard
        .writeText(formattedData)
        .then(() => {
          alert("Data copied to clipboard!");
        })
        .catch((error) => {
          alert("Failed to copy data: " + error);
        });
    } else if (action === "Paste") {
      navigator.clipboard
        .readText()
        .then((clipboardData) => {
          try {
            const pastedData = clipboardData.split("\n").map((line) => {
              const [ticker, available] = line?.split(" ");
              return { ticker, available: parseInt(available, 10) };
            });

            const updatedTickerTrades = tickerTrades
              ?.filter((trade) =>
                pastedData.some((item) => item?.ticker === trade?.ticker)
              )
              .map((trade) => {
                const matchingData = pastedData?.find(
                  (item) => item?.ticker === trade?.ticker
                );
                if (matchingData) {
                  return { ...trade, available: matchingData.available };
                }
                return trade;
              });

            setLocatesData(updatedTickerTrades, custodian);
          } catch (error) {
            alert("Failed to parse pasted data: " + error);
          }
        })
        .catch((error) => {
          alert("Failed to read clipboard data: " + error);
        });
    }
    handleMenuClose();
  };

  return (
    <>
      <Box
        sx={{
          border: `1px solid ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "10px",
            width: "100%", // Ensure full width of the Box container
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold !important",
              fontSize: "20px !important",
            }}
          >
            Account: {custodian}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                marginRight: "10px",
                cursor: "pointer",
                color: theme.palette.primary.main,
              }}
              onClick={() => handleMenuItemClick("Copy")}
            >
              Copy
            </Typography>
            <Typography
              sx={{
                marginRight: "10px",
                cursor: "pointer",
                color: theme.palette.primary.main,
              }}
              onClick={() => handleMenuItemClick("Paste")}
            >
              Paste
            </Typography>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          {/* Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleMenuItemClick("Text")} disabled>
              Fetch via API
            </MenuItem>
          </Menu>
        </Box>

        <Card sx={{ padding: 0 }}>
          <TableContainer style={{ height: "350px" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <VantageListHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHeader}
                noStatus={true}
                customizeFont={12}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      overflow: "auto",
                      "&:hover": {
                        background: theme.palette.grey[200],
                        cursor: "pointer",
                      },
                      "& > .MuiTableCell-root": {
                        paddingTop: "0px",
                        paddingBottom: "0px !important",
                        lineHeight: "1.2",
                        "& .MuiInputBase-input": {
                          padding: "0px 0px !important",
                        },
                        "& .MuiIconButton-root": {
                          padding: "0px",
                        },
                      },
                    }}
                  >
                    <Tablecell align="left">{row?.ticker}</Tablecell>
                    <Tablecell align="right">
                      {formatValue(row?.shareCount, true)}
                    </Tablecell>
                    <TableCell
                      align="right"
                      sx={{ borderLeft: "none", padding: "5px" }}
                    >
                      <TextField
                        value={row.available}
                        onChange={(e) =>
                          handleAvailableChange(e, index, row?.key, row?.id)
                        }
                        size="small"
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: "12px",
                            fontWeight: "500",
                            width: "10ch",
                            marginRight: "0.5ch",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          },
                        }}
                        inputProps={{
                          type: "number",
                          style: {
                            textAlign: "right",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            MozAppearance: "textfield", // For Firefox
                            // For hiding the spin buttons specifically in WebKit browsers
                            "&::-webkit-outer-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                            "&::-webkit-inner-spin-button": {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          },
                        }}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          if (["e", "+", "-", "."].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={(e) => {
                          let value = parseInt(e.target.value, 10);
                          if (isNaN(value) || value < 0) {
                            e.target.value = 0;
                            handleAvailableChange(
                              { target: { value: 0 } },
                              index,
                              row?.key,
                              row?.id
                            );
                          } else {
                            handleAvailableChange(e, index, row?.key, row?.id);
                          }
                        }}
                      />
                    </TableCell>
                    <Tablecell align="left">{row?.fund}</Tablecell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
};

export default Locates;
