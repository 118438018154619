import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  IconButton,
  NativeSelect,
  TableBody,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Tablecell } from "../Methods";
import MonitorScreenCalculation from "../../component/Tables/Portfolio/AssetPositions/MonitorScreenCalculation";
import CloseTrades from "./CloseTrades";

const Rebalance = ({
  title,
  saveRebalancer,
  setSaveRebalancer,
  myStrategy,
}) => {
  const {
    signals,
    lots,
    accountingData,
    funds,
    strategies,
    marketData,
    isStrategist,
    trades,
    Test,
    livePrices,
    markets
  } = useSelector((state) => state?.todos);
  const dispatch = useDispatch();

  const [selectedFund, setSelectedFund] = useState("");
  const [loading, setLoading] = useState(false);
  const [mySignals, setSignals] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openSave, setOpenSave] = useState(false);
  const [error, setError] = useState(false);
  const [summaryRows, setSummaryRows] = useState([]);

  useEffect(() => {
    if (signals && signals.length > 0) {
      setSignals(signals);
    } else {
      setSignals([]);
    }
  }, [signals]);

  const handleMe = () => {
    if (selectedFund) {
      setSaveRebalancer(false);
      setOpenSave(true);
    } else {
      setError(true);
      setSaveRebalancer(true);
      setOpenSave(false);
    }
  };

  const handleClose = () => {
    setSelectedFund("");
    setSaveRebalancer(false);
    setSignals([]);
  };

  useEffect(() => {
    let timer;

    if (saveRebalancer) {
      setLoading(true);

      timer = setTimeout(() => {
        setLoading(false);
      }, 5000);
    } else {
      setLoading(false);
    }

    return () => clearTimeout(timer);
  }, [saveRebalancer]);

  useEffect(() => {
    if (selectedFund && myStrategy) {
      let fundObject = funds?.find((obj) => obj.abbreviation === selectedFund);
      const filteredData = MonitorScreenCalculation({
        selectedFund: fundObject?.fundId,
        selectedStrategy: myStrategy,
        selectedSide: "Both",
        lots: lots,
        accountingData: accountingData,
        funds: funds,
        strategies: strategies,
        marketData: livePrices,
        markets: markets,
        trades: trades,
        isStrategist: isStrategist,
      });

      setFilteredData(filteredData);
    }
  }, [marketData, selectedFund, myStrategy, Test]);

  useEffect(() => {
    const initialSums = {
      positive: { oldValueSum: 0, newValueSum: 0, sizeSum: 0 },
      negative: { oldValueSum: 0, newValueSum: 0, sizeSum: 0 },
    };

    const sums = (signals || []).reduce((acc, row) => {
      const isPositive = row?.oldValue > 0;
      const size =
        filteredData?.find((data) => data?.tickerSymbol === row?.symbol)
          ?.size || 0;

      if (isPositive) {
        acc.positive.oldValueSum += row?.oldValue || 0;
        acc.positive.newValueSum += row?.newValue || 0;
        acc.positive.sizeSum += parseFloat(size);
      } else {
        acc.negative.oldValueSum += row?.oldValue || 0;
        acc.negative.newValueSum += row?.newValue || 0;
        acc.negative.sizeSum += parseFloat(size);
      }

      return acc;
    }, initialSums);

    const netExposure = {
      oldValue:
        (sums?.positive?.oldValueSum || 0) + (sums?.negative?.oldValueSum || 0),
      newValue:
        (sums?.positive?.newValueSum || 0) + (sums?.negative?.newValueSum || 0),
      size: (sums?.positive?.sizeSum || 0) + (sums?.negative?.sizeSum || 0),
    };

    const grossExposure = {
      oldValue:
        (sums?.positive?.oldValueSum || 0) - (sums?.negative?.oldValueSum || 0),
      newValue:
        (sums?.positive?.newValueSum || 0) - (sums?.negative?.newValueSum || 0),
      size: (sums?.positive?.sizeSum || 0) - (sums?.negative?.sizeSum || 0),
    };

    setSummaryRows([
      {
        name: "Long Gross",
        oldValue: sums.positive.oldValueSum,
        newValue: sums.positive.newValueSum,
        size: sums.positive.sizeSum,
      },
      {
        name: "Short Gross",
        oldValue: sums.negative.oldValueSum,
        newValue: sums.negative.newValueSum,
        size: sums.negative.sizeSum,
      },
      {
        name: "Net Exposure",
        oldValue: netExposure.oldValue,
        newValue: netExposure.newValue,
        size: netExposure.size,
      },
      {
        name: "Gross Exposure",
        oldValue: grossExposure.oldValue,
        newValue: grossExposure.newValue,
        size: grossExposure.size,
      },
    ]);
  }, [signals, filteredData]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedFund(value);

    if (value === "") {
      setError(true);
    } else {
      setError(false);
    }
  };

  const fundsList = funds
    ?.map((i) => i?.abbreviation)
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

  return (
    <>
      <Dialog open={saveRebalancer} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ px: 8, height: "40vh", overflow: "auto" }}
        >
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%", width: "100%" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid
                display="flex"
                sx={{ marginBottom: 2 }}
                columnGap={2}
                alignItems={"center"}
              >
                <Typography sx={{ fontSize: 12 }}>Fund</Typography>
                <NativeSelect
                  value={selectedFund}
                  onChange={handleChange}
                  autoSelect
                  disableUnderline
                  sx={{ fontSize: "12px" }}
                  id="fund"
                  name="fund"
                >
                  <option value="" disabled>
                    Please Select
                  </option>
                  {fundsList?.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </NativeSelect>

                {error && (
                  <FormHelperText error>Please Select a Fund.</FormHelperText>
                )}
              </Grid>

              <Table>
                <TableHead>
                  <TableRow>
                    <Tablecell
                      sx={{
                        paddingLeft: "5px",
                        fontSize: "13px",
                        width: "180px",
                        fontWeight: "bold",
                      }}
                    >
                      Symbol
                    </Tablecell>
                    <Tablecell
                      sx={{
                        paddingLeft: "5px",
                        fontSize: "13px",
                        width: "130px",
                        fontWeight: "bold",
                      }}
                    >
                      Original Last Signal
                    </Tablecell>
                    <Tablecell
                      sx={{
                        paddingLeft: "5px",
                        fontSize: "13px",
                        width: "130px",
                        fontWeight: "bold",
                      }}
                    >
                      Current Weight
                    </Tablecell>
                    <Tablecell
                      sx={{
                        paddingLeft: "5px",
                        fontSize: "13px",
                        width: "130px",
                        fontWeight: "bold",
                      }}
                    >
                      New Signal
                    </Tablecell>
                    <Tablecell
                      sx={{
                        paddingLeft: "5px",
                        fontSize: "13px",
                        width: "130px",
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </Tablecell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mySignals?.map((row) => {
                    const matchedData = filteredData.find(
                      (data) => data.tickerSymbol === row.symbol
                    );

                    return (
                      <TableRow key={row.id}>
                        <Tablecell
                          sx={{ paddingLeft: "5px", fontSize: "13px" }}
                        >
                          {row?.symbol}
                        </Tablecell>
                        <Tablecell
                          sx={{ paddingLeft: "5px", fontSize: "13px" }}
                        >
                          {row?.oldValue
                            ? `${(row?.oldValue * 100)?.toFixed(2)}%`
                            : "-"}
                        </Tablecell>

                        <Tablecell
                          sx={{ paddingLeft: "5px", fontSize: "13px" }}
                        >
                          {matchedData?.size
                            ? Number.isFinite(Number(matchedData.size))
                              ? Number(matchedData.size).toFixed(2)
                              : "-"
                            : "-"}
                        </Tablecell>

                        <Tablecell
                          sx={{ paddingLeft: "5px", fontSize: "13px" }}
                        >
                          {row?.status !== "deleted"
                            ? `${(row?.newValue * 100)?.toFixed(2)}%`
                            : "-"}
                        </Tablecell>
                        <Tablecell
                          sx={{ paddingLeft: "5px", fontSize: "13px" }}
                        >
                          {row?.status?.toUpperCase()}
                        </Tablecell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <Table sx={{ mt: 5 }}>
                <TableBody>
                  {summaryRows?.map((summary, index) => (
                    <TableRow key={index}>
                      <Tablecell
                        sx={{
                          paddingLeft: "5px",
                          fontSize: "13px",
                          fontWeight: "bold",
                          width: "180px",
                        }}
                      >
                        {summary?.name}
                      </Tablecell>
                      <Tablecell
                        sx={{
                          paddingLeft: "5px",
                          fontSize: "13px",
                          width: "130px",
                        }}
                      >
                        {summary?.oldValue
                          ? `${(summary?.oldValue * 100)?.toFixed(2)}%`
                          : "-"}
                      </Tablecell>

                      <Tablecell
                        sx={{
                          paddingLeft: "5px",
                          fontSize: "13px",
                          width: "130px",
                        }}
                      >
                        {summary?.size && !isNaN(Number(summary.size))
                          ? Number(summary.size).toFixed(2)
                          : "-"}
                      </Tablecell>

                      <Tablecell
                        sx={{
                          paddingLeft: "5px",
                          fontSize: "13px",
                          width: "260px",
                        }}
                      >
                        {summary?.newValue
                          ? `${(summary?.newValue * 100).toFixed(2)}%`
                          : "-"}
                      </Tablecell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!loading && (
            <Grid
              item
              xs={12}
              margin="20px 0px 20px 1px"
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  height: "40px",
                }}
                onClick={handleMe}
              >
                REBALANCE ONLY UPDATED SIGNALS
              </Button>
              {/* <Button
                variant="contained"
                color="success"
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  height: "40px",
                  mr: 1,
                }}
                onClick={handleMe}
              >
                REBALANCE WHOLE SIGNAL SET
              </Button> */}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <CloseTrades
        title="CREATE TRADES"
        openSave={openSave}
        setOpenSave={setOpenSave}
        signals={signals}
        selectedFund={selectedFund}
        myStrategy={myStrategy}
      />
    </>
  );
};

export default Rebalance;
