import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getFunds,
  getStrategies,
  getLots,
  getTrades,
  getDeletes,
  getCustodians,
  getTickers,
  getHistoricalData,
  getFtp,
  getRouters,
  getBrokers,
  getSecurities,
  getManagers,
  getMarkets,
  getAccountingSystem,
  getFees,
  getProcessTrades,
  getHistoryReports,
  TradeCustodianFile,
  addNewBrokers,
  updateAccountingAum,
  addNewCustodians,
  modifyCustodians,
  addNewTrades,
  addNewOrders,
  addNewManual,
  addNewFill,
  addNewCommission,
  deleteTrades,
  setDefaultFund,
  TradeCustodianAccountingFile,
  ReportFile,
  getUserPreference,
  setGlobalContract,
  deleteCustodian,
  modifyBrokers,
  deleteBroker,
  deleteFund,
  modifyFunds,
  saveMarketData,
  addNewFunds,
  addNewStrategy,
  modifyStrategy,
  deleteStrategy,
  requestMarketData,
  modifySignal,
  getNotifications,
  modifyNotification,
  getCustomFunds,
  modifyUnreadNotification,
  modifySignalValue,
  updateCalculatedMonitorScreen,
  getAllocationData,
  stageOneOfSleeve,
  stageTwoOfRebalance,
  stageThreeOfRebalance,
  RebalanceSummary,
  blacklistRulesManager,
  blacklistRulesModifiedManager,
  blacklistRulesModifiedFunds,
  blacklistRulesFunds,
  BlacklistSignals,
  blacklistDeleteFunds,
  blacklistDeleteManagers,
  RebalancedTrades,
  stageOneOfRebalance,
  clearRebalancer,
  finalCrossedTrades,
  CreateReport,
  UpdateSecurities,
  updatefigi,
  saveThreshold,
} from "../Actions/todoActions";

import {
  TradeInvestment,
  findFund,
  findFundById,
  hashTradeType,
} from "../../component/Methods";
import {
  dummyLots,
  dummyAccountingData,
  dummyStrategies,
  dummyBrokers,
  dummyFunds,
} from "./dummy";
import dayjs from "dayjs";
import { removeUser } from "../Actions/UserActions";
import { toast, ToastContainer } from "react-toastify";

const initialState = {
  error: "",
  loading: false,
  loadingmsg: "",
  isLoading: false,
  rebalancerFund: "",
  isReconnecting: false,
  activeRequests: 0,
  load: true,
  crossingLoader: true,
  showMarketLoader: false,
  defaultFund: { defaultFund: "", defaultFundStrategy: "" },
  marketData: [],
  globalContract: { shareCounts: 0, prices: 0 },
  data: [],
  managers: [],
  markets: [],
  routeList: [],
  funds: [],
  customFunds: [],
  strategies: [],
  historical: [],
  custodians: [],
  tickers: [],
  lots: [],
  trades: [],
  processedTrades: {},
  reports: [],
  signals: [],
  securities: [],
  UserPreference: {},
  accountingData: [],
  file: [],
  reportFile: {},
  notifications: [],
  accountingFile: {},
  deletes: [],
  Ftp: [],
  RawMarketData: {},
  fees: {},
  signalsPerformance: [],
  companyNames: [],
  livePrices: [],
  calculatedMonitorScreen: [],
  allocation: {},
  sleeveDataStageOne: {},
  blacklistedTickers: [],
  identifier: "",
  AllTrades: {},
  finalCrossedTrades: [],
  orders: [],
  locates: {},
  blacklistSignal: [],
  blacklistRulesData: {},
  isStrategist: false,
  isInvestor: false,
  rebalancerSummary: {},
  marketDataThreshold: 20,
};

function getUid() {
  const characters = "0123456789abcdef";
  const length = 32;
  let uniqueIdentifier = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueIdentifier += characters[randomIndex];
  }
  return uniqueIdentifier;
}

const todoReducer = createSlice({
  name: "todos",
  initialState,
  reducers: {
    updateLoading: (state, { payload }) => {
      state.loading = Boolean(payload);
    },
    updateLoadingMsg: (state, { payload }) => {
      state.loadingmsg = payload;
    },
    updateLoadingAxios: (state, { payload }) => {
      state.isLoading = Boolean(payload);
    },
    updateIsStrategist: (state, { payload }) => {
      state.isStrategist = Boolean(payload);
    },
    updateIsInvestor: (state, { payload }) => {
      state.isInvestor = Boolean(payload);
    },
    updateRebalancerFund: (state, { payload }) => {
      state.rebalancerFund = payload;
    },
    setReconnecting: (state, action) => {
      state.isReconnecting = action.payload;
    },
    setShowMarketLoader: (state, action) => {
      state.showMarketLoader = action.payload;
    },
    incrementActiveRequests: (state) => {
      state.activeRequests = (state.activeRequests || 0) + 1;
    },
    decrementActiveRequests: (state) => {
      state.activeRequests = Math.max((state.activeRequests || 0) - 1, 0);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFunds.fulfilled, (state, action) => {
      // const manager = action.payload;
      // const findFund = manager[0]?.funds;
      // const fundsArray = Object.values(findFund);
      // state.funds = dummyFunds();
      state.funds = action.payload;
      //state.loading = false;
    });
    builder.addCase(getCustomFunds.fulfilled, (state, action) => {
      state.customFunds = action.payload;
      //state.loading = false;
    });
    builder.addCase(setDefaultFund.fulfilled, (state, action) => {
      state.defaultFund = action.payload;
      //state.loading = false;
    });
    builder.addCase(setGlobalContract.fulfilled, (state, action) => {
      state.globalContract = action.payload;
      //state.loading = false;
    });
    builder.addCase(getStrategies.fulfilled, (state, action) => {
      const sortData = action.payload;
      // const sortData = dummyStrategies();
      const sort = sortData?.sort((a, b) => (a.name > b.name ? 1 : -1));
      state.strategies = sort;

      //state.loading = false;
    });
    builder.addCase(getHistoricalData.fulfilled, (state, action) => {
      state.historical = action.payload;
      //state.loading = false;
    });

    builder.addCase(requestMarketData.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(requestMarketData.fulfilled, (state, action) => {
      state.marketData = action.payload;
      //state.loading = false;
    });
    builder.addCase(requestMarketData.rejected, (state) => {
      //state.loading = false;
    });
    builder.addCase(removeUser.pending, (state) => {
      // state.loading = true;
    });
    builder.addCase(removeUser.fulfilled, (state) => {
      state.todos = [];
      //state.loading = false;
    });
    builder.addCase(removeUser.rejected, (state) => {
      //state.loading = false;
    });
    builder.addCase(getLots.fulfilled, (state, action) => {
      let lots = action.payload?.length > 0 ? action.payload : state?.lots;

      //  lots = dummyLots();

      const trades = state?.trades;

      //ADDING TODAYS TRADES TO LOTS
      let todaysTrades = "";
      let completedShares = 0;
      let completedSharesPrice = 0;
      const currentDate = new Date();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const year = currentDate.getFullYear();
      const formattedDate = `${month}/${day}/${year}`;
      const startOfDay = new Date(formattedDate).getTime();
      const endOfDay = startOfDay + 86400000 - 60;

      // lots = lots?.filter((row) => {
      //   return row?.date < startOfDay;
      // });

      if (trades) {
        todaysTrades = trades?.filter((row) => {
          const lastOrder = row?.orders?.slice(-1)[0];
          return lastOrder?.date > startOfDay && lastOrder?.date < endOfDay;
        });

        todaysTrades = todaysTrades.filter((row) => {
          if (row?.manuals.length > 0 || row.hasOwnProperty("fills")) {
            return true;
          }
        });

        todaysTrades?.map((trade) => {
          if (trade?.manuals.length > 0) {
            const lastManual = trade?.manuals.slice(-1)[0];
            completedShares = lastManual.shareCount;
            completedSharesPrice = lastManual.sharePrice;
          } else if (
            trade?.manuals.length == 0 &&
            trade.hasOwnProperty("fills")
          ) {
            completedShares = trade.fills?.shareCount;
            completedSharesPrice = trade.fills?.sharePrice;
          }

          if (Array.isArray(trade?.orders)) {
            const lastOrder = trade.orders.slice(-1)[0];
            if (lastOrder.ticker.investment === TradeInvestment.Equity) {
              //EQUITY TRADES

              let fundkey = Object.keys(lastOrder?.distribution)[0];
              let strategy = lastOrder.hasOwnProperty("strategy")
                ? lastOrder.strategy
                : undefined;
              if (
                hashTradeType(lastOrder?.action, lastOrder?.position) == 1 ||
                hashTradeType(lastOrder?.action, lastOrder?.position) == 2
              )
                completedShares = completedShares * -1;
              if (completedShares != 0) {
                let topush = {
                  cost: 0,
                  currency: "USD",
                  custodian: getUid(),
                  date: Date.now(),
                  fund: fundkey,
                  investment: TradeInvestment.Equity,
                  position: lastOrder?.position,
                  shareCount: completedShares,
                  sharePrice: completedSharesPrice,
                  ticker: {
                    currency: 7,
                    exchange: 3,

                    investment: TradeInvestment.Equity,

                    symbol: lastOrder?.ticker.symbol,
                    tickerId: getUid(),
                  },
                  value: -15345.680000000002,
                  lotId: getUid(),
                };
                if (
                  hashTradeType(lastOrder?.action, lastOrder?.position) == 0 ||
                  hashTradeType(lastOrder?.action, lastOrder?.position) == 1
                )
                  topush.todaybuysell = true;
                if (
                  hashTradeType(lastOrder?.action, lastOrder?.position) == 2 ||
                  hashTradeType(lastOrder?.action, lastOrder?.position) == 3
                )
                  topush.todayshortcover = true;
                if (strategy) {
                  topush.strategy = strategy;
                }

                //  lots.push(topush);
              }
            }
          }
        });
      }

      state.lots = lots;
      //state.loading = false;
    });

    builder.addCase(getTrades.fulfilled, (state, action) => {
      state.trades = action.payload?.filter((row) => {
        return row.deletion == undefined;
      });
      //state.loading = false;
    });
    builder.addCase(getDeletes.fulfilled, (state, action) => {
      let newDeletedTrades = action.payload?.filter((row) => {
        return row.deletion != undefined;
      });
      state.deletes = newDeletedTrades;
      //state.loading = false;
    });
    builder.addCase(getCustodians.fulfilled, (state, action) => {
      const sortData = action.payload;
      const sort = sortData?.sort((a, b) => (a.name > b.name ? 1 : -1));
      state.custodians = sort;
      //state.loading = false;
    });
    builder.addCase(getTickers.fulfilled, (state, action) => {
      state.tickers = action.payload;
      //state.loading = false;
    });
    builder.addCase(getFtp.fulfilled, (state, action) => {
      state.Ftp = action.payload;
      //state.loading = false;
    });
    builder.addCase(getRouters.fulfilled, (state, action) => {
      state.routeList = action.payload;
      //state.loading = false;
    });
    builder.addCase(getBrokers.fulfilled, (state, action) => {
      const sortData = action.payload;
      //  const sortData = dummyBrokers();
      const sort = sortData?.sort((a, b) => (a.name > b.name ? 1 : -1));
      state.data = sort;
      //state.loading = false;
    });
    builder.addCase(getSecurities.fulfilled, (state, action) => {
      state.securities = action.payload;
      //state.loading = false;
    });

    builder.addCase(getManagers.fulfilled, (state, action) => {
      const managersData = action.payload;
      state.managers = managersData;
      //state.loading = false;
    });
    builder.addCase(getMarkets.fulfilled, (state, action) => {
      state.markets = action.payload;
      //state.loading = false;
    });
    builder.addCase(getAccountingSystem.fulfilled, (state, action) => {
      state.accountingData = action.payload;
      //  state.accountingData = dummyAccountingData();
      //state.loading = false;
    });
    builder.addCase(getUserPreference.fulfilled, (state, action) => {
      state.UserPreference = action.payload;
      state.defaultFund.defaultFund = action.payload?.defaults?.defaultFund;
      state.defaultFund.defaultFundStrategy =
        action.payload?.defaults?.defaultStrategy;
      //state.loading = false;
    });
    builder.addCase(getFees.fulfilled, (state, action) => {
      state.fees = action.payload;
      //state.loading = false;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      //state.loading = false;
    });
    builder.addCase(getAllocationData.fulfilled, (state, action) => {
      const { fundId, items } = action.payload;
      if (state.managers && state.managers.length > 0) {
        const funds = state.managers[0].funds;

        if (funds) {
          if (funds[fundId]) {
            funds[fundId] = {
              ...funds[fundId],
              allocation: { items: items },
            };
            toast.success(
              `Allocation for ${findFund(fundId, state.funds)} is Updated`
            );
          } else {
            toast.error(`${findFund(fundId, state.funds)} not found in state.`);
          }
        } else {
          toast.error("funds object is undefined in state.");
        }
      }
    });

    builder.addCase(updatefigi.fulfilled, (state, action) => {
      if (
        action.payload?.status === "success" &&
        action.payload?.securityMasterItems &&
        action.payload?.securityMasterItems?.length > 0
      ) {
        const updatedSecurities = action.payload?.securityMasterItems;
        updatedSecurities?.forEach((payloadItem) => {
          const securityIndex = state.securities.findIndex(
            (security) => security.tickerId === payloadItem.tickerId
          );

          if (securityIndex !== -1) {
            state.securities[securityIndex] = {
              ...state.securities[securityIndex],
              ...payloadItem,
            };
          }
        });
      }

      toast.success(
        action.payload?.updatedTickerCount +
          " out of " +
          action.payload?.requestedTickerCount +
          " Tickers Updated"
      );
    });
    builder.addCase(getProcessTrades.fulfilled, (state, action) => {
      state.processedTrades = action.payload;
      //state.loading = false;
    });
    builder.addCase(getHistoryReports.fulfilled, (state, action) => {
      state.reports = action.payload;
      //state.loading = false;
    });

    builder.addCase(TradeCustodianFile.fulfilled, (state, action) => {
      state.file = action.payload;
      //state.loading = false;
    });
    builder.addCase(ReportFile.fulfilled, (state, action) => {
      state.reportFile = action.payload;
      const generation = action.payload?.generation;

      const reportIndex = state?.reports?.findIndex(
        (a) => a?.reportId === action.payload?.report
      );

      if (reportIndex !== -1) {
        const updatedReports = [...state.reports];
        const report = updatedReports[reportIndex];
        if (report?.generations && Array.isArray(report?.generations)) {
          const existingGenerationIndex = report.generations.findIndex(
            (gen) =>
              gen?.date === generation?.date &&
              gen?.reportId === generation?.reportId
          );

          if (existingGenerationIndex !== -1) {
            report.generations[existingGenerationIndex] = {
              ...report.generations[existingGenerationIndex],
              ...generation,
            };
          } else {
            report.generations.push(generation);
          }
        } else {
          report.generations = [generation];
        }
        updatedReports[reportIndex] = report;
        state.reports = updatedReports;
      }
    });
    builder.addCase(CreateReport.fulfilled, (state, action) => {
      if (action.payload?.message && action.payload?.error) {
        toast.error(action.payload?.error);
      }

      const report = action.payload?.report;
      if (report) {
        const reportIndex = state?.reports?.findIndex(
          (a) => a?.reportId === report?.reportId
        );

        if (reportIndex === -1) {
          state.reports.push(report);
          toast.success("Report Created");
        } else {
          toast.error("Report already exists in the state.");
        }
      }
      //state.loading = false;
    });

    builder.addCase(TradeCustodianAccountingFile.fulfilled, (state, action) => {
      state.accountingFile = action.payload;
      //state.loading = false;
    });
    builder.addCase(addNewBrokers.fulfilled, (state, action) => {
      if (state?.data?.find((a) => a?.brokerId !== action.payload?.brokerId)) {
        state.data = [...state.data, action.payload];
      }
      //state.loading = false;
    });
    builder.addCase(updateAccountingAum.fulfilled, (state, action) => {
      const { aum, strategyAUMs, fund } = action.payload.accountingItem;
      state?.accountingData[0]?.funds?.forEach((stateFund) => {
        if (stateFund.fund === fund) {
          stateFund.aum = aum;
          if (strategyAUMs) {
            Object.entries(strategyAUMs).forEach(
              ([strategyId, strategyAUM]) => {
                stateFund.strategyAUMs[strategyId] = strategyAUM;
              }
            );
          }
        }
      });
      //state.loading = false;
    });
    builder.addCase(modifyBrokers.fulfilled, (state, action) => {
      const existingBrokerIndex = state.data.findIndex(
        (a) => a?.brokerId === action.payload?.brokerId
      );

      if (existingBrokerIndex !== -1) {
        state.data[existingBrokerIndex] = action.payload;
      } else {
        state.data.push(action.payload);
      }
      //state.loading = false;
    });
    builder.addCase(deleteBroker.fulfilled, (state, action) => {
      const brokerIdToDelete = action.payload?.brokerId;
      state.data = state.data.filter((a) => a?.brokerId !== brokerIdToDelete);

      //state.loading = false;
    });
    builder.addCase(addNewCustodians.fulfilled, (state, action) => {
      if (
        state?.custodians?.find(
          (a) => a?.custodianId !== action.payload?.custodianId
        )
      ) {
        state.custodians = [...state.custodians, action.payload];
      }
      //state.loading = false;
    });
    builder.addCase(modifyCustodians.fulfilled, (state, action) => {
      const existingCustodianIndex = state.custodians.findIndex(
        (a) => a?.custodianId === action.payload?.custodianId
      );

      if (existingCustodianIndex !== -1) {
        state.custodians[existingCustodianIndex] = action.payload;
      } else {
        state.custodians.push(action.payload);
      }
      //state.loading = false;
    });
    builder.addCase(deleteCustodian.fulfilled, (state, action) => {
      const custodianIdToDelete = action.payload?.custodianId;
      state.custodians = state.custodians.filter(
        (a) => a?.custodianId !== custodianIdToDelete
      );

      //state.loading = false;
    });

    builder.addCase(
      updateCalculatedMonitorScreen.fulfilled,
      (state, action) => {
        state.calculatedMonitorScreen = action.payload;
        //state.loading = false;
      }
    );

    builder.addCase(addNewFunds.fulfilled, (state, action) => {
      if (action.payload?.message === "success" && action.payload?.fund) {
        const updatedFund = action.payload?.fund;
        if (state?.funds?.find((a) => a?.fundId !== updatedFund?.fundId)) {
          state.funds = [...state.funds, action.payload.fund];
        }

        toast.success(`${updatedFund.name} has been successfully added.`);
      } else {
        toast.error(`Failed to add. Please try again.`);
      }

      //state.loading = false;
    });
    builder.addCase(modifyFunds.fulfilled, (state, action) => {
      const existingFundIndex = state.funds.findIndex(
        (a) => a?.fundId === action.payload?.fundId
      );

      if (existingFundIndex !== -1) {
        state.funds[existingFundIndex] = action.payload;
      } else {
        state.funds.push(action.payload);
      }
      //state.loading = false;
    });
    builder.addCase(deleteFund.fulfilled, (state, action) => {
      const fundIdToDelete = action.payload?.fundId;
      state.funds = state.funds.filter((a) => a?.fundId !== fundIdToDelete);

      //state.loading = false;
    });
    builder.addCase(addNewStrategy.fulfilled, (state, action) => {
      if (action.payload?.id) {
        const modifiedPayload = { ...action.payload };
        modifiedPayload.owner = modifiedPayload.user;
        modifiedPayload.strategyId = modifiedPayload.id;
        modifiedPayload.date = new Date(modifiedPayload?.date).getTime();
        if (
          !state.strategies.find((a) => a?.strategyId === modifiedPayload.id)
        ) {
          state.strategies = [...state.strategies, modifiedPayload];
        }
      }

      //state.loading = false;
    });
    builder.addCase(UpdateSecurities.fulfilled, (state, action) => {
      if (
        action.payload?.status === "success" &&
        action.payload?.securityMasterItems &&
        action.payload?.securityMasterItems?.length > 0
      ) {
        const updatedSecurities = action.payload?.securityMasterItems;

        updatedSecurities?.forEach((payloadItem) => {
          const existingSecurity = state.securities.find(
            (security) => security.tickerId === payloadItem.tickerId
          );
          if (existingSecurity) {
            state.securities = state.securities.map((security) =>
              security.tickerId === payloadItem.tickerId
                ? { ...security, ...payloadItem }
                : security
            );
          } else {
            state.securities.push(payloadItem);
          }
        });

        toast.success("Securities are Updated");
      } else {
        toast.error("Securities cannot be Updated");
      }
    });

    builder.addCase(modifyStrategy.fulfilled, (state, action) => {
      const existingStrategyIndex = state.strategies.findIndex(
        (a) => a?.strategyId === action.payload?.strategyId
      );

      if (existingStrategyIndex !== -1) {
        state.strategies[existingStrategyIndex] = action.payload;
      } else {
        state.strategies.push(action.payload);
      }
      //state.loading = false;
    });
    builder.addCase(deleteStrategy.fulfilled, (state, action) => {
      const StrategyIdToDelete = action.payload?.strategyId;
      state.strategies = state.strategies.filter(
        (a) => a?.strategyId !== StrategyIdToDelete
      );

      //state.loading = false;
    });
    builder.addCase(addNewTrades.fulfilled, (state, action) => {
      // go through each trade in payload
      for (const trade of action.payload) {
        if (state?.trades?.find((a) => a?.tradeId != trade?.tradeId)) {
          state.trades = [...state.trades, trade];
        }
      }
      //state.loading = false;
    });

    builder.addCase(addNewOrders.fulfilled, (state, action) => {
      let newOrders = action.payload.data;
      if (action.payload.data.length === 1) {
        const singleOrder = action.payload.data[0];
        let newOrder = state?.trades?.map((a) => {
          if (a?.tradeId === singleOrder.tradeId) {
            return {
              ...a,
              orders:
                a?.orders?.length > 0
                  ? [...a.orders, singleOrder.order]
                  : [singleOrder.order],
            };
          } else {
            return a;
          }
        });

        state.trades = newOrder;
      } else {
        const updatedTrades = state?.trades?.map((trade) => {
          const matchingOrder = newOrders.find(
            (order) => order.tradeId === trade.tradeId
          );
          if (matchingOrder) {
            return {
              ...trade,
              orders:
                trade?.orders?.length > 0
                  ? [...trade.orders, matchingOrder.order]
                  : [matchingOrder.order],
            };
          } else {
            return trade;
          }
        });

        state.trades = updatedTrades;
      }

      //state.loading = false;
    });

    builder.addCase(addNewManual.fulfilled, (state, action) => {
      let newManual = state?.trades?.map((a) => {
        if (a?.tradeId == action.payload[0]?.trade) {
          return {
            ...a,
            manuals:
              a?.manuals?.length > 0
                ? [...a.manuals, action.payload[0]]
                : [action.payload[0]],
          };
        } else {
          return a;
        }
      });
      state.trades = newManual;
      //state.loading = false;
    });
    builder.addCase(addNewFill.fulfilled, (state, action) => {
      let newFill = state?.trades?.map((a) => {
        if (a?.tradeId == action.payload?.tradeId) {
          return { ...a, fill: action.payload };
        } else {
          return a;
        }
      });
      state.trades = newFill;
      //state.loading = false;
    });
    builder.addCase(addNewCommission.fulfilled, (state, action) => {
      let newCommissions = action.payload.data;

      if (action.payload.data.length === 1) {
        const singleCommission = action.payload.data[0];

        let newCommission = state?.trades?.map((a) => {
          if (a?.tradeId === singleCommission.trade) {
            return { ...a, commission: singleCommission };
          } else {
            return a;
          }
        });

        state.trades = newCommission;
      } else {
        const updatedTrades = state?.trades?.map((trade) => {
          const matchingCommission = newCommissions.find(
            (commission) => commission.trade === trade.tradeId
          );

          if (matchingCommission) {
            return { ...trade, commission: matchingCommission };
          } else {
            return trade;
          }
        });

        state.trades = updatedTrades;
      }

      //state.loading = false;
    });

    builder.addCase(deleteTrades.fulfilled, (state, action) => {
      const deletedTradeIds = action.payload.data.map(
        (deletedTrade) => deletedTrade.trade
      );
      const newDeletedTrades = state?.trades?.filter(
        (trade) => !deletedTradeIds.includes(trade.tradeId)
      );
      const deletedTrades = state?.trades?.filter((trade) =>
        deletedTradeIds.includes(trade.tradeId)
      );
      state.deletes = [...state.deletes, ...deletedTrades];
      state.trades = newDeletedTrades;
      //state.loading = false;
    });

    builder.addCase(saveThreshold.fulfilled, (state, action) => {
      state.marketDataThreshold = action.payload;
      //state.loading = false;
    });
    builder.addCase(saveMarketData.fulfilled, (state, action) => {
      function processData(jsonData) {
        const resultArray = [];
        const companyArray = [];
        const signalsArray = [];
        let currentPrice = 1;
        let yesterdayPrice = 1;
        let expire = Date.now();
        let companyName = "";
        let PercentChangeDay = 0;
        let PercentChangeMonth = 0;
        let PercentChangeQuarter = 0;
        let PercentChangeWeek = 0;
        let marketCap = 0;
        let sector = "";
        let expire2 = Date.now();
        let expire3 = Date.now();

        if (jsonData.data) {
          if (jsonData.data.keys) {
            for (const key in jsonData.data.keys) {
              let marketKey = "";
              yesterdayPrice = 1;
              if (jsonData.data.values.hasOwnProperty(key)) {
                const symbolName = jsonData.data.keys[key].Symbol;

                if (jsonData.data.values[key]["9"]) {
                  const currentPriceKey = Object.keys(
                    jsonData.data.values[key]["9"]
                  )[0];
                  marketKey = currentPriceKey;

                  currentPrice =
                    jsonData.data.values[key]["9"][currentPriceKey].value;

                  expire = jsonData.data.values[key]["9"][currentPriceKey].date;
                }

                if (jsonData.data.values[key]["10"]) {
                  const yesterdayPriceKey = Object.keys(
                    jsonData.data.values[key]["10"]
                  )[0];
                  yesterdayPrice =
                    jsonData.data.values[key]["10"][yesterdayPriceKey].value;

                  marketKey = yesterdayPriceKey;
                }

                if (currentPrice != 1) {
                  resultArray.push({
                    symbol: symbolName.toLowerCase(),
                    price: currentPrice,
                    yestPrice: yesterdayPrice,
                    expiration: expire,
                    market: marketKey,
                  });
                }
              }
            }
          }
        }

        if (jsonData.keys) {
          for (const key in jsonData.keys) {
            companyName = "";
            yesterdayPrice = 1;
            PercentChangeDay = 0;
            PercentChangeMonth = 0;
            PercentChangeQuarter = 0;
            PercentChangeWeek = 0;
            marketCap = 0;
            sector = "";
            let marketKey = "";
            if (jsonData.values.hasOwnProperty(key)) {
              const symbolName = jsonData.keys[key]?.symbol
                ? jsonData.keys[key].symbol
                : jsonData.keys[key].Symbol;

              //PRICE and YESTERDAY'S PRICE /////////////////////////
              if (jsonData.values[key]["9"]) {
                const currentPriceKey = Object.keys(
                  jsonData.values[key]["9"]
                )[0];
                marketKey = currentPriceKey;

                currentPrice = jsonData.values[key]["9"][currentPriceKey].value;
                expire = jsonData.values[key]["9"][currentPriceKey].date;
              }

              if (jsonData.values[key]["10"]) {
                const yesterdayPriceKey = Object.keys(
                  jsonData.values[key]["10"]
                )[0];
                yesterdayPrice =
                  jsonData.values[key]["10"][yesterdayPriceKey].value;
                marketKey = yesterdayPriceKey;
              }
              ///////////////////////////////////////////////////////////

              //COMPANY NAMES ///////////////////////
              if (jsonData.values[key]["1"]) {
                const companyNameKey = Object.keys(
                  jsonData.values[key]["1"]
                )[0];
                companyName = jsonData.values[key]["1"][companyNameKey].value;

                expire2 = jsonData.values[key]["1"][companyNameKey].date;
                marketKey = companyNameKey;
              }
              /////////////////////////////

              //SIGNALS PERFORMANCE //////////

              if (jsonData.values[key]["4"]) {
                const marketCapKey = Object.keys(jsonData.values[key]["4"])[0];
                marketCap = jsonData.values[key]["4"][marketCapKey].value;
                marketKey = marketCapKey;
              }

              if (jsonData.values[key]["5"]) {
                const PercentChangeDayKey = Object.keys(
                  jsonData.values[key]["5"]
                )[0];
                PercentChangeDay =
                  jsonData.values[key]["5"][PercentChangeDayKey].value;
                expire3 = jsonData.values[key]["5"][PercentChangeDayKey].date;
                marketKey = PercentChangeDayKey;
              }
              if (jsonData.values[key]["6"]) {
                const PercentChangeMonthKey = Object.keys(
                  jsonData.values[key]["6"]
                )[0];
                PercentChangeMonth =
                  jsonData.values[key]["6"][PercentChangeMonthKey].value;
                marketKey = PercentChangeMonthKey;
              }
              if (jsonData.values[key]["7"]) {
                const PercentChangeQuarterKey = Object.keys(
                  jsonData.values[key]["7"]
                )[0];
                PercentChangeQuarter =
                  jsonData.values[key]["7"][PercentChangeQuarterKey].value;
                marketKey = PercentChangeQuarterKey;
              }
              if (jsonData.values[key]["8"]) {
                const PercentChangeWeekKey = Object.keys(
                  jsonData.values[key]["8"]
                )[0];
                PercentChangeWeek =
                  jsonData.values[key]["8"][PercentChangeWeekKey].value;
                marketKey = PercentChangeWeekKey;
              }

              if (jsonData.values[key]["11"]) {
                const sectorKey = Object.keys(jsonData.values[key]["11"])[0];
                sector = jsonData.values[key]["11"][sectorKey].value;
                marketKey = sectorKey;
              }

              /////////////////////////////

              if (companyName != "") {
                companyArray.push({
                  symbol: symbolName.toLowerCase(),
                  company: companyName,
                  expiration: expire2,
                  market: marketKey,
                });
              }

              if (currentPrice != 1) {
                resultArray.push({
                  symbol: symbolName.toLowerCase(),
                  price: currentPrice,
                  yestPrice: yesterdayPrice,
                  expiration: expire,
                  market: marketKey,
                });
              }

              if (PercentChangeDay != 0) {
                signalsArray.push({
                  symbol: symbolName.toLowerCase(),
                  expiration: expire3,
                  PercentChangeDay: PercentChangeDay,
                  PercentChangeMonth: PercentChangeMonth,
                  PercentChangeQuarter: PercentChangeQuarter,
                  PercentChangeWeek: PercentChangeWeek,
                  marketCap: marketCap,
                  sector: sector,
                  market: marketKey,
                });
              }
            }
          }
        }

        return [resultArray, companyArray, signalsArray];
      }

      const socketData = processData(action.payload)[0];
      const companyData = processData(action.payload)[1];
      const signalsData = processData(action.payload)[2];

      if (socketData.length > 0) {
        let existingData = state.livePrices;

        //IF LOCAL STORAGE ALREADY HAVE DATA
        if (existingData.length > 0) {
          const mergedArray = existingData.concat(socketData);

          const uniqueMergedArray = Array.from(
            mergedArray
              .reduce((map, obj) => {
                // Keep the object with the maximum expiration date for each symbol
                if (
                  !map.has(obj.symbol) ||
                  new Date(obj.expiration) >
                    new Date(map.get(obj.symbol).expiration)
                ) {
                  map.set(obj.symbol, obj);
                }
                return map;
              }, new Map())
              .values()
          );

          state.livePrices = uniqueMergedArray;
        } else {
          state.livePrices = socketData;
        }
      }

      if (companyData.length > 0) {
        let existingData = state.companyNames;

        //IF LOCAL STORAGE ALREADY HAVE DATA
        if (existingData.length > 0) {
          const mergedArray = existingData.concat(companyData);

          const uniqueMergedArray = Array.from(
            mergedArray
              .reduce((map, obj) => {
                // Keep the object with the maximum expiration date for each symbol
                if (
                  !map.has(obj.symbol) ||
                  new Date(obj.expiration) >
                    new Date(map.get(obj.symbol).expiration)
                ) {
                  map.set(obj.symbol, obj);
                }
                return map;
              }, new Map())
              .values()
          );

          state.companyNames = uniqueMergedArray;
        } else {
          state.companyNames = companyData;
        }
      }

      if (signalsData.length > 0) {
        let existingData = state.signalsPerformance;

        //IF LOCAL STORAGE ALREADY HAVE DATA
        if (existingData.length > 0) {
          const mergedArray = existingData.concat(signalsData);

          const uniqueMergedArray = Array.from(
            mergedArray
              .reduce((map, obj) => {
                // Keep the object with the maximum expiration date for each symbol
                if (
                  !map.has(obj.symbol) ||
                  new Date(obj.expiration) >
                    new Date(map.get(obj.symbol).expiration)
                ) {
                  map.set(obj.symbol, obj);
                }
                return map;
              }, new Map())
              .values()
          );

          state.signalsPerformance = uniqueMergedArray;
        } else {
          state.signalsPerformance = signalsData;
        }
      }

      state.RawMarketData = action.payload;
      //state.loading = false;
    });

    builder.addCase(modifyNotification.fulfilled, (state, action) => {
      const { content, heading, metadata } = action.payload.data;

      const newNotification = {
        content: content,
        heading: heading,
        metadata: metadata,
        creationDate: dayjs().valueOf(),
      };
      state.notifications.unshift(newNotification);
      //state.loading = false;
    });
    builder.addCase(modifyUnreadNotification.fulfilled, (state, action) => {
      const { message } = action.payload.data;
      if (message === "success") {
        state.notifications = state.notifications.map((notification) => ({
          ...notification,
          isRead: true,
        }));
      }

      // If you need to add a new notification, you can push it here.
      // const newNotification = { /* your new notification object */ };
      // state.notifications.push(newNotification);

      //state.loading = false;
    });

    builder.addCase(stageOneOfRebalance.fulfilled, (state, action) => {
      const { message, result } = action.payload || {};
      if (message === "success") {
        state.blacklistedTickers = result || [];
      } else {
        state.blacklistedTickers = [];
      }
    });

    builder.addCase(stageOneOfSleeve.fulfilled, (state, action) => {
      const { message, data } = action.payload || {};
      if (message === "success") {
        state.sleeveDataStageOne = data || {};
      } else {
        state.sleeveDataStageOne = {};
      }
      //state.loading = false;
    });
    builder.addCase(stageTwoOfRebalance.fulfilled, (state, action) => {
      state.AllTrades = action.payload;
      state.crossingLoader = false;
      //state.loading = false;
    });
    builder.addCase(RebalanceSummary.fulfilled, (state, action) => {
      state.rebalancerSummary = action.payload;
    });

    builder.addCase(stageThreeOfRebalance.fulfilled, (state, action) => {
      state.locates = action.payload.data;
      state.identifier = action.payload.identifier;
      //state.loading = false;
    });
    builder.addCase(finalCrossedTrades.fulfilled, (state, action) => {
      state.finalCrossedTrades = action.payload;
    });

    builder.addCase(clearRebalancer.fulfilled, (state, action) => {
      state.blacklistedTickers = {};
      state.AllTrades = {};
      state.sleeveDataStageOne = {};
      state.orders = [];
      state.crossingLoader = true;
      state.finalCrossedTrades = [];
      //state.loading = false;
    });
    builder.addCase(RebalancedTrades.fulfilled, (state, action) => {
      state.orders = action.payload;
      state.load = false;
      //state.loading = false;
    });
    builder.addCase(BlacklistSignals.fulfilled, (state, action) => {
      state.blacklistSignal = action.payload.blacklistedTickers;
      //state.loading = false;
    });
    builder.addCase(modifySignal.fulfilled, (state, action) => {
      const { strategy, weights_keys, weights_values } = action.payload.data;
      state.strategies = state.strategies.map((a) => {
        if (a.strategyId === strategy) {
          a.signals = {
            weights_keys,
            weights_values,
          };
        }
        return a;
      });

      //state.loading = false;
    });
    builder.addCase(modifySignalValue.fulfilled, (state, action) => {
      state.signals = action.payload;
      //state.loading = false;
    });
    builder.addCase(blacklistRulesFunds.fulfilled, (state, action) => {
      const { fund, blacklist } = action.payload;
      const fundIndex = state.funds.findIndex((i) => i.fundId === fund?.fundId);
      if (fundIndex !== -1) {
        state.funds[fundIndex] = {
          ...state.funds[fundIndex],
          blacklist: blacklist,
        };
      }

      //state.loading = false;
    });
    builder.addCase(blacklistDeleteFunds.fulfilled, (state, action) => {
      const { blacklist } = action.payload;
      const fundIndex = state.funds.findIndex(
        (i) => i.fundId === blacklist?.fundId
      );
      if (fundIndex !== -1) {
        state.funds[fundIndex] = {
          ...state.funds[fundIndex],
          blacklist: {
            ...state.funds[fundIndex].blacklist,
            rules: [],
          },
        };
      }

      //state.loading = false;
    });
    builder.addCase(blacklistRulesModifiedFunds.fulfilled, (state, action) => {
      const { blacklist } = action.payload;
      const fundIndex = state.funds.findIndex(
        (i) => i.fundId === blacklist?.fundId
      );

      if (fundIndex !== -1) {
        state.funds[fundIndex] = {
          ...state.funds[fundIndex],
          blacklist: {
            ...state.funds[fundIndex].blacklist,
            rules: blacklist.rules,
          },
        };
      }

      //state.loading = false;
    });
    builder.addCase(blacklistRulesManager.fulfilled, (state, action) => {
      const { manager, blacklist } = action.payload;
      const managerIndex = state.managers.findIndex(
        (i) => i.managerId === manager?.managerId
      );
      if (managerIndex !== -1) {
        state.managers[managerIndex] = {
          ...state.managers[managerIndex],
          blacklist: blacklist,
        };
      }

      //state.loading = false;
    });
    builder.addCase(
      blacklistRulesModifiedManager.fulfilled,
      (state, action) => {
        const { blacklist } = action.payload;
        const managerIndex = state.managers.findIndex(
          (i) => i.managerId === blacklist?.managerId
        );

        if (managerIndex !== -1) {
          state.managers[managerIndex] = {
            ...state.managers[managerIndex],
            blacklist: {
              ...state.managers[managerIndex].blacklist,
              rules: blacklist.rules,
            },
          };
        }

        //state.loading = false;
      }
    );
    builder.addCase(blacklistDeleteManagers.fulfilled, (state, action) => {
      const { blacklist } = action.payload;
      const managerIndex = state.managers.findIndex(
        (i) => i.managerId === blacklist?.managerId
      );
      if (managerIndex !== -1) {
        state.managers[managerIndex] = {
          ...state.managers[managerIndex],
          blacklist: {
            ...state.managers[managerIndex].blacklist,
            rules: [],
          },
        };
      }

      //state.loading = false;
    });
  },
});
export const dataReducer = todoReducer.reducer;
export const {
  updateLoading,
  incrementActiveRequests,
  decrementActiveRequests,
  updateLoadingAxios,
  updateIsStrategist,
  updateIsInvestor,
  updateRebalancerFund,
  updateLoadingMsg,
  setReconnecting,
  setShowMarketLoader,
} = todoReducer.actions;
export const getLoading = (state) => state?.todos?.loading;
export const getLoadingMsg = (state) => state?.todos?.loadingmsg;
export const getMarketDataThreshold = (state) =>
  state?.todos?.marketDataThreshold;
export const getLivePrices = (state) => state?.todos?.livePrices;
export const getShowMarketLoader = (state) => state?.todos?.showMarketLoader;

export const getIsLoading = (state) => state?.todos?.isLoading;
export const getReconnectingStatus = (state) => state.todos.isReconnecting;
export const getActiveRequests = (state) => state?.todos?.activeRequests || 0;
