import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Checkbox,
  Typography,
  TableCell,
  TextField,
} from "@mui/material";
import {
  Tablecell,
  calculateAndFormatNoAbs,
  formatValue,
} from "../../../../Methods";
import { InputAdornment } from "@mui/material";

const Target = ({
  totals,
  oldData,
  fundAum,
  editableValue,
  setEditableValue,
  setActiveAum,
}) => {
  const theme = useTheme();
  const [editableData, setEditableData] = useState(totals);
  const [aum, setAum] = useState(editableValue);
  const [myVal, setVal] = useState(true);

  useEffect(() => {
    setEditableData(totals);
    // setAum(formatValue(editableValue, true));
    //  setAum(calculateAndFormatNoAbs(editableValue));
  }, [totals, oldData, fundAum, editableValue]);
  return (
    <>
      <Box
        sx={{
          border: `1px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "50px",
          width: "25%",
          marginRight: 1,
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            height: "50%",
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#E4C0C6",
                  textAlign: "center",
                }}
              >
                Target
              </Tablecell>
            </TableRow>
          </TableBody>

          <TableRow
            sx={{
              backgroundColor: "#B5D0D9",
            }}
          >
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Percent
            </Tablecell>
            <Tablecell
              sx={{
                fontSize: theme.typography.bodyText12.fontSize,
                fontWeight: theme.typography.bodyText12.fontWeight,
                backgroundColor: "#F2F2F2",
                textAlign: "center",
                width: "100px",
              }}
            >
              Amount
            </Tablecell>
          </TableRow>

          <TableBody>
            {editableData?.map((row, index) => (
              <TableRow
                key={index} // Add unique key for each row
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "900",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    paddingRight: "5px",
                  }}
                >
                  {row?.changeValue} {/* Percent */}
                </Tablecell>
                <Tablecell
                  align="right"
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderRight: "none",
                    paddingRight: "5px",
                  }}
                >
                  {row?.changeAllocation} {/* Amount */}
                </Tablecell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <Tablecell
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#E4C0C6",
                  textAlign: "center",
                  py: "2px",
                }}
              >
                <Box sx={{ height: "40px" }}>
                  <Typography
                    sx={{
                      fontSize: theme.typography.bodyText12.fontSize,
                      fontWeight: theme.typography.bodyText12.fontWeight,
                      mt: "5px",
                    }}
                  >
                    Target{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: theme.typography.bodyText12.fontSize,
                      fontWeight: 900,
                    }}
                  >
                    ${calculateAndFormatNoAbs(fundAum)}
                  </Typography>
                </Box>
              </Tablecell>
            </TableRow>
            {/* <TableRow
              sx={{
                backgroundColor: "#B5D0D9",
              }}
            >
              <TableCell
                rowSpan={2}
                colSpan={2}
                sx={{
                  fontSize: theme.typography.bodyText12.fontSize,
                  fontWeight: theme.typography.bodyText12.fontWeight,
                  backgroundColor: "#E4C0C6",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme.typography.bodyText12.fontSize,
                    fontWeight: theme.typography.bodyText12.fontWeight,
                  }}
                >
                  Target
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: "1px",
                      fontSize: "12px",
                      marginBottom: "1px",
                      fontWeight: 900,
                    }}
                  >
                    $
                  </Typography>
                  <TextField
                    value={aum}
                    // onChange={(e) => {
                    //   setEditableValue(e.target.value);
                    // }}
                    size="small"
                    variant="standard"
                    align="center"
                    textAlign="center"
                    sx={{ mb: "1px", textAlign: "center", width: "80px" }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "12px",
                        fontWeight: 900,
                        textAlign: "center",
                      },
                    }}
                  />
                </Box>
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Target;
