import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  TABLE_HEAD_SIGNAL_LOCATES,
  findStrategy,
  findFund,
  TABLE_HEAD_ALLOC,
} from "../../../../Methods";
import SignalsLimitedByLocates from "../../DataEntity/SignalsLimitedByLocates";
import { getSecuritiesWithMarketDataRequest } from "../../../../../utils/helpers";
import RebalancingInfo from "..";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../../../Redux/Reducers/todoReducers";
import { clearRebalancer } from "../../../../../Redux/Actions/todoActions";
import { toast } from "react-toastify";
import Allocated from "../../DataEntity/Allocated";

const SleeveRebalance = ({
  setCurrentStep,
  rebalancedFunds,
  setSelectedRebalancedFunds,
  rebalancedStrategies,
  setRebalancedStrategies,
}) => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [alloc, setAlloc] = useState([]);
  const [selectedValue, setSelectedValue] = useState("all");
  const [selectedFundsId, setSelectedFundsId] = useState(null);
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSignalsTable, setShowSignalsTable] = useState(false);
  const [strategyValObj, setstrategyValObj] = useState([]);
  const [error, setError] = useState("");
  const [marketDataFetched, setMarketDataFetched] = useState(false);

  const {
    accountingData,
    strategies,
    funds,
    marketData,
    lots,
    livePrices,
    markets,
    managers,
  } = useSelector((state) => state?.todos);

  const myFunds = accountingData[0]?.funds;
  const FundList = (funds?.map((i) => i?.abbreviation) || [])?.sort();
  const StrategyList = (strategies?.map((i) => i?.name) || [])?.sort();

  useEffect(() => {}, [livePrices]);

  const handleFetchData = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowSignalsTable(true);
      setMarketDataFetched(true);
    }, 5000);

    if (rebalancedStrategies?.length > 0) {
      const data = getSecuritiesWithMarketDataRequest(
        lots,
        selectedFundsId,
        rebalancedStrategies,
        strategies,
        livePrices
      );
      const tickerMap = new Map();

      data?.securities?.forEach((security) => {
        const symbol = security?.ticker?.symbol?.toLowerCase();

        if (!tickerMap?.has(symbol)) {
          tickerMap?.set(symbol, security);
        } else {
        }
      });

      const uniqueSecurities = Array.from(tickerMap?.values());

      setData(uniqueSecurities);
      if (data?.market_data_request && data.market_data_request.length > 0) {
        data.market_data_request.forEach((request) => {
          const socketRequest = {
            action: "market_data",
            data: request,
          };

          dispatch(incrementActiveRequests());
          window.clientSocks.send(JSON.stringify(socketRequest));
        });
      }
    }
  };

  const setStrategiesToggle = (newValue) => {
    const enrichedStrategyData = newValue?.map((item) => {
      const fullStrategy = strategies?.find(
        (strategy) => strategy.name === item
      );

      return fullStrategy.strategyId;
    });
    setRebalancedStrategies(enrichedStrategyData);
  };

  const resetToBack = () => {
    setShowSignalsTable(false);
    setSelectedRebalancedFunds([]);
    setSelectedFundsId(null);
    setSelectedFunds([]);
    setstrategyValObj([]);
    setRebalancedStrategies([]);
    setError("");
    setMarketDataFetched(false);
    setData([]);
    setAlloc([]);
  };

  const onNext = () => {
    const socketRequest = {
      action: "rebalancer_check_blacklist",
      data: {
        fundIds: selectedFundsId,
        rebalancerType: 0,
        strategies: rebalancedStrategies,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest));
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Checking Blacklist"));
    setCurrentStep((prev) => prev + 1);
  };

  const handleSelectedFundsChange = (event, newValue) => {
    setSelectedFunds(newValue);
    const fundIds = newValue
      ?.map((fundAbbreviation) => {
        const fund = funds?.find((f) => f.abbreviation === fundAbbreviation);
        return fund?.fundId;
      })
      .filter((fundId) => fundId !== undefined);

    setSelectedFundsId(fundIds);
    setSelectedRebalancedFunds(fundIds);
  };
  useEffect(() => {
    if (!selectedFunds || selectedFunds.length === 0) {
      setMarketDataFetched(false);
      setstrategyValObj([]);
      setRebalancedStrategies([]);
      setShowSignalsTable(false);
      setData([]);
      setAlloc([]);
    }
  }, [selectedFunds]);
  const GetAllocation = (selectedFundsId, rebalancedStrategies) => {
    let errorFound = false;
    let updatedAlloc = [];

    selectedFundsId?.forEach((fundId) => {
      const fundData = managers?.[0]?.funds?.[fundId];

      if (fundData?.allocation?.items) {
        rebalancedStrategies?.forEach((strategy) => {
          const strategyData = fundData?.allocation?.items[strategy];

          if (!strategyData) {
            errorFound = true;

            updatedAlloc.push({
              fund: findFund(fundId, funds),
              strategy: findStrategy(strategy, strategies),
              status: "Failed",
              reason: `${findStrategy(
                strategy,
                strategies
              )} not found in fund ${findFund(fundId, funds)}`,
            });
          } else {
            const requiredFields = [
              "account",
              "broker",
              "percentLong",
              "percentShort",
              "totalAllocation",
            ];

            const missingFields = requiredFields.filter(
              (field) =>
                strategyData[field] === undefined ||
                strategyData[field] === null
            );

            if (missingFields?.length > 0) {
              errorFound = true;
              updatedAlloc.push({
                fund: findFund(fundId, funds),
                strategy: findStrategy(strategy, strategies),
                status: "Failed",
                reason: `${findStrategy(strategy, strategies)} ${findFund(
                  fundId,
                  funds
                )} has missing fields: ${missingFields.join(", ")}`,
              });
            }
          }
        });
      } else {
        errorFound = true;
        updatedAlloc.push({
          fund: findFund(fundId, funds),
          strategy: "N/A",
          status: "Failed",
          reason: `Allocation items not found for fund ${findFund(
            fundId,
            funds
          )}`,
        });
      }
    });

    if (errorFound) {
      setError(
        "Allocation is not set correctly for the listed funds and strategies"
      );
    } else {
      setError("");
    }

    setAlloc(updatedAlloc);
  };

  useEffect(() => {
    if (
      managers &&
      managers.length > 0 &&
      selectedFundsId &&
      selectedFundsId.length > 0 &&
      rebalancedStrategies &&
      rebalancedStrategies.length > 0
    ) {
      GetAllocation(selectedFundsId, rebalancedStrategies);
    }
  }, [managers, selectedFunds, selectedFundsId, rebalancedStrategies]);
  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={4}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              padding: "10px",
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                name="radio-buttons-group"
                column
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Rebalance all strategies in a fund"
                  onClick={resetToBack}
                />
                {selectedValue === "all" && (
                  <Grid item xs={12}>
                    <FormControl sx={{ marginLeft: 2, my: 3, width: "75%" }}>
                      <Autocomplete
                        multiple
                        options={FundList}
                        getOptionLabel={(option) => option}
                        onChange={handleSelectedFundsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Fund to Rebalance"
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
                <FormControlLabel
                  value="specific"
                  control={<Radio />}
                  label="Rebalance specific strategies"
                  onClick={resetToBack}
                />
              </RadioGroup>
            </FormControl>

            {selectedValue === "specific" && (
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControl sx={{ my: 2, width: "75%" }}>
                    <Autocomplete
                      multiple
                      options={FundList}
                      getOptionLabel={(option) => option}
                      onChange={handleSelectedFundsChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Fund"
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
            )}

            {selectedFunds &&
              selectedFunds.length > 0 &&
              selectedValue === "specific" && (
                <Grid item xs={12}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <FormControl sx={{ mb: 3, width: "75%" }}>
                      <Autocomplete
                        multiple
                        options={StrategyList}
                        onChange={(e, newValue) => {
                          setStrategiesToggle(newValue);
                          setstrategyValObj(newValue);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Strategies"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </Grid>
              )}

            {selectedFunds && (
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                  }}
                >
                  <RebalancingInfo funds={rebalancedFunds} />
                </div>
              </Grid>
            )}

            {selectedFunds &&
              selectedFunds.length > 0 &&
              (selectedValue === "all" ||
                (selectedValue === "specific" &&
                  strategyValObj?.length > 0)) && (
                <Grid item xs={12}>
                  <FormControl sx={{ mb: 3, width: "75%" }}>
                    <Button
                      variant="contained"
                      onClick={handleFetchData}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Fetch Securities & Signals"
                      )}
                    </Button>
                  </FormControl>
                </Grid>
              )}

            {((selectedValue === "all" && showSignalsTable) ||
              (selectedValue === "specific" &&
                showSignalsTable &&
                strategyValObj?.length > 0)) && (
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    my: 3,
                  }}
                >
                  <SignalsLimitedByLocates
                    markets={markets}
                    dummy={data}
                    addCol={true}
                    tableHeader={TABLE_HEAD_SIGNAL_LOCATES}
                    marketData={livePrices}
                    showPopup={true}
                    strategyListToggle={rebalancedStrategies}
                  />
                </div>
              </Grid>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          {alloc && alloc?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box sx={{ width: "100%", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Allocated
                    alloc={alloc}
                    tableHeader={TABLE_HEAD_ALLOC}
                    error={error}
                  />
                </div>
              </Box>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {selectedFunds &&
            (selectedValue === "all" ||
              (selectedValue === "specific" && strategyValObj?.length > 0)) &&
            marketDataFetched && (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "150px", height: "40px" }}
                >
                  Next
                </Button>
              </Box>
            )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SleeveRebalance;
