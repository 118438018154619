import PortfolioLayout from "../component/Layouts/PortfolioLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Topbar from "../component/NavComponents/Topbar";
import { useDispatch, useSelector } from "react-redux";
import { requestMarketData } from "../Redux/Actions/todoActions";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import { getMarketDataThreshold } from "../Redux/Reducers/todoReducers";

const Portfolio = () => {
  const { fundName, strategyName } = useParams();
  const dispatch = useDispatch();
  const { lots, trades, isStrategist } = useSelector((state) => state?.todos);
  const threshold = useSelector(getMarketDataThreshold);
  // useEffect(() => {
  //   if (lots.length > 0) {
  //     const req = isStrategist ? false : true;
  //     dispatch(requestMarketData({ lots: lots, threshold: threshold, noRequest: req, trades: trades }));
  //   }
  // }, [lots]);

  return (
    <>
      <Helmet>
        <title>Daily Performance</title>
      </Helmet>
      <Topbar title="Daily Performance" />
      <PortfolioLayout fundName={fundName} strategyName={strategyName} />
    </>
  );
};
export default Portfolio;
