import { useEffect, useState } from "react";
import {
  caluclateCHPercentage,
  caluclatePL,
  calculateCh,
  calculateSize,
  calcualteDeltaAdjustedShareCount,
  calculateImpactOptionContracts,
  calculateNotionalShareCountDelta,
} from "../../../Methods/MonitorScreenFormulae.js";

import { useSelector } from "react-redux";
import { findName, calculateTdg } from "../../../Methods/index.js";
import { formatTimeAgo } from "../../../../utils/helpers.js";
import { fetchDeltasForTickers } from "../../../../utils/polygon.js";

const contractUnits = 100;
let dataSource = "Bloomberg";
let fetched = "N/A";

const MonitorScreenCalculation = ({
  selectedFund,
  selectedStrategy,
  selectedSide,
  lots,
  accountingData,
  funds,
  strategies,
  marketData,
  trades,
  isStrategist,
  markets
}) => {
  function searchPrice(symbol, marketDataObject) {
    let deltaObject = fetchDeltasForTickers();

    if (marketDataObject.length > 1) {
      let matchingTicker = marketDataObject.find(
        (tickerItem) => tickerItem.symbol.toUpperCase() === symbol.toUpperCase()
      );

      let c =
        matchingTicker?.price === undefined ? 0 : matchingTicker?.price;
      let l = matchingTicker?.yestPrice === undefined ? 0 : matchingTicker?.yestPrice;

      let d =
        matchingTicker?.expiration === undefined ? fetched : matchingTicker?.expiration;

     

      d = formatTimeAgo(d);

      let e = d.msg;

      let f = d.valid;

      if (c === 0 || l === 0) {
        f= false;
      }


      let matchingDelta = deltaObject.filter(
        (item) => Object.keys(item)[0] === symbol.toUpperCase()
      );

      let delta =
        Math.abs(parseFloat(matchingDelta[0]?.[symbol.toUpperCase()])) || 0.9;
      return [c, l, delta, e, f, matchingTicker?.market];
    } else {
      return [0, 0, 0.9, "N/A", false, "N/A"];
    }
  }

  lots = lots.filter((lot) => lot.ticker.investment == 4);

  const valuesForTable = [];

  var visitedTicker = [];
  let matchingStrategies = [];

  let totalAUM = 10;
  if (isStrategist) {
    matchingStrategies = strategies?.filter(
      (strategy) =>
        strategy.name === selectedStrategy ||
        strategy.name.startsWith(`${selectedStrategy}_`)
    );
    if (matchingStrategies.length > 0) totalAUM = matchingStrategies[0]?.aum;
  } else {
    if (accountingData?.length > 0) {
      if (selectedFund) {
        let matchingObj = accountingData[0].funds.find(
          (obj) => obj.fund == selectedFund
        );

        if (matchingObj) {
          if (
            selectedStrategy !== undefined &&
            selectedStrategy !== "ALL" &&
            selectedStrategy !== ""
          ) {
            if (selectedSide === "Both" || selectedSide === undefined) {
              matchingStrategies = strategies?.filter(
                (strategy) =>
                  strategy.name === selectedStrategy ||
                  strategy.name.startsWith(`${selectedStrategy}_`)
              );
            } else if (selectedSide === "Long" || selectedSide === "Short") {
              matchingStrategies = strategies?.filter((strategy) =>
                strategy.name.includes(`${selectedStrategy}_${selectedSide}`)
              );
            }

            const strategyAUMs = matchingObj?.strategyAUMs || {};

            totalAUM = matchingStrategies.reduce((sum, strategy) => {
              const strategyId = strategy.strategyId;
              return sum + (strategyAUMs[strategyId] || 0);
            }, 0);
          } else {
            totalAUM = matchingObj?.aum;
          }
        } else {
          totalAUM = accountingData[0]?.funds.reduce(
            (total, obj) => total + obj.aum,
            0
          );
        }
      } else {
        totalAUM = accountingData[0]?.funds.reduce(
          (total, obj) => total + obj.aum,
          0
        );
      }

    }
  }

  if (selectedFund)
    lots = lots?.filter((obj) => {
      return obj.fund == selectedFund;
    });

  if (selectedStrategy) {
    const matchingStrategyIds = matchingStrategies?.map(
      (strategy) => strategy?.strategyId
    );

    lots = lots?.filter((obj) => matchingStrategyIds?.includes(obj?.strategy));
  }

  let calculatedData = [];

  if (trades) {
    if (matchingStrategies.length > 0) {
      const str_id = matchingStrategies[0].strategyId;
      calculatedData = calculateTdg(trades, marketData, str_id);
    } else {
      calculatedData = calculateTdg(trades, marketData);
    }
  }

  for (var i = 0; i < lots?.length; i++) {
    if (!visitedTicker.includes(lots[i].ticker.symbol)) {
      if (lots[i].shareCount == 0) continue;

      visitedTicker.push(lots[i].ticker.symbol);

      let livePrices = searchPrice(lots[i].ticker.symbol, marketData);
      let tableFund = "All";
      let tableStrategyId = "All";
      let tableStrategyName = "All";
      if (selectedFund) {
        tableFund = lots[i].fund;
      }
      if (selectedStrategy) {
        tableStrategyId = lots[i].strategy;
        tableStrategyName = findName(lots[i].strategy, strategies);
      }


      let marketName = ""
      const matchedMarket = markets.find(
        (market) => market?.marketId == livePrices[5]
      );

      if (matchedMarket) {
        marketName = matchedMarket?.name;
      }

      var valuesForTableObject = {
        tickerSymbol: lots[i].ticker.stock 
            ? lots[i].ticker.symbol + "/" + lots[i].ticker.stock 
            : lots[i].ticker.symbol,
        tickerInvestment: lots[i].ticker.investment,
        sharePrice: livePrices[0],
        last: livePrices[1],
        delta: livePrices[2],
        size: 0,
        equityShareCount: 0,
        deltaAdjustedShareCount: 0,
        impactOfOptionsContracts: 0,
        ch: 0,
        pL: 0,
        fund: tableFund,
        fundStrategyName: tableStrategyName,
        strategyName: tableStrategyId,
        dataSource: marketName,
        fetched: livePrices[3],
        valid: livePrices[4],
      
        fundName: "",
        custodian: "",
        shareCount: 0,
        Strategy: [],
        chPercetage: 0,
        profitLossWeek: 0,
        profitLossMonth: 0,
        profitLossQuarter: 0,
        profitLossYear: 0,
        cost: 0,
        tdg: 0,
      };

      //LOOP THROUGH ALL LOTS TO GET SECOND OCCURENCE ................
      for (var j = 0; j < lots.length; j++) {
        if (lots[j].ticker.symbol === lots[i].ticker.symbol) {
          if (lots[j].ticker.investment === 9) {
            //PUT = NEGATIVE DELTA  //CALL = POSITIVE DELTA  //HERE WE NEED TO FETCH DELTA
            valuesForTableObject.impactOfOptionsContracts +=
              lots[j].shareCount * contractUnits * valuesForTableObject.delta;
          }
          if (lots[j].ticker.investment === 4) {
            valuesForTableObject.equityShareCount += lots[j].shareCount;
            valuesForTableObject.shareCount += lots[j].shareCount;
            valuesForTableObject.cost += lots[j].cost;
          }

          valuesForTableObject.profitLossWeek += lots[j].profitLossWeek;
          valuesForTableObject.profitLossMonth += lots[j].profitLossMonth;
          valuesForTableObject.profitLossQuarter += lots[j].profitLossQuarter;
          valuesForTableObject.profitLossYear += lots[j].profitLossYear;
          valuesForTableObject.custodian = lots[j]?.account?.custodian;
        }
      }

      valuesForTableObject.deltaAdjustedShareCount =
        calcualteDeltaAdjustedShareCount(
          valuesForTableObject.equityShareCount,
          valuesForTableObject.impactOfOptionsContracts
        );

      valuesForTableObject.size = calculateSize(
        valuesForTableObject.deltaAdjustedShareCount,
        valuesForTableObject.sharePrice,
        totalAUM
      ).toFixed(2);

      valuesForTableObject.ch = calculateCh(
        valuesForTableObject.sharePrice,
        valuesForTableObject.last
      );

      valuesForTableObject.pL = caluclatePL(
        valuesForTableObject.ch,
        valuesForTableObject.equityShareCount
      ).toFixed(1);

      valuesForTableObject.chPercetage = caluclateCHPercentage(
        valuesForTableObject.sharePrice,
        valuesForTableObject.last
      ).toFixed(1);

      let matchingFund = funds?.find(
        (obj) => obj.fundId === valuesForTableObject.fund
      );
      if (matchingFund) {
        valuesForTableObject.fundName = matchingFund.abbreviation;
      } else {
        valuesForTableObject.fundName = "ALL";
      }

      const inTrade = calculatedData.filter((item) => {
        return (
          item.symbol.toLowerCase() ==
          valuesForTableObject.tickerSymbol.toLowerCase()
        );
      });

      if (inTrade.length > 0) {
        valuesForTableObject.tdg = parseFloat(inTrade[0].PnL);

        const totalCost =
          valuesForTableObject.cost +
          parseFloat(inTrade[0].price) * inTrade[0].completedShares;
        const totalShareCount =
          valuesForTableObject.shareCount + inTrade[0].completedShares;
        valuesForTableObject.avgCost = totalCost / totalShareCount;
      } else {
        valuesForTableObject.avgCost =
          valuesForTableObject.cost / valuesForTableObject.shareCount;
      }
      valuesForTable.push(valuesForTableObject);
    }
  }

  if (trades) {
    const newTrades = calculatedData.filter(
      (secondItem) =>
        !valuesForTable.some(
          (firstItem) => firstItem.tickerSymbol === secondItem.symbol
        )
    );

    newTrades.map((item) => {
      let multiplier = 1;
      if (item.position == 1) multiplier = -1;
      const size = calculateSize(
        item.completedShares * multiplier,
        item.currentMarketPrice,
        totalAUM
      ).toFixed(2);

      const change = calculateCh(
        item.currentMarketPrice,
        parseFloat(item.price)
      );

      const profitLoss = caluclatePL(change, item.completedShares).toFixed(1);

      const profitLossPer = caluclateCHPercentage(
        item.currentMarketPrice,
        parseFloat(item.price)
      ).toFixed(1);

      const newPos = {
        size: size,
        sharePrice: item.currentMarketPrice,
        deltaAdjustedShareCount: item.completedShares * multiplier,
        ch: change,
        pL: profitLoss,
        avgCost: parseFloat(item.price),
        last: parseFloat(item.price),
        tickerSymbol: item.symbol,
        chPercentage: profitLossPer,
        fund: "ALL",
        dataSource: "Bloomberg",
        fetched: "Just Now",
        valid: true,
        tdg: parseFloat(item.PnL),
      };

      valuesForTable.push(newPos);
    });
  }

  return valuesForTable;
};

export default MonitorScreenCalculation;
