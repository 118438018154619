import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getComparator,
  TABLE_HEAD_ARCHIVED_REPORTS as TABLE_HEAD,
  Tablecell,
  applySortFilter,
} from "../../Methods";
import { useParams } from "react-router-dom";
import DeletePopUp from "../../Popup/DeletePopUp";
import { useSelector } from "react-redux";
import moment from "moment";
import FundCountEdit from "../../Popup/PopUp/Reports/FundCount/FundCountEdit";
import GoldmanEdit from "../../Popup/PopUp/Reports/Goldman/GoldmanEdit";
import TopwaterEdit from "../../Popup/PopUp/Reports/TopWater/TopwaterEdit";
import CharlesEdit from "../../Popup/PopUp/Reports/Charles/CharlesEdit";

const ArchivedDetailTable = (id) => {
  const theme = useTheme();
  const Id = id?.id?.id;
  const { reports } = useSelector((state) => state.todos);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);
  const [tradesPopup, setTradesOpenPopup] = useState(false);
  const [GoldmanPopup, setGoldmanPopup] = useState(false);
  const [FundCountPopup, setFundCountPopup] = useState(false);
  const [CharlesPopup, setCharlesPopup] = useState(false);
  const [TopwaterPopup, setTopwaterPopup] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  useEffect(() => {
    if (reports) {
      const findReport = reports.find((i) => i.reportId === Id);
      setTitle(findReport?.name);
      const generations = findReport?.generations;

      if (generations && generations.length > 0) {
        const generationsWithName = generations.map((generation) => ({
          ...generation,
          name: findReport?.name,
        }));

        const filteredUsers = applySortFilter(
          generationsWithName,
          getComparator(order, orderBy),
          filterName
        );

        setData(filteredUsers);
      } else {
        setData([]);
        setTitle("");
      }
    }
  }, [order, orderBy, filterName, reports, Id]);

  const openInPopup = (item) => {
    const name = title;
    if (name.toLowerCase().includes("fund count")) {
      setFundCountPopup(true);
    } else if (name.toLowerCase().includes("charles")) {
      setCharlesPopup(true);
    } else if (name.toLowerCase().includes("goldman")) {
      setGoldmanPopup(true);
    } else if (name.toLowerCase().includes("topwater")) {
      setTopwaterPopup(true);
    }
    setRecordForEdit(item);
  };
  const openInDeletePopup = (item) => {
    setrecordForDelete(item);
    setDeleteOpenPopup(true);
  };
  const isNotFound = !data.length && !!filterName;

  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { date, stage } = row;

                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                              ".deleteIcon": {
                                visibility: "visible",
                              },
                            },
                          }}
                        >
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {index + 1 + page * rowsPerPage}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {date ? moment(date).format("MMM DD, YYYY") : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {date ? moment(date).format("hh:mm A") : "-"}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {stage === 0
                              ? "Completed"
                              : stage === 1
                              ? "Processing"
                              : stage === 2
                              ? "Started"
                              : "-"}
                          </Tablecell>

                          <Tablecell
                            align="center"
                            sx={{
                              paddingLeft: "5px",
                              width: "150px",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                fontSize: "9px",
                                fontWeight: "500",
                                visibility: "hidden",
                              }}
                              className="deleteIcon"
                            >
                              <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInPopup(row);
                                }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                              {/* <IconButton
                                sx={{ height: "20px", Minwidth: "10px" }}
                                onClick={() => {
                                  openInDeletePopup(row);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    color: "#808080",
                                    width: "20px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton> */}
                            </Box>
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <DeletePopUp
        title="Delete a Report"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_report"
        item="delete_report"
      />
      <FundCountEdit
        recordForEdit={recordForEdit}
        title={title}
        FundCountPopup={FundCountPopup}
        setFundCountPopup={setFundCountPopup}
      />
      <CharlesEdit
        recordForEdit={recordForEdit}
        title={title}
        setCharlesPopup={setCharlesPopup}
        CharlesPopup={CharlesPopup}
      />
      <GoldmanEdit
        recordForEdit={recordForEdit}
        title={title}
        GoldmanPopup={GoldmanPopup}
        setGoldmanPopup={setGoldmanPopup}
      />
      <TopwaterEdit
        recordForEdit={recordForEdit}
        title={title}
        TopwaterPopup={TopwaterPopup}
        setTopwaterPopup={setTopwaterPopup}
      />
    </>
  );
};
export default ArchivedDetailTable;
