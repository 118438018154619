import moment from "moment";
import { findFund, findStrategy, hashOption } from ".";
//for simple trades Data
export const getBroker = (brokers, lastOrder) => {
  if (brokers && lastOrder?.broker) {
    const name = brokers?.find((a) => a.brokerId === lastOrder?.broker);
    return name ? name.abbreviation : "";
  }
  return "";
};
export const getRouter = (routeList, tradeObj) => {
  if (routeList && routeList.length > 0 && tradeObj?.router) {
    const name = routeList?.find(
      (a) => a.routerId === tradeObj?.router?.router
    );
    return name ? name.abbreviation : "";
  }
  return "";
};
export const getCustodian = (funds, lastOrder, custodians, distribute) => {
  if (funds && funds.length > 0 && lastOrder.distribution) {
    const distribution = distribute;

    if (distribution && Object.keys(distribution).length > 0) {
      const firstDistributionKey = Object.keys(distribution)[0];
      const distributionArray = distribution[firstDistributionKey];

      if (distributionArray) {
        if (distributionArray.length > 1) {
          return "multiple";
        } else if (distributionArray.length === 1) {
          const fundAcc = distributionArray[0].account;
          const fundObj = funds.find((i) => i.fundId === firstDistributionKey);

          if (
            fundObj &&
            fundObj.accounts &&
            Object.keys(fundObj.accounts).length > 0 &&
            fundAcc
          ) {
            const key = fundObj.accounts[fundAcc];

            if (key && key.custodian && custodians) {
              const custodianName = custodians.find(
                (a) => a.custodianId === key.custodian
              );
              return custodianName?.abbreviation || "";
            }
          }
        }
      }
    }
  }
  return "";
};
export const getFund = (funds, distribute) => {
  if (funds && funds.length > 0 && distribute) {
    const firstDistributionKey = Object.keys(distribute)[0];
    const distributionArray = distribute[firstDistributionKey];

    if (distributionArray) {
      const firstFund = distributionArray[0].fund;
      return distributionArray.every((item) => item.fund === firstFund)
        ? firstFund
        : "multiple";
    }
  }
  return "";
};

export const getStrategy = (strategies, distribute) => {
  if (strategies && strategies.length > 0 && distribute) {
    const firstDistributionKey = Object.keys(distribute)[0];
    const distributionArray = distribute[firstDistributionKey];

    if (distributionArray) {
      const firstStrategy = distributionArray[0].strategy;
      return distributionArray.every((item) => item.strategy === firstStrategy)
        ? firstStrategy
        : "multiple";
    }
  }
  return "";
};
export const getCommission = (tradeObj) => {
  return tradeObj?.commission?.commission || "";
};

export const hashTicker = (ticker, TradeInvestment) => {
  if (ticker?.investment == TradeInvestment.Options) {
    const formattedExpiration = moment(ticker?.expirationDate)?.format(
      "MMM/DD"
    );
    return `${ticker?.symbol} ${formattedExpiration} ${
      ticker?.strikePrice
    } ${hashOption(ticker?.option)}`;
  }
  return ticker?.symbol;
};

export const calculateFill = (distribute, tradeObj) => {
  if (!distribute || Object.keys(distribute).length === 0) {
    return {};
  }

  const totalShareCount = Object.keys(distribute).reduce((sum, key) => {
    return (
      sum + distribute[key].reduce((acc, entry) => acc + entry.shareCount, 0)
    );
  }, 0);

  let fillSource;
  if (tradeObj.manuals && tradeObj.manuals.length > 0) {
    fillSource = tradeObj.manuals.slice(-1)[0];
  } else if (tradeObj.fill && Object.keys(tradeObj.fill).length > 0) {
    fillSource = tradeObj.fill;
  } else {
    return distribute;
  }

  if (!fillSource || !fillSource.shareCount) {
    return distribute;
  }

  const fillShareCount = fillSource.shareCount;

  let allEntries = [];
  Object.keys(distribute).forEach((key) => {
    allEntries = allEntries.concat(distribute[key]);
  });

  let updatedEntries;
  if (fillShareCount <= 5) {
    let remainingFill = fillShareCount;
    updatedEntries = allEntries.map((entry) => {
      if (remainingFill >= entry.shareCount) {
        remainingFill -= entry.shareCount;
        return {
          ...entry,
          completed: entry.shareCount,
          working: 0,
        };
      } else if (remainingFill > 0) {
        const completed = Math.min(remainingFill, entry.shareCount);
        remainingFill -= completed;
        return {
          ...entry,
          completed,
          working: entry.shareCount - completed,
        };
      } else {
        return {
          ...entry,
          completed: 0,
          working: entry.shareCount,
        };
      }
    });
  } else {
    let calculatedFillSum = 0;
    updatedEntries = allEntries.map((entry, i) => {
      const completed = Math.floor(
        (entry.shareCount / totalShareCount) * fillShareCount
      );

      if (i === allEntries.length - 1) {
        const adjustedFill = fillShareCount - calculatedFillSum;
        return {
          ...entry,
          completed: adjustedFill,
          working: entry?.shareCount - adjustedFill,
        };
      } else {
        calculatedFillSum += completed;
        return { ...entry, completed, working: entry?.shareCount - completed };
      }
    });
  }

  const updatedDistribute = {};
  let entryIndex = 0;
  Object.keys(distribute).forEach((key) => {
    updatedDistribute[key] = updatedEntries.slice(
      entryIndex,
      entryIndex + distribute[key].length
    );
    entryIndex += distribute[key].length;
  });

  return updatedDistribute;
};

//for Historical trades data

export const getFundFromTradeObj = (funds, tradeObj) => {
  return funds?.find((a) => a.fundId === tradeObj?.fund)?.abbreviation || "";
};
export const getStrategyFromTradeObj = (strategies, tradeObj) => {
  return (
    strategies?.find((a) => a.strategyId === tradeObj?.strategy)?.name || ""
  );
};

export const getCustodianFromTradeObj = (custodians, tradeObj) => {
  return (
    custodians?.find((a) => a.custodianId === tradeObj?.custodian)?.name || ""
  );
};
// for composite keys in rebalancer step 2
export const showComposite = (compositeKey, strategies, funds) => {
  if (!compositeKey || !strategies || !funds) {
    return "Invalid Input: Missing compositeKey, strategies, or funds";
  }

  const [strategyId, fundId] = compositeKey.split("#");

  if (!strategyId || !fundId) {
    return "Invalid compositeKey format.  Should be 'strategyId#fundId'";
  }

  const strategy = findStrategy(strategyId, strategies);
  const fund = findFund(fundId, funds);

  if (!strategy) {
    return `Strategy not found for ID: ${strategyId}`;
  }

  if (!fund) {
    return `Fund not found for ID: ${fundId}`;
  }

  return `${strategy} - ${fund}`;
};
