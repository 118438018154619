import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  TableSortLabel,
  Typography,
} from "@mui/material";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

const SignalList = ({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  hideColumn,
  noStatus,
  title,
  customizeFont,
  noArrow,
  colspan
}) => {
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const adjustedHeadLabel = headLabel;

  return (
    <TableHead>
      <TableRow sx={{ background: theme.palette.grey[700] }}>
        {!noStatus ? (
          <TableCell
            padding="checkbox"
            align="center"
            sx={{
              color: theme.palette.grey[100],
              background: "inherit",
              fontSize: "11px",
            }}
          >
            #
          </TableCell>
        ) : null}

        {adjustedHeadLabel?.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              title={title ? headCell.title : ""}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                color: theme.palette.lightGrey.textColor,
                background: "#d9dcdf",
                fontSize: "11px",
                marginLeft:
                  headCell.id === "idealweights" ||
                  headCell.id === "currentweight"
                    ? "180px"
                    : "",

                height:
                  headCell.id === "tickers"
                    ? "41px"
                    : headCell.id === "weights"
                    ? "41px"
                    : "",
                width:
                  headCell.id === "tick"
                    ? "500px"
                    : headCell.id === "tickers"
                    ? "50px"
                    : headCell.id === "weights"
                    ? "40px"
                    : headCell.id === "idealweights"
                    ? "70px"
                    : headCell.id === "currentweight"
                    ? "70px"
                    : headCell.id === "variance"
                    ? "70px"
                    : headCell.id === "sharecounts"
                    ? "70px"
                    : headCell.id === "sno"
                    ? "25px"
                    : headCell.id === "long_current"
                    ? "60px"
                    : headCell.id === "long_ch"
                    ? "50px"
                    : headCell.id === "long_pL"
                    ? "60px"
                    : headCell.id === "long_chpercentage"
                    ? "55px"
                    : headCell.id === "long_optn"
                    ? "70px"
                    : headCell.id === "long_tgt"
                    ? "70px"
                    : headCell.id === "short_size"
                    ? "50px"
                    : headCell.id === "short_deltaadjustedsharecount"
                    ? "50px"
                    : headCell.id === "short_tickerSymbol"
                    ? "60px"
                    : headCell.id === "short_last"
                    ? "60px"
                    : headCell.id === "short_current"
                    ? "60px"
                    : headCell.id === "short_ch"
                    ? "50px"
                    : headCell.id === "short_pL"
                    ? "60px"
                    : headCell.id === "short_chpercentage"
                    ? "55px"
                    : headCell.id === "short_optn"
                    ? "70px"
                    : headCell.id === "short_tgt"
                    ? "70px"
                    : headCell.id === "pL"
                    ? "70px"
                    : headCell.id === "plShort"
                    ? "70px"
                    : headCell.id === "plLong"
                    ? "70px"
                    : headCell.id === "sizeLong"
                    ? "70px"
                    : headCell.id === "sizeShort"
                    ? "70px"
                    : headCell.id === "vantagelongCapital"
                    ? "85px"
                    : headCell.id === "vantageshortCapital"
                    ? "85px"
                    : headCell.id === "vantageslongPerformance"
                    ? "65px"
                    : headCell.id === "vantageshortPerformance"
                    ? "65px"
                    : headCell.id === "vantagePercent"
                    ? "65px"
                    : headCell.id === "strategyName"
                    ? "150px"
                    : headCell.id === "fundName"
                    ? "150px"
                    : headCell.id === "aum"
                    ? "90px"
                    : headCell.id == "symbol"
                    ? "30px"
                    : headCell.id == "marketCap"
                    ? "90px"
                    : "",
                padding:
                  headCell.id === "idealweights" ||
                  headCell.id === "currentweight"
                    ? "1px"
                    : "2px",
                paddinfLeft: headCell.id === "sno" ? "20px" : "",
                borderLeft: "0.1px solid #ccc3c3",
                borderRight: "0.1px solid #ccc3c3",
                alignItems: "center",
                "&:hover": {
                  background: theme.palette.lightGrey.tableHeader,
                  cursor: "pointer",
                },
              }}
            >
              {noArrow ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black !important",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {headCell.label}
                  </Typography>
                </Box>
              ) : (
                <TableSortLabel
                  // active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black !important",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {headCell.label}
                    </Typography>
                  </Box>
                </TableSortLabel>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default SignalList;
