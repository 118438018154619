import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  REBALANCER_SUMMARY as summaryHeader,
  TABLE_CROSS_TRADE as tableHeader,
} from "../../../../../Methods";

import { useDispatch, useSelector } from "react-redux";
import CrossingTradesTable from "./components/CrossingTradesTable";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../../../../Redux/Reducers/todoReducers";
import SummaryTable from "./components/SummaryTable";

const FundRebalanceStep7 = ({ rebalancedFunds }) => {
  const { AllTrades, finalCrossedTrades } = useSelector((state) => state.todos);

  const dispatch = useDispatch();

  const onNext = () => {
    const socketRequest = {
      action: "finalize_rebalancer_orders",
      data: {
        fundIds: rebalancedFunds,
        processId: AllTrades?.processId,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest));
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "35%",
              }}
            >
              <Box
                sx={{
                  marginBottom: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold !important",
                    fontSize: "20px !important",
                  }}
                >
                  Final Trades
                </Typography>
              </Box>
            </div>
            {finalCrossedTrades && finalCrossedTrades.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                  sx={{ width: "200px", height: "40px" }}
                >
                  Send To Blotter
                </Button>
              </Box>
            ) : null}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {finalCrossedTrades ? (
              <>
                <CrossingTradesTable
                  crossingTrades={finalCrossedTrades}
                  tableHeader={tableHeader}
                />
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "90%" }}>
                  <SummaryTable tableHeader={summaryHeader} />
                </div>
              </>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FundRebalanceStep7;
