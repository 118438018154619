import moment from "moment/moment";
import CircleIcon from "@mui/icons-material/Circle";
import { calculateAndFormat, formatValue } from ".";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

//Trades Table Columns

export const TradesTable = (
  theme,
  initial,
  openInDeletePopup,
  openTradeInPopup,
  openInmultiplePopup
) => [
  {
    field: String("actions"),
    headerName: String("Actions"),
    width: 70,
    minWidth: 10,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (params) => (
      <>
        {!initial ? (
          <div></div>
        ) : (
          <>
            <div style={{ paddingRight: 2 }}>
              {params?.row?.process ? (
                <IconButton
                  sx={{ height: "10px", width: "10px", padding: "10px" }}
                  onClick={() => {
                    openInmultiplePopup(params?.row?.tradeObject, params?.row);
                  }}
                >
                  <ContentCopyIcon
                    sx={{
                      color: "#808080",
                      width: "15px !important",
                      height: "15px !important",
                    }}
                  />
                </IconButton>
              ) : (
                <>
                  <IconButton
                    sx={{ height: "10px", width: "10px", padding: "10px" }}
                    onClick={() => {
                      openInDeletePopup(params?.row?.tradeObject);
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        color: "#808080",
                        width: "15px !important",
                        height: "15px !important",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{ height: "10px", width: "10px", padding: "10px" }}
                    onClick={() => {
                      openTradeInPopup(params?.row?.tradeObject);
                    }}
                  >
                    <EditIcon
                      sx={{
                        color: "#808080",
                        width: "15px !important",
                        height: "15px !important",
                      }}
                    />
                  </IconButton>
                </>
              )}
            </div>
          </>
        )}
      </>
    ),
  },

  {
    field: String("parent"),
    width: 60,
    disableColumnMenu: true,
    minWidth: 30,
    filterable: true,
    headerName: String("Parent"),
    alignLeft: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("fundStatus"),
    headerName: String("Status"),
    disableColumnMenu: true,
    sortable: false,
    width: 60,
    alignCenter: true,
    isActive: true,
    filterable: true,
    renderCell: (params) => (
      <>
        <CircleIcon
          style={{
            color: params ? "green" : "red",
            width: "10px",
            height: "10px",
          }}
        />
      </>
    ),
  },

  {
    field: String("id"),
    width: 40,
    disableColumnMenu: true,
    minWidth: 30,
    headerName: String("#"),
    alignLeft: true,
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("TradeTime"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Trade Time"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params ? moment(params?.value).format("hh:mm:ss") : ""}
      </div>
    ),
  },

  {
    field: String("TradeType"),
    disableColumnMenu: true,
    width: 88,
    headerName: String("Trade Type"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value?.toLowerCase() === "buyshort"
          ? "CoverShort"
          : params?.value}
      </div>
    ),
  },

  {
    field: String("Pcm"),
    width: 70,
    disableColumnMenu: true,
    headerName: String("Ticker"),
    filterable: true,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "left",
          paddingLeft: "5px",
          paddingLeft: "8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: String("ordered"),
    width: 75,
    disableColumnMenu: true,
    headerName: String("Ordered"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: String("working"),
    width: 75,
    disableColumnMenu: true,
    headerName: String("Working"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: String("tradefills"),
    width: 10,
    disableColumnMenu: true,
    headerName: String("A/M"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("strategy"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("Strategy"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("completed"),
    width: 85,
    disableColumnMenu: true,
    headerName: String("Completed"),
    filterable: true,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: String("price"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Price/Share"),
    filterable: true,
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: String("comm"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Comm/Share"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: String("brokerId"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("Broker ID"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("Ins"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Instructions"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value?.slice(0, 10)}
      </div>
    ),
  },

  {
    field: String("ordertype"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Order type"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("routing"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Order Routed"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("currency"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("Currency"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("prime"),
    width: 95,
    disableColumnMenu: true,
    headerName: String("Prime Broker"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("InvestmentType"),
    width: 120,
    disableColumnMenu: true,
    headerName: String("Investment Type"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("PositionType"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Position Type"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("client"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Client ID"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("expiration"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Expiration"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("strikeprice"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Strike Price"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("putsorcall"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Puts or calls"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("sec"),
    width: 75,
    disableColumnMenu: true,
    headerName: String("SEC Fee"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: String("occ"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("OCC Fee"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: String("orf"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("ORF Fee"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
];
//Deleted Trades Table columns
export const DeletedTradescolumns = (theme) => [
  {
    field: String("id"),
    width: 40,
    disableColumnMenu: true,
    minWidth: 30,
    headerName: String("#"),
    alignLeft: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("TradeTime"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Trade Time"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params ? moment(params?.value).format("hh:mm:ss") : ""}
      </div>
    ),
  },

  {
    field: String("TradeType"),
    disableColumnMenu: true,
    width: 88,
    headerName: String("Trade Type"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("Pcm"),
    width: 70,
    disableColumnMenu: true,
    headerName: String("Ticker"),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "left",
          paddingLeft: "5px",
          paddingLeft: "8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: String("ordered"),
    width: 75,
    disableColumnMenu: true,
    headerName: String("Ordered"),
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: String("working"),
    width: 75,
    disableColumnMenu: true,
    headerName: String("Working"),
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },
  {
    field: String("tradefills"),
    width: 10,
    disableColumnMenu: true,
    headerName: String("A/M"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("strategy"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("Strategy"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("completed"),
    width: 85,
    disableColumnMenu: true,
    headerName: String("Completed"),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: String("price"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Price/Share"),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "right",
          width: "100%",
          color: params?.value ? theme.palette.primary.dark : "#000",
        }}
      >
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: String("comm"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Comm/Share"),
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>
        {formatValue(params?.value)}
      </div>
    ),
  },
  {
    field: String("brokerId"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("Broker ID"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("Ins"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Instructions"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value?.slice(0, 10)}
      </div>
    ),
  },

  {
    field: String("ordertype"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Order type"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("routing"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Order Routed"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("currency"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("Currency"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("prime"),
    width: 95,
    disableColumnMenu: true,
    headerName: String("Prime Broker"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("InvestmentType"),
    width: 120,
    disableColumnMenu: true,
    headerName: String("Investment Type"),
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },
  {
    field: String("PositionType"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Position Type"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("client"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Client ID"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("expiration"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Expiration"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("strikeprice"),
    width: 90,
    disableColumnMenu: true,
    headerName: String("Strike Price"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "right", paddingRight: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("putsorcall"),
    width: 100,
    disableColumnMenu: true,
    headerName: String("Puts or calls"),
    filterable: true,
    renderCell: (params) => (
      <div style={{ textAlign: "left", paddingLeft: "5px" }}>
        {params?.value}
      </div>
    ),
  },

  {
    field: String("sec"),
    width: 75,
    disableColumnMenu: true,
    headerName: String("SEC Fee"),
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: String("occ"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("OCC Fee"),
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
  {
    field: String("orf"),
    width: 80,
    disableColumnMenu: true,
    headerName: String("ORF Fee"),
    renderCell: (params) => (
      <div style={{ textAlign: "right", width: "100%" }}>{params?.value}</div>
    ),
  },
];
//Child Trades Table Columns

export const childTradesColumns = (theme, openInDeletePopup) => [
  {
    field: "actions",
    headerName: "Actions",
    width: 60,
    minWidth: 10,
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (params) => (
      <>
        <div style={{ paddingRight: 2 }}>
          <IconButton
            sx={{ height: "10px", width: "10px", padding: "10px" }}
            onClick={() => {
              openInDeletePopup(params?.row);
            }}
          >
            <DeleteIcon
              sx={{
                color: "#808080",
                width: "15px !important",
                height: "15px !important",
              }}
            />
          </IconButton>
        </div>
      </>
    ),
  },

  {
    field: "child",
    headerName: "Child",
    width: 30,
    disableColumnMenu: true,
  },

  {
    field: "id",
    headerName: "#",
    width: 30,
    disableColumnMenu: true,
  },

  {
    field: "shareCount",
    headerName: "Ordered",
    width: 90,
    disableColumnMenu: true,

    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        {calculateAndFormat(params?.value)}
      </div>
    ),
  },

  {
    field: "working",
    headerName: "Working",
    width: 90,
    disableColumnMenu: true,

    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        {params?.value}
      </div>
    ),
  },
  {
    field: "completed",
    headerName: "Completed",
    width: 120,
    disableColumnMenu: true,

    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        {" "}
        {params?.value}
      </div>
    ),
  },
  {
    field: "account",
    headerName: "Account",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "broker",
    headerName: "Broker",
    width: 100,
    disableColumnMenu: true,
  },

  {
    field: "fund",
    headerName: "Fund",
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: "strategy",
    headerName: "Strategy",
    width: 100,
    disableColumnMenu: true,
  },
];
