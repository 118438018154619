import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  useTheme,
  TextField,
  Checkbox,
  Box,
} from "@mui/material";
import ListToolbar from "./ListToolbar";
import ListHead from "../../ListHead";
import { useSelector } from "react-redux";
import Popup from "../../Popup";

import {
  getComparator,
  TABLE_HEAD_SECURITY_MASTERS as TABLE_HEAD,
  Tablecell,
  applySortFilter,
  hashExchangeEnum,
  hashInvestment,
  hashCurrencyEnum,
} from "../../Methods";
import DeletePopUp from "../../Popup/DeletePopUp";

const SecurityMasterTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("tickerSymbol");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [hasChanges, setHasChanges] = useState(false);
  const [updatedRows, setUpdatedRows] = useState([]);
  const [recordForDelete, setrecordForDelete] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePopup, setDeleteOpenPopup] = useState(false);
  const [bulkDelete, setShowBulkDelete] = useState(false);
  const { securities, markets } = useSelector((state) => state?.todos);
  const [editCell, setEditCell] = useState({ id: null, field: null });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  useEffect(() => {
    if (
      !securities ||
      securities.length === 0 ||
      !markets ||
      markets.length === 0
    ) {
      setData([]);
      return;
    }

    const updatedSecurities = securities?.map((security) => {
      if (
        !security ||
        !security.markets ||
        Object.keys(security?.markets).length === 0
      ) {
        return { ...security };
      }

      const tickerSymbol = security.ticker?.symbol || "";
      const exchange = security?.ticker?.exchange;
      const investment = security?.ticker?.investment;
      const currency = security?.ticker?.currency;
      const updatedSecurity = {
        ...security,
        check: false,
        tickerSymbol: tickerSymbol,
        exchange: hashExchangeEnum(exchange),
        investment: hashInvestment(investment),
        currency: hashCurrencyEnum(currency),
      };
      const marketSecurity = security?.markets || {};

      Object.keys(marketSecurity).forEach((marketId) => {
        const externalMarket = markets.find(
          (market) => market.marketId === marketId
        );
        if (externalMarket) {
          updatedSecurity[externalMarket?.name?.toLowerCase()] =
            marketSecurity[marketId];
        }
      });

      return updatedSecurity;
    });

    const filteredUsers = applySortFilter(
      updatedSecurities,
      getComparator(order, orderBy),
      filterName
    );
    setData(filteredUsers);
  }, [order, orderBy, filterName, securities, markets]);

  const handleEditRow = (id, field, value) => {
    setData((prevData) => {
      if (!prevData) return prevData;

      const updatedData = prevData.map((row) => {
        if (row.tickerId === id) {
          const updatedRow = { ...row, [field]: value };
          const isUpdated = row[field] !== value;
          if (isUpdated) {
            setUpdatedRows((prev) => {
              const index = prev.findIndex(
                (updatedRow) => updatedRow.tickerId === row.tickerId
              );
              if (index === -1) {
                return [...prev, updatedRow];
              } else {
                const updatedRows = [...prev];
                updatedRows[index] = updatedRow;
                return updatedRows;
              }
            });
          }

          return updatedRow;
        }
        return row;
      });

      setHasChanges(true);
      return updatedData;
    });
  };
  const handleCheck = (id, field, value) => {
    setData((prevData) => {
      if (!prevData) return prevData;

      const updatedData = prevData.map((row) => {
        if (row.tickerId === id) {
          let updatedRow = { ...row };

          const isChecked = !row[field];
          updatedRow[field] = isChecked;

          return updatedRow;
        }
        return row;
      });
      const anyChecked = updatedData.some((row) => row.check === true);
      setShowBulkDelete(anyChecked);

      return updatedData;
    });
  };

  const isNotFound = !data.length && !!filterName;

  const handleCellClick = (id, field) => {
    setEditCell({ id, field });
  };

  const handleCellBlur = () => {
    setEditCell({ id: null, field: null });
  };
  return (
    <>
      <Container maxWidth="70%" sx={{ marginTop: 1, marginBottom: 3 }}>
        <Card>
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            hasChanges={hasChanges}
            setHasChanges={setHasChanges}
            bulkDelete={bulkDelete}
            setShowBulkDelete={setShowBulkDelete}
            markets={markets}
            setData={setData}
            updatedRows={updatedRows}
            setUpdatedRows={setUpdatedRows}
            data={data}
          />
          <TableContainer style={{ height: "62vh" }}>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              sx={{
                height: "max-content",
              }}
            >
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                bulkDelete={bulkDelete}
                setShowBulkDelete={setShowBulkDelete}
                data={data}
                setData={setData}
              />

              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{
                            "&:hover": {
                              background: theme.palette.grey[200],
                              cursor: "pointer",
                              ".deleteIcon": {
                                visibility: "visible",
                              },
                            },
                          }}
                        >
                          <Tablecell
                            align="left"
                            sx={{
                              paddingLeft: "5px",
                            }}
                          >
                            {index + 1 + page * rowsPerPage}{" "}
                          </Tablecell>
                          <Tablecell
                            align="center"
                            sx={{
                              "& .MuiCheckbox-root": { padding: "0px" },
                            }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                handleCheck(
                                  row?.tickerId,
                                  "check",
                                  e.target.value
                                )
                              }
                              checked={row?.check}
                              size="small"
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 18 },
                              }}
                            />
                          </Tablecell>

                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "name")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "name" ? (
                              <TextField
                                value={row?.name}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "name",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "300px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.name
                            )}
                          </Tablecell>
                          <Tablecell align="left" sx={{ paddingLeft: "5px" }}>
                            {row?.tickerSymbol}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "cusip")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "cusip" ? (
                              <TextField
                                value={row?.cusip}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "cusip",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.cusip
                            )}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "sedol")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "sedol" ? (
                              <TextField
                                value={row?.sedol}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "sedol",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.sedol
                            )}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "isin")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "isin" ? (
                              <TextField
                                value={row?.isin}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "isin",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "120px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.isin
                            )}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "figi")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "figi" ? (
                              <TextField
                                value={row?.figi}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "figi",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "120px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.figi
                            )}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "bloomberg")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "bloomberg" ? (
                              <TextField
                                value={row?.bloomberg}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "bloomberg",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.bloomberg
                            )}
                          </Tablecell>
                          <Tablecell
                            align="left"
                            sx={{ paddingLeft: "5px" }}
                            onClick={() =>
                              handleCellClick(row?.tickerId, "factset")
                            }
                          >
                            {editCell.id === row?.tickerId &&
                            editCell.field === "factset" ? (
                              <TextField
                                value={row?.factset}
                                onChange={(e) =>
                                  handleEditRow(
                                    row?.tickerId,
                                    "factset",
                                    e.target.value
                                  )
                                }
                                onBlur={handleCellBlur}
                                size="small"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    fontSize: "12px",
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    width: "100px",
                                  },
                                }}
                                autoFocus
                              />
                            ) : (
                              row?.factset
                            )}
                          </Tablecell>
                        </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[100, 500, 1000]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <DeletePopUp
        title="Delete a Security Map"
        openDeletePopup={openDeletePopup}
        setDeleteOpenPopup={setDeleteOpenPopup}
        recordForDelete={recordForDelete}
        deleteObj="delete_security_map"
        item="security_map"
      />

      {/* <Popup
        title="Edit Security Map"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EditSecurity
          recordForEdit={recordForEdit}
          setOpenPopup={setOpenPopup}
        />
      </Popup> */}
    </>
  );
};
export default SecurityMasterTable;
