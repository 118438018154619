import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Grid,
  InputAdornment,
  Select,
  Box,
  IconButton,
  useTheme,
  Typography,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  criteriaOptions,
  findStrategyById,
  hashGetBlacklistCritera,
  hashGetBlacklistType,
  operationOptions,
  unitOptions,
} from "../../Methods";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../Redux/Reducers/todoReducers";
import { useDispatch } from "react-redux";

const EditRuleDialog = ({
  open,
  editedRule,
  strategies,
  onClose,
  onSave,
  recordForBlacklist,
  BlacklistData,
  setBlacklistOpenPopup,
  title,
}) => {
  const dispatch = useDispatch();
  const [strategy, setStrategy] = useState("All");
  const [tickers, setTickers] = useState([]);
  const [isRuleAdded, setIsRuleAdded] = useState(false);
  const [myrule, setMyrule] = useState({
    criteria: "",
    operation: "",
    value: "",
    unit: "",
  });
  const sectorOptions = [
    "Information Technology",
    "Industrials",
    "Financials",
    "Communication Services",
    "Real Estate",
    "Energy",
    "Consumer Discretionary",
    "Materials",
    "Health Care",
    "Utilities",
    "Consumer Staples",
  ];

  const updatedStrategies = strategies ? [...strategies] : [];
  if (!updatedStrategies.some((strategy) => strategy.name === "All")) {
    updatedStrategies.unshift({ id: "all", name: "All" });
  }

  useEffect(() => {
    if (editedRule) {
      const { type, maximum, minimum, ticker, strategy } = editedRule;
      let operation = null;
      let value = null;

      if (minimum !== undefined && maximum !== undefined) {
        operation = "=";
        value = minimum;
      } else if (maximum !== undefined) {
        operation = ">";
        value = maximum;
      } else if (minimum !== undefined) {
        operation = "<";
        value = minimum;
      }

      setStrategy(editedRule?.strategy?.name || "All");
      setTickers(
        editedRule?.tickers?.length > 0 ? editedRule.tickers : ["All"]
      );
      setMyrule({
        criteria: hashGetBlacklistCritera(type),
        operation,
        value,
        unit: editedRule.unit || "",
      });
      setIsRuleAdded(true);
    } else {
      setStrategy("All");
      setTickers(["All"]);
      setMyrule({
        criteria: "",
        operation: "",
        value: "",
        unit: "",
      });
      setIsRuleAdded(false);
    }
  }, [editedRule]);

  const handleStrategyChange = (event) => {
    setStrategy(event.target.value);
  };

  const handleTickerChange = (event) => {
    const newTickers = event.target.value
      .split(",")
      .map((ticker) => ticker.trim());
    setTickers(newTickers);
  };
  const handleRuleChange = (field, value) => {
    if (field === "criteria") {
      if (value === "Sector") {
        setMyrule((prevMyrule) => ({
          ...prevMyrule,
          operation: "=",
          value: [],
          [field]: value,
        }));
      } else {
        setMyrule((prevMyrule) => ({
          ...prevMyrule,
          [field]: value,
          operation: "",
          value: "",
        }));
      }
    } else {
      setMyrule((prevMyrule) => ({
        ...prevMyrule,
        [field]: value,
      }));
    }
  };

  const handleSave = () => {
    const { criteria, operation, value } = myrule;
    const ruleObj = {
      type: hashGetBlacklistType(criteria),
    };

    const normalizedValue = Number(value);
    if (ruleObj.type === 5) {
      ruleObj.sectors = value;
    } else {
      if (operation === "<") {
        ruleObj.minimum = normalizedValue;
      } else if (operation === ">") {
        ruleObj.maximum = normalizedValue;
      } else if (operation === "=") {
        ruleObj.maximum = normalizedValue;
        ruleObj.minimum = normalizedValue;
      }
    }

    const updatedRule = tickers.includes("All")
      ? [
          {
            ...(strategy !== "All" && {
              strategy: findStrategyById(strategy, strategies),
            }),
            ...ruleObj,
          },
        ]
      : tickers.map((ticker) => ({
          ...(strategy !== "All" && {
            strategy: findStrategyById(strategy, strategies),
          }),
          ticker: ticker,
          ...ruleObj,
        }));

    const isModification =
      BlacklistData?.rules && BlacklistData.rules?.length > 0 ? true : false;

    const data = {
      action: isModification ? "modify_blacklist" : "create_blacklist_rule",
      data: {
        rules: isModification
          ? {
              add: [],
              modify: [],
              remove: [],
            }
          : [],
      },
    };

    if (isModification && editedRule) {
      data.data.rules.modify.push(...updatedRule);
      data.data.fundId = recordForBlacklist;

      // Check for removed tickers
      const previousTickers = editedRule.tickers || [];
      const removedTickers = previousTickers.filter(
        (ticker) => !tickers.includes(ticker)
      );
      if (previousTickers.length == 0) {
        data.data.rules.remove.push({
          ...(strategy !== "All" && {
            strategy: findStrategyById(strategy, strategies),
          }),
          ...ruleObj,
        });
      }
      removedTickers.forEach((ticker) => {
        data.data.rules.remove.push({
          ...(strategy !== "All" && {
            strategy: findStrategyById(strategy, strategies),
          }),
          ticker: ticker,
          ...ruleObj,
        });
      });
    } else if (isModification && !editedRule) {
      data.data.rules.add.push(...updatedRule);
      data.data.fundId = recordForBlacklist;
    } else {
      data.data.rules.push(...updatedRule);
      data.data.fundBlacklist = true;
      data.data.fund = recordForBlacklist;
    }
    window.clientSocks.send(JSON.stringify(data));
    setBlacklistOpenPopup(false);
    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Updating Blacklist"));
  };

  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "1000px",
          height: "100vh",
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h6"
          component="div"
          align="center"
          style={{ flexGrow: 1, fontSize: "20px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" sx={{ fontSize: "0.875rem" }}>
          Select Strategy
        </Typography>
        <FormControl fullWidth size="small" variant="outlined">
          <Select
            value={strategy}
            onChange={handleStrategyChange}
            name="strategy"
            sx={{ height: "50px" }}
          >
            {updatedStrategies?.map((strategyItem) => (
              <MenuItem key={strategyItem.id} value={strategyItem.name}>
                {strategyItem.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography
          variant="subtitle1"
          sx={{ marginTop: 2, fontSize: "0.875rem" }}
        >
          Enter Tickers (comma separated)
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={tickers}
          onChange={handleTickerChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Typography
          variant="subtitle1"
          sx={{ marginTop: 2, fontSize: "0.875rem" }}
        >
          Criteria
        </Typography>

        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <Select
              fullWidth
              size="small"
              value={myrule?.criteria || ""}
              onChange={(e) => handleRuleChange("criteria", e.target.value)}
              displayEmpty
            >
              {criteriaOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              value={myrule?.operation || ""}
              onChange={(e) => handleRuleChange("operation", e.target.value)}
              disabled={
                !myrule?.criteria ||
                myrule?.criteria === "NotAllowed" ||
                myrule?.criteria === "Sector"
              }
            >
              {operationOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {myrule.criteria === "Sector" ? (
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="sector-autocomplete"
                options={sectorOptions}
                value={myrule.value || []}
                fullWidth
                onChange={(event, newValue) =>
                  handleRuleChange("value", newValue)
                }
                disabled={!myrule?.criteria}
                renderInput={(params) => (
                  <TextField {...params} label="Select Sectors" />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <TextField
                fullWidth
                size="small"
                value={myrule.value}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    handleRuleChange("value", value);
                  }
                }}
                disabled={
                  !myrule?.criteria || myrule?.criteria === "NotAllowed"
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {myrule.unit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: "20px",
            float: "right",
            marginRight: "10px",
            marginBottom: "20px",
            padding: "8px 30px",
          }}
          onClick={handleSave}
          disabled={
            !myrule.criteria ||
            (myrule.criteria !== "NotAllowed" &&
              (!myrule.operation ||
                (myrule.criteria !== "Sector" && !myrule.value))) ||
            (myrule.criteria === "Sector" && myrule.value.length === 0)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRuleDialog;
