import React from "react";
import ResetUserPassword from "../component/UserPassword/ResetUserPassword";
import { Helmet } from "react-helmet";


const ResetPassword = () => {
  return (
    <>
     <Helmet>
        <title>Reset Password | Vantage Alpha</title>
      </Helmet>
      <ResetUserPassword />
    </>
  );
};

export default ResetPassword;
