import React, { useState } from "react";
import {
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Grid,
  FormControl,
  NativeSelect,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../../Redux/Reducers/todoReducers";
import { findBrokerId } from "../../../Methods";

const BulkUpdatePopup = ({
  openPopup,
  setOpenPopup,
  title,
  sortedData,
  custodians,
  matchedAllocations,
  setEditableData,
  editableData,
  setHasChanges,
  fundId,
  editableValue,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      broker: "",
      account: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.broker) {
        errors.broker = "Please select  broker.";
      }
      if (!values.account) {
        errors.account = "Please select account.";
      }
      return errors;
    },
    onSubmit: (values) => {},
  });

  const handleCancel = () => {
    formik.resetForm();
    setOpenPopup(false);
  };

  const handleBrokerChange = (event) => {
    const newValue = event.target.value;
    formik.setFieldValue("broker", newValue);
  };

  const handleAccountChange = (event) => {
    const newValue = event.target.value;
    formik.setFieldValue("account", newValue);
  };

  const handleUpdate = (field, fieldValue, errorMessage, allocationsField) => {
    formik.setFieldTouched(field, true);
    formik.validateField(field);

    if (!formik.values[field]) {
      formik.setFieldError(field, errorMessage);
      return;
    }
    setEditableData((prev) =>
      prev.map((item) => ({
        ...item,
        [field]: formik.values[field],
      }))
    );

    setHasChanges(true);
    formik.resetForm();
  };

  const handleUpdateBroker = () => {
    handleUpdate(
      "broker",
      formik.values.broker,
      "Please select broker.",
      "account"
    );
  };

  const handleUpdateAccount = () => {
    handleUpdate(
      "account",
      formik.values.account,
      "Please select account.",
      "broker"
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleCancel}
      open={openPopup}
      sx={{
        "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
          minWidth: "200px",
          marginLeft: "200px",
        },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            align="left"
            style={{ flexGrow: 1, fontSize: "20px" }}
          >
            {title}
          </Typography>
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormControl fullWidth>
              <NativeSelect
                value={formik.values.broker}
                onChange={handleBrokerChange}
                id="broker"
                name="broker"
                error={Boolean(formik.errors.broker && formik.touched.broker)}
              >
                <option value="">Select Broker</option>
                {sortedData?.map((val) => (
                  <option key={val.abbreviation} value={val.abbreviation}>
                    {val.abbreviation}
                  </option>
                ))}
              </NativeSelect>
              {formik.errors.broker && formik.touched.broker && (
                <FormHelperText error>{formik.errors.broker}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              onClick={handleUpdateBroker}
              sx={{ width: "100%" }}
            >
              Update
            </Button>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <NativeSelect
                value={formik.values.account}
                onChange={handleAccountChange}
                id="account"
                name="account"
                error={Boolean(formik.errors.account && formik.touched.account)}
              >
                <option value="">Select Account</option>
                {custodians?.map((val) => (
                  <option key={val.custodian} value={val.name}>
                    {val.name}
                  </option>
                ))}
              </NativeSelect>
              {formik.errors.account && formik.touched.account && (
                <FormHelperText error>{formik.errors.account}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              onClick={handleUpdateAccount}
              sx={{ width: "100%" }}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default BulkUpdatePopup;
