import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Alert, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  findFund,
  findStrategy,
  TABLE_HEAD_FUND_REBALANCE_BLACKLIST,
} from "../../../../../Methods";
import LocatesNotFoundPopup from "../../../../../Popup/LocatesNotFoundPopup";
import Blacklisted from "./components/Blacklisted";
import { useDispatch, useSelector } from "react-redux";
import {
  incrementActiveRequests,
  updateLoading,
  updateLoadingMsg,
} from "../../../../../../Redux/Reducers/todoReducers";
import { showComposite } from "../../../../../Methods/mainFunctions";

const FundRebalanceStep4 = ({
  sleeve,
  setCurrentStep,
  rebalancedFunds,
  rebalancedStrategies,
}) => {
  const dispatch = useDispatch();
  const { blacklistedTickers, strategies, funds } = useSelector(
    (state) => state.todos
  );

  const [popupOpen, setPopupOpen] = useState(false);
  const [blacklist, setBlacklistData] = useState([]);
  const [selectedCompositeKey, setSelectedCompositeKey] = useState("all");
  const [CompositeKeys, setCompositeKeys] = useState([]);
  const [strategyData, setStrategyData] = useState([]);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const combineBlacklistedTickers = (tickersArray) => {
    return tickersArray?.reduce((acc, fund) => {
      const tickers = Object.entries(fund?.blacklistedTickers)?.flatMap(
        ([compositeKey, tickersArray]) =>
          tickersArray?.map((tickerObj) => ({
            ...tickerObj,
            strategy: compositeKey?.split("#")[0],
            fund: compositeKey?.split("#")[1],
          }))
      );
      return acc?.concat(tickers);
    }, []);
  };

  useEffect(() => {
    if (sleeve && blacklistedTickers && blacklistedTickers.length > 0) {
      const compositeKeys = blacklistedTickers?.flatMap((fund) =>
        Object.keys(fund?.blacklistedTickers)
      );
      const allTickers = combineBlacklistedTickers(blacklistedTickers);
      setCompositeKeys(compositeKeys);
      setSelectedCompositeKey("all");
      setStrategyData(allTickers);
    } else {
      setStrategyData([]);
      setCompositeKeys([]);
    }
  }, [sleeve, blacklistedTickers]);

  useEffect(() => {
    if (strategyData) {
      const updatedList = strategyData?.map((item) => ({
        reason: item?.reason,
        ticker: item?.ticker?.symbol,
        fund: findFund(item?.fund, funds),
        strategy: findStrategy(item?.strategy, strategies),
      }));
      setBlacklistData(updatedList);
    }
  }, [strategyData]);

  const handleCompositeKeyChange = (e) => {
    const newCompositeKey = e.target.value;
    setSelectedCompositeKey(newCompositeKey);

    if (newCompositeKey === "all") {
      const allTickers = combineBlacklistedTickers(blacklistedTickers);
      setStrategyData(allTickers);
    } else {
      const selectedFund = blacklistedTickers?.find((fund) =>
        Object.keys(fund?.blacklistedTickers)?.includes(newCompositeKey)
      );

      if (selectedFund) {
        const tickers = selectedFund?.blacklistedTickers[newCompositeKey]?.map(
          (tickerObj) => ({
            ...tickerObj,
            fund: newCompositeKey?.split("#")[1],
            strategy: newCompositeKey?.split("#")[0],
          })
        );
        setStrategyData(tickers);
      }
    }
  };

  const onNext = () => {
    const blacklistedData = rebalancedFunds?.reduce((acc, fundId) => {
      const matchedFund = blacklistedTickers?.find(
        (item) => item?.fundId === fundId
      );

      if (matchedFund && matchedFund?.blacklistedTickers) {
        const aggregatedTickers = Object.values(matchedFund?.blacklistedTickers)
          ?.flat()
          ?.map(({ ticker, reason }) => ({
            ...ticker,
          }));

        acc[fundId] = aggregatedTickers.length > 0 ? aggregatedTickers : [];
      } else {
        acc[fundId] = [];
      }

      return acc;
    }, {});

    const socketRequest = {
      action: "create_rebalancer_items",
      data: {
        fundIds: rebalancedFunds,
        blacklistedTickers: blacklistedData,
        rebalancerType: 0,
        strategies: rebalancedStrategies,
      },
    };
    window.clientSocks.send(JSON.stringify(socketRequest));

    dispatch(incrementActiveRequests());
    dispatch(updateLoading(true));
    dispatch(updateLoadingMsg("Creating Rebalancer Items"));

    setCurrentStep((prev) => prev + 1);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Box
                sx={{
                  marginBottom: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Blacklisted Securities
                </Typography>
                {sleeve && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <select
                      value={selectedCompositeKey}
                      onChange={handleCompositeKeyChange}
                      style={{
                        padding: "5px",
                        fontSize: "14px",
                        marginBottom: 10,
                      }}
                      size="small"
                    >
                      <option value="all">All</option>
                      {CompositeKeys?.map((compositeKey) => (
                        <option key={compositeKey} value={compositeKey}>
                          {showComposite(compositeKey, strategies, funds)}
                        </option>
                      ))}
                    </select>
                  </Box>
                )}
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "150px", height: "40px" }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "40%" }}>
            <Alert severity="info">
              The securities that appear below will not be counted in final
              rebalancer trades.
            </Alert>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "row", width: "40%" }}>
            {blacklist?.length > 0 ? (
              <Blacklisted
                blacklist={blacklist}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_BLACKLIST}
              />
            ) : (
              <Typography>No data available</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      <LocatesNotFoundPopup open={popupOpen} onClose={handleClosePopup} />
    </Container>
  );
};

export default FundRebalanceStep4;
